import entityService from './base/entity.service';

const contactService = () => {
  const baseService = entityService('contacts');

  const that = {};

  that.getAll = baseService.getAll;
  that.getSingle = baseService.getSingle;
  that.create = baseService.create;
  that.update = baseService.update;
  that.delete = baseService.delete;
  that.export = baseService.export;
  that.archive = baseService.archive;
  that.unArchive = baseService.unArchive;
  that.sendMail = baseService.sendMail;

  return that;
};

export default contactService;
