import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import PulseLoader from 'react-spinners/PulseLoader';
import ProgressBar from 'react-bootstrap/ProgressBar';
import internshipService from '../../services/internship.service';
import internshipTypeService from '../../services/internship-type.service';
import ValidatedFormField from '../Common/ValidatedFormField';
import FormSelect from '../Common/FormSelect';
import jobStatusEnum from '../../enum/job-status-enum';

function EditInternshipTypeModal({ isVisible, handleClose }) {
  const [isBusy, setIsBusy] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [selectedInternshipType, setSelectedInternshipType] = useState([]);
  const {
    handleSubmit,
    trigger,
    control,
    formState: { isValid, errors }
  } = useForm();

  const startAutomatching = async () => {
    const result = (await internshipService().getAutomatchingJobs())?.data?.rows;
    if (result?.length === 0) {
      setIsBusy(true);
      const response = await internshipService().startAutomatching(selectedInternshipType?.id);
      if (response) {
        const { id } = response;
        const newJob = { id, progress: 0, status: jobStatusEnum.IN_PROGRESS };
        setJobs([...jobs, newJob]);
      }
      setIsBusy(false);
    }
  };

  const onSubmit = async () => {
    await trigger();
    if (isValid) {
      setIsBusy(true);

      await startAutomatching();

      setIsBusy(false);
    }
  };

  const fetchJobs = async () => {
    const result = (await internshipService().getAutomatchingJobs())?.data?.rows;
    if (result) {
      setJobs([...result]);
    }
  };

  const stopAutomatching = async (jobId) => {
    await internshipService().stopAutomatching(jobId);
    await fetchJobs();
  };

  const renderJobs = () =>
    jobs.map((job) => (
      <div key={job.id} className="d-flex justify-content-between align-items-center mb-3">
        <p className="mb-0">Automatching job: {job.data?.name}</p>
        <ProgressBar animated now={job.progress ? job.progress : 0} style={{ minWidth: '200px', height: '15px' }} label={`${job.progress ? job.progress : 0}%`} />
        <button type="button" className="btn btn-danger btn-xs" onClick={() => stopAutomatching(job.id)}>
          Stoppen
        </button>
      </div>
    ));

  useEffect(() => {
    // Call the method initially
    fetchJobs();

    // Call the method every 5 seconds
    const intervalId = setInterval(fetchJobs, 5000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {}, [jobs]);
  return (
    <Modal show={isVisible} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>Automatching</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {jobs.length === 0 && (
            <>
              <ValidatedFormField className="mb-4" errors={errors} name="selectedInternshipType" description="Stage type">
                <FormSelect
                  name="selectedInternshipType"
                  required
                  loadService={internshipTypeService}
                  control={control}
                  onChange={(e) => {
                    setSelectedInternshipType(e);
                  }}
                  iconClassName="bi-calendar-heart"
                />
              </ValidatedFormField>
              <button type="submit" className="btn btn-primary">
                {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Starten'}
              </button>
            </>
          )}
          {isBusy && (
            <div className="d-flex justify-content-center">
              <PulseLoader color="#007bff" size="10px" margin="4px" />
            </div>
          )}
          {!isBusy && jobs?.length > 0 && renderJobs()}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-white" onClick={handleClose}>
            Sluiten
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default EditInternshipTypeModal;
