import React, { useState } from 'react';
import './password-control.scss';

function PasswordControl({ register, fieldName, placeholder }) {
  const [password, setPassword] = useState('');
  const [validate, setValidate] = useState({
    hasLow: false,
    hasCap: false,
    hasNumber: false,
    has8digit: false
  });

  const strength = Object.values(validate).reduce((a, item) => a + item, 0);
  const feedback = {
    1: 'Zeer zwak',
    2: 'Zwak',
    3: 'Medium',
    4: 'Sterk'
  }[strength];

  const validatePassword = (password) => {
    if (password.match(/\d+/g)) {
      setValidate((o) => ({ ...o, hasNumber: true }));
    } else {
      setValidate((o) => ({ ...o, hasNumber: false }));
    }

    if (password.match(/[A-Z]+/g)) {
      setValidate((o) => ({ ...o, hasCap: true }));
    } else {
      setValidate((o) => ({ ...o, hasCap: false }));
    }

    if (password.match(/[a-z]+/g)) {
      setValidate((o) => ({ ...o, hasLow: true }));
    } else {
      setValidate((o) => ({ ...o, hasLow: false }));
    }

    if (password.length > 7) {
      setValidate((o) => ({ ...o, has8digit: true }));
    } else {
      setValidate((o) => ({ ...o, has8digit: false }));
    }
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };

  return (
    <div className="PasswordControl">
      <input
        {...register(fieldName, {
          required: 'Vul het wachtwoord in!',
          pattern: {
            value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
            message: 'Vul het wachtwoord volgens de vereiste sterkte in.'
          }
        })}
        type="password"
        className="form-control"
        value={password}
        onChange={(e) => handleChangePassword(e)}
        placeholder={placeholder}
      />
      {strength > 0 ? <progress hidden={password.length === 0} className={`password strength-${strength}`} value={strength} max="4" /> : null}
      <div className={`feedback strength-${strength}`} hidden={password.length === 0}>
        {feedback}
      </div>
    </div>
  );
}

export default PasswordControl;
