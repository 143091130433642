import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import PulseLoader from 'react-spinners/PulseLoader';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import internshipService from '../../services/internship.service';
import internshipTypeService from '../../services/internship-type.service';
import officeService from '../../services/office.service';
import contactService from '../../services/contact.service';
import studentService from '../../services/student.service';
import ValidatedFormField from '../Common/ValidatedFormField';
import FormSelect from '../Common/FormSelect';
import FormDatePicker from '../Common/DatePicker';

function EditInternshipModal({ isVisible, handleClose, internshipId, studentId, contactId, officeId, internshipTypeId }) {
  const {
    handleSubmit,
    reset,
    trigger,
    control,
    formState: { isValid, errors }
  } = useForm();

  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(false);
  const [internship, setInternship] = useState({});
  const [office, setOffice] = useState();
  const [student, setStudent] = useState();
  const [mentor, setMentor] = useState();
  const [filterByRegion, setFilterByRegion] = useState(true);

  const onSubmit = async (data) => {
    await trigger();
    if (isValid) {
      setIsBusy(true);

      if (internshipId) {
        await internshipService().update(internshipId, data);
        handleClose(true);
      } else {
        const result = await internshipService().create(data);
        if (result !== undefined) {
          navigate(`/internships/${result.id}`);
        }
      }

      setIsBusy(false);
    }
  };

  const onOfficeChange = (value) => {
    setOffice(value);
    internship.officeId = value?.id;
    setMentor(undefined);
    setInternship(internship);
  };

  const onStudentChange = (value) => {
    setStudent(value);
    internship.studentId = value?.id;
    setInternship(internship);
  };

  const onMentorChange = (value) => {
    setMentor(value);
    internship.mentorId = value.id;
    setInternship(internship);
  };

  const loadStudent = async () => {
    const student = await studentService().getSingle(studentId);
    setStudent(student);
  };

  const loadEntity = async () => {
    const internship = await internshipService().getSingle(internshipId);
    setInternship(internship);
    setOffice(internship.office);
    setMentor(internship.mentor);
    setStudent(internship.student);
  };

  useEffect(() => {}, [filterByRegion]);
  useEffect(() => {}, [office, mentor, student]);
  useEffect(() => {
    setInternship({ studentId, mentorId: contactId, officeId, internshipTypeId });
    loadStudent();
  }, [studentId, contactId, officeId, internshipTypeId]);
  useEffect(() => {
    reset(internship);
  }, [internship]);
  useEffect(() => {
    if (isVisible && internshipId) {
      loadEntity();
    }
    if (isVisible) {
      loadStudent();
    }
  }, [isVisible]);
  return (
    <Modal show={isVisible} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>{internshipId ? 'Stage bewerken' : 'Nieuwe stage'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <ValidatedFormField className="mb-4" errors={errors} name="internshipTypeId" description="Stagetype">
                <FormSelect
                  name="internshipTypeId"
                  required
                  defaultValue={internship?.internshipTypeId}
                  loadService={internshipTypeService}
                  control={control}
                  optionLabels={['name', 'start', 'end']}
                  onChange={(e) => {
                    internship.internshipTypeId = e.id;
                    internship.start = new Date(e.start);
                    internship.end = new Date(e.end);
                    reset(internship);
                  }}
                  iconClassName="bi-calendar-heart"
                />
              </ValidatedFormField>
              <ValidatedFormField className="mb-4" errors={errors} name="studentId" description="Student">
                <FormSelect
                  name="studentId"
                  defaultValue={internship?.studentId}
                  loadService={studentService}
                  orderBy="firstName"
                  optionLabels="fullName"
                  control={control}
                  onChange={(e) => onStudentChange(e)}
                  iconClassName="bi-mortarboard"
                />
              </ValidatedFormField>
              <ValidatedFormField className="mb-4" errors={errors} name="officeId" description="Praktijk">
                <FormSelect
                  name="officeId"
                  required
                  defaultValue={internship?.officeId}
                  loadService={officeService}
                  control={control}
                  iconClassName="bi-hospital"
                  onChange={(e) => onOfficeChange(e)}
                  filters={student && filterByRegion ? { regionIds: [student.preferredRegion1Id, student.preferredRegion2Id] } : {}}
                />
                <input type="checkbox" className="ms-2 mt-1" id="filterByRegion" checked={filterByRegion} onChange={() => setFilterByRegion(!filterByRegion)} />
                <label className="ms-1 mt-1" htmlFor="filterByRegion">
                  Filter op voorkeursregio
                </label>
              </ValidatedFormField>
            </div>
            <div className="row">
              <ValidatedFormField className="mb-4" errors={errors} name="mentorId" description="Begeleider">
                <FormSelect
                  name="mentorId"
                  required
                  defaultValue={mentor}
                  loadService={contactService}
                  orderBy="firstName"
                  optionLabels="fullName"
                  control={control}
                  iconClassName="bi-person"
                  onChange={(e) => onMentorChange(e)}
                  filters={office ? { officeId: office?.id } : {}}
                />
              </ValidatedFormField>
            </div>
            <div className="row">
              <div className="col col-sm">
                <ValidatedFormField className="mb-4" errors={errors} name="start" description="Start">
                  <FormDatePicker name="start" required defaultValue={internship?.start} control={control} />
                </ValidatedFormField>
              </div>
              <div className="col col-sm">
                <ValidatedFormField className="mb-4" errors={errors} name="start" description="Einde">
                  <FormDatePicker name="end" required defaultValue={internship?.end} control={control} />
                </ValidatedFormField>
              </div>
            </div>
            {internship?.id && (
              <div className="row">
                <ValidatedFormField className="mb-4" errors={errors} name="internshipStatusId" description="Status">
                  <FormSelect
                    name="internshipStatusId"
                    required
                    defaultValue={internship?.internshipStatusId}
                    data={[
                      { id: 1, name: 'Nieuw' },
                      { id: 2, name: 'In aanvraag' },
                      { id: 4, name: 'Bevestigd' },
                      { id: 3, name: 'Geweigerd' }
                    ]}
                    control={control}
                    iconClassName="bi-flag"
                  />
                </ValidatedFormField>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary">
            {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Bewaren'}
          </button>
          <button type="button" className="btn btn-white" onClick={handleClose}>
            Sluiten
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default EditInternshipModal;
