import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import PulseLoader from 'react-spinners/PulseLoader';
import PaginationCtrl from './Pagination';
import ErrorToast from './ErrorToast';
import dateFormat from '../../helpers/date-format';
import numberFormat from '../../helpers/number-format';
import ContactEmptyState from '../../assets/svg/oc-chatting.svg';
import FilterControl from './FilterControl';
import filterControlTypeEnum from '../../enum/filter-control-type-enum';
import useWindowDimensions from '../../helpers/window-dimensions';

function TableList({
  entityType,
  columnDefinition,
  getItems,
  deleteItems,
  exportItems,
  archiveItems,
  unArchiveItems,
  filters,
  filterControls,
  actionButtons,
  searchHidden,
  selectHidden,
  refreshKey,
  isWidget
}) {
  const getSavedFiltersettings = (key) => {
    if (isWidget) {
      // no load for widgets
      return null;
    }
    const savedFiltersettings = localStorage.getItem(key);
    if (savedFiltersettings) {
      return JSON.parse(savedFiltersettings);
    }
    return null;
  };

  const headerColumns = [];
  const [rows, setRows] = useState(null);
  const [sortBy, setSortBy] = useState(getSavedFiltersettings(`sortBy-${entityType}`) || 'id');
  const [order, setOrder] = useState(getSavedFiltersettings(`sortOrder-${entityType}`) || 'ASC');
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(getSavedFiltersettings(`currentPage-${entityType}`) || 1);
  const [pageSize, setPageSize] = useState(getSavedFiltersettings(`pageSize-${entityType}`) || 50);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState(getSavedFiltersettings(`searchQuery-${entityType}`) || '');
  const [errorToastVisible, setErrorToastVisible] = useState(false);

  const [queryFilters, setQueryFilters] = useState(getSavedFiltersettings(`queryFilters-${entityType}`) || filters || { showArchive: false });
  const [userFilters, setUserFilters] = useState(getSavedFiltersettings(`userFilters-${entityType}`) || {});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [exportBusy, setExportBusy] = useState(false);
  const { height, width } = useWindowDimensions();
  const [tableHeight, setTableHeight] = useState({ width: '', height: '' });

  const handleStateChanges = () => {
    if (!isWidget) {
      localStorage.setItem(`queryFilters-${entityType}`, JSON.stringify(queryFilters));
      localStorage.setItem(`userFilters-${entityType}`, JSON.stringify(userFilters));
      localStorage.setItem(`sortBy-${entityType}`, JSON.stringify(sortBy));
      localStorage.setItem(`sortOrder-${entityType}`, JSON.stringify(order));
      localStorage.setItem(`currentPage-${entityType}`, JSON.stringify(currentPage));
      localStorage.setItem(`pageSize-${entityType}`, JSON.stringify(pageSize));
      localStorage.setItem(`searchQuery-${entityType}`, JSON.stringify(searchQuery));
    }
  };

  const closeErrorToast = () => {
    setErrorToastVisible(false);
  };

  const showErrorToast = () => {
    setErrorToastVisible(true);
  };

  const handleActionBtnClick = (btn) => {
    btn.action(selectedRows);
    setSelectedRows([]);
    setSelectAllChecked(false);
  };

  const handleClearFiltersClick = () => {
    filterControls?.forEach((item) => {
      item.clearKey = Date.now();
    });

    setQueryFilters({ showArchive: false });
    setUserFilters({});
    setCurrentPage(1);
  };

  const toggleSort = (item) => {
    if (item.sortable) {
      if (sortBy === item.fieldName) {
        setOrder(order === 'ASC' ? 'DESC' : 'ASC');
      } else {
        setSortBy(item.fieldName);
      }
    }
  };

  const selectToggleAll = (isChecked) => {
    setSelectAllChecked(isChecked);
    const arr = selectedRows;

    for (let i = 0; i < rows.length; i += 1) {
      if (isChecked) {
        if (arr.indexOf(rows[i].id) < 0) {
          arr.push(rows[i].id);
        }
      } else if (arr.indexOf(rows[i].id) >= 0) {
        arr.splice(arr.indexOf(rows[i].id), 1);
      }
    }
    setSelectedRows(arr);
  };

  const onRowSelected = (id) => {
    const arr = selectedRows || [];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
    } else {
      arr.push(id);
    }

    setSelectedRows([...arr]);
  };

  const fetchData = async (page) => {
    try {
      const response = await getItems(page, pageSize, sortBy, order, searchQuery, { ...queryFilters, ...userFilters });
      setRows(response.data.rows);
      setTotalItems(response.data.count);
      handleStateChanges();
    } catch (error) {
      showErrorToast();
    }
  };

  const exportData = async () => {
    if (!exportBusy) {
      setExportBusy(true);
      try {
        const dataUrl = await exportItems(sortBy, order, searchQuery, { ...queryFilters });
        if (dataUrl) {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = `exported_data_${new Date().toISOString()}.xlsx`;
          link.click();
        } else {
          showErrorToast();
        }
      } catch (error) {
        showErrorToast();
      } finally {
        setExportBusy(false);
      }
    }
  };

  const onExportClicked = async () => {
    await exportData();
  };

  const onDeleteClicked = async () => {
    await deleteItems(selectedRows);
    await fetchData();
    setSelectedRows([]);
  };

  const onArchiveClicked = async () => {
    await archiveItems(selectedRows);
    await fetchData();
    setSelectedRows([]);
  };

  const onUnArchiveClicked = async () => {
    await unArchiveItems(selectedRows);
    await fetchData();
    setSelectedRows([]);
  };

  const getFieldValue = (fieldName, item) => {
    if (fieldName) {
      const multiLevel = fieldName.split('.').length > 1;
      if (multiLevel) {
        const nestedValue = item[fieldName.split('.')[0]];
        if (nestedValue) {
          return nestedValue[fieldName.split('.')[1]];
        }
      }
      return item[fieldName];
    }
    return undefined;
  };

  const onFilterChange = async (fieldName, value, isUserFilter) => {
    setSelectedRows([]);
    setSelectAllChecked(false);

    const tFilters = isUserFilter ? userFilters || {} : queryFilters || {};

    if (value == null) {
      delete tFilters[fieldName];
    } else {
      tFilters[fieldName] = value;
    }

    if (isUserFilter) {
      setUserFilters(tFilters);
    } else {
      setQueryFilters(tFilters);
    }
    setCurrentPage(1);
    await fetchData(1);
  };

  headerColumns.push(
    <th className="table-column-pe-0" key="select_all">
      {!selectHidden && <input className="form-check-input ms-3" type="checkbox" value="" id="datatableCheckAll" onChange={(e) => selectToggleAll(e.target.checked)} checked={selectAllChecked} />}
    </th>
  );

  columnDefinition.forEach((item) => {
    if (item.fieldName === sortBy) {
      headerColumns.push(
        <th
          className={`table-column-ps-0 ${order === 'ASC' ? 'sorting_asc' : 'sorting_desc'}`}
          key={item.fieldName ?? item.editBtn?.description ?? item.actionBtn?.description}
          onClick={() => toggleSort(item)}
        >
          {item.description}
        </th>
      );
    } else {
      headerColumns.push(
        <th
          className={`table-column-ps-0 ${item.sortable === true ? 'sorting' : ''}`}
          key={item.fieldName ?? item.editBtn?.description ?? item.actionBtn?.description}
          onClick={() => toggleSort(item)}
        >
          {item.description}
        </th>
      );
    }
  });

  useEffect(() => {
    const contentHeight = `${document.getElementsByClassName('content')[0].offsetHeight}px`;
    const tableTop = `${document.getElementsByClassName('table-container')[0].getBoundingClientRect().top}px`;
    const margin = '3rem';

    const newHeight = `calc(${contentHeight} - ${tableTop} - ${margin})`;
    setTableHeight(newHeight);
  }, [height, width]);

  useEffect(() => {}, [selectedRows, errorToastVisible]);
  useEffect(() => {
    const getData = setTimeout(async () => {
      await fetchData(currentPage);
      setSelectAllChecked(false);
    }, 200);
    return () => clearTimeout(getData);
  }, [pageSize, currentPage, sortBy, order, searchQuery, queryFilters, userFilters, refreshKey]);
  return (
    <>
      <ErrorToast handleClose={() => closeErrorToast()} isVisible={errorToastVisible} error="Data kon niet geladen worden. Mogelijk geen internet connectie!" />
      <>
        {!searchHidden && (
          <div className="card-header d-flex gap-2 table-card">
            <div className="mb-2 mb-md-0">
              <div className="input-group input-group-merge input-group-flush">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    await fetchData(1);
                  }}
                >
                  <div className="input-group-prepend input-group-text">
                    <i className="bi-search" />
                  </div>
                  <input type="text" className="form-control" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Zoeken" />
                  <div className="input-group-append input-group-text">
                    <button
                      type="button"
                      className="btn btn-ghost-secondary btn-icon"
                      onClick={() => {
                        setSearchQuery('');
                      }}
                    >
                      <i className="bi-x" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {selectedRows.length > 0 && (
                <div>
                  <span className="fs-5 me-3">
                    <span>
                      {selectedRows.length}
                      &nbsp;
                    </span>
                    Geselecteerd
                  </span>
                  {actionButtons?.map(
                    (btn) =>
                      btn.showAlways !== true && (
                        <button type="button" className="btn btn-outline-primary btn-sm me-2" onClick={() => handleActionBtnClick(btn)} key={btn.description}>
                          <i className={btn.iconClassName} />
                          {btn.description}
                        </button>
                      )
                  )}
                  {archiveItems && queryFilters?.showArchive === false && (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm ms-2"
                      onClick={() => {
                        // eslint-disable-next-line no-alert
                        if (window.confirm('Ben je zeker dat je wil archiveren?')) onArchiveClicked();
                      }}
                    >
                      <i className="bi-archive me-2" />
                      Archiveer
                    </button>
                  )}
                  {unArchiveItems && queryFilters?.showArchive === true && (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm ms-2"
                      onClick={() => {
                        // eslint-disable-next-line no-alert
                        if (window.confirm('Ben je zeker dat je wil dearchiveren?')) onUnArchiveClicked();
                      }}
                    >
                      <i className="bi-archive me-2" />
                      Dearchiveer
                    </button>
                  )}
                  {deleteItems && (
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm ms-2"
                      onClick={() => {
                        // eslint-disable-next-line no-alert
                        if (window.confirm('Ben je zeker dat je wil verwijderen?')) onDeleteClicked();
                      }}
                    >
                      <i className="bi-trash me-2" />
                      Verwijder
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="d-grid d-sm-flex justify-content-md-end align-items-sm-center gap-2  ms-auto me-0 flex-wrap">
              <div className="d-flex align-items-center flex-wrap">
                {filterControls?.length > 0 && (
                  <Dropdown>
                    <Dropdown.Toggle drop="left" className="me-2 btn-sm">
                      <i className="bi bi-filter me-2" />
                      Filters {Object.keys(userFilters).length > 0 ? `(${Object.keys(userFilters).length})` : ''}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="p-4">
                      {archiveItems && (
                        <FilterControl
                          className="d-inline ms-1 me-1"
                          filterControlType={filterControlTypeEnum.BOOL}
                          fieldName="showArchive"
                          placeholder="Toon gearchiveerd"
                          onChange={(fieldName, value) => onFilterChange(fieldName, value, false)}
                          key="filter_archived"
                          defaultValue={queryFilters?.showArchive}
                          isSearchable={false}
                          isClearable={false}
                          clearKey={queryFilters?.showArchive}
                        />
                      )}

                      {filterControls?.map((c) => (
                        <FilterControl
                          className="d-inline ms-1 me-1"
                          clearKey={c.clearKey}
                          key={`filter_${c.fieldName}`}
                          filterControlType={c.filterControlType}
                          defaultValue={userFilters[c.fieldName]}
                          selectedValue={userFilters[c.fieldName]}
                          fieldName={c.fieldName}
                          onChange={(fieldName, value) => onFilterChange(fieldName, value, true)}
                          placeholder={c.placeholder}
                        />
                      ))}
                      <div>
                        <button type="button" className="btn btn-primary btn-sm" onClick={handleClearFiltersClick}>
                          Reset
                        </button>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                {actionButtons?.map(
                  (btn) =>
                    btn.showAlways === true && (
                      <button type="button" className={`${btn.className ? btn.className : 'btn btn-outline-primary btn-sm me-2'}`} onClick={() => handleActionBtnClick(btn)} key={btn.description}>
                        <i className={btn.iconClassName} />
                        {btn.description}
                      </button>
                    )
                )}
                {exportItems && rows && rows.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm ms-2"
                    onClick={() => {
                      // eslint-disable-next-line no-alert
                      onExportClicked();
                    }}
                  >
                    <i className="bi-file-earmark-spreadsheet me-2" />
                    <span>Exporteren {exportBusy && <PulseLoader className="loader d-inline" color="#fff" size="5px" />}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="table-container">
          <div className="table-responsive datatable-custom" style={{ height: tableHeight, minHeight: '300px' }}>
            {rows && rows.length === 0 ? (
              <div className="p-6 text-center">
                <p>{`Geen ${entityType} gevonden.`}</p>
                <img className="w-25 mt-2" src={ContactEmptyState} alt="" />
              </div>
            ) : (
              <Table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer table-hover">
                <thead className="thead-light">
                  <tr>{headerColumns}</tr>
                </thead>
                <tbody>
                  {rows ? (
                    rows.map((item) => (
                      <tr key={item.id}>
                        <td className="table-column-pe-0">
                          {!selectHidden && (
                            <input className="form-check-input ms-3" type="checkbox" checked={selectedRows.includes(item.id)} value={item.id} onChange={() => onRowSelected(item.id)} />
                          )}
                        </td>

                        {columnDefinition.map((col) => (
                          <td key={`${col.fieldName}_${item[col.id]}`} className="table-column-ps-0">
                            {col.link && col.link.type === 'contact' && (
                              <Link to={`/contacts/${getFieldValue(col.link.fieldName, item)}`} className="link">
                                <i className="bi-person nav-icon me-2" width="16" height="16" />
                                {getFieldValue(col.fieldName, item)}
                              </Link>
                            )}
                            {col.link && col.link.type === 'office' && (
                              <Link to={`/offices/${getFieldValue(col.link.fieldName, item)}`} className="link">
                                <i className="bi-hospital nav-icon me-2" width="16" height="16" />
                                {getFieldValue(col.fieldName, item)}
                              </Link>
                            )}
                            {col.link && col.link.type === 'student' && (
                              <Link to={`/students/${getFieldValue(col.link.fieldName, item)}`} className="link">
                                <i className="bi-mortarboard nav-icon me-2" width="16" height="16" />
                                {getFieldValue(col.fieldName, item)}
                              </Link>
                            )}
                            {col.link && col.link.type === 'project' && (
                              <Link to={`/projects/${item[col.link.fieldName]}`} className="link">
                                <i className="bi-briefcase nav-icon me-2" width="16" height="16" />
                                {getFieldValue(col.fieldName, item)}
                              </Link>
                            )}
                            {col.type === 'date' && dateFormat(getFieldValue(col.fieldName, item))}
                            {col.type === 'bool' && (item[col.fieldName] === true ? 'Ja' : 'Nee')}
                            {col.type === 'currency' && <strong>{numberFormat(getFieldValue(col.fieldName, item))}</strong>}
                            {col.type === 'progress' && (
                              <div className="d-flex align-items-center">
                                <label htmlFor="progressbar" className="fs-6 me-2">
                                  {getFieldValue(col.fieldName, item)}%
                                </label>
                                <div className="progress table-progress me-4">
                                  <div className="progress-bar bg-primary" style={{ width: `${getFieldValue(col.fieldName, item)}%` }} />
                                </div>
                              </div>
                            )}
                            {col.type === 'internshipStatus' && (
                              <>
                                <span
                                  className="legend-indicator"
                                  style={{
                                    backgroundColor: `${getFieldValue('internshipStatus.color', item)}`
                                  }}
                                />
                                {getFieldValue('internshipStatus.name', item)}
                              </>
                            )}
                            {(col.type === 'text' || col.type === undefined) && !col.link && getFieldValue(col.fieldName, item)}

                            {col.editBtn && col.editBtn.type === 'internship' && (
                              <Link className="btn btn-white" to={`/internships/${item[col.editBtn.fieldName]}`}>
                                {col.editBtn.description}
                              </Link>
                            )}
                            {col.editBtn && col.editBtn.type === 'internshipType' && (
                              <Link className="btn btn-white" to={`/internshiptypes/${item[col.editBtn.fieldName]}`}>
                                {col.editBtn.description}
                              </Link>
                            )}
                            {col.editBtn && col.editBtn.type === 'contact' && (
                              <Link className="btn btn-white" to={`/contacts/${item[col.editBtn.fieldName]}`}>
                                {col.editBtn.description}
                              </Link>
                            )}
                            {col.editBtn && col.editBtn.type === 'office' && (
                              <Link className="btn btn-white" to={`/offices/${item[col.editBtn.fieldName]}`}>
                                {col.editBtn.description}
                              </Link>
                            )}
                            {col.editBtn && col.editBtn.type === 'student' && (
                              <Link className="btn btn-white" to={`/students/${item[col.editBtn.fieldName]}`}>
                                {col.editBtn.description}
                              </Link>
                            )}
                            {col.editBtn && col.editBtn.type === 'user' && (
                              <Link className="btn btn-white" to={`/users/${item[col.editBtn.fieldName]}`}>
                                {col.editBtn.description}
                              </Link>
                            )}
                            {col.actionBtn && (
                              <button type="button" className="btn btn-sm btn-white" onClick={() => col.actionBtn.onClick(item.id)}>
                                {col.actionBtn.description}
                              </button>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr key="loader_tr" className="grid-loading-wrapper">
                      <td key="loader">
                        <PulseLoader className="loader" color="#6b37fb" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </div>
        </div>

        <div className="card-footer">
          <PaginationCtrl
            totalItems={totalItems}
            currentPage={currentPage}
            pageSize={pageSize}
            pageSizeChanged={(limit) => {
              setPageSize(limit);
              selectToggleAll(false);
            }}
            pageChanged={(page) => setCurrentPage(page)}
          />
        </div>
      </>
    </>
  );
}

export default TableList;
