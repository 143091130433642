import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './scss/theme.scss';
import './scss/theme-dark.scss';

import 'bootstrap-icons/font/bootstrap-icons.css';
import App from './App';

import OfficeList from './components/Offices/OfficeList';
import Office from './components/Offices/Office';
import OfficeView from './components/Public/OfficeView';
import StudentList from './components/Students/StudentList';
import Student from './components/Students/Student';
import ContactList from './components/Contacts/ContactList';
import Contact from './components/Contacts/Contact';
import ChangeEmailConfirmation from './components/Users/ChangeEmailConfirmation';
import InternshipList from './components/Internships/InternshipList';
import InternshipTypeList from './components/InternshipTypes/InternshipTypeList';
import InternshipType from './components/InternshipTypes/InternshipType';
import Internship from './components/Internships/Internship';
import Settings from './components/Settings/Settings';
import Login from './components/Auth/Login';
import SetPassword from './components/Public/SetPassword';
import Dashboard from './components/Dashboard/Dashboard';
import Inbox from './components/Events/Inbox';
import reportWebVitals from './reportWebVitals';
import InternshipApproval from './components/Public/InternshipApproval';
import StudentInfoRequest from './components/Students/StudentInfoRequest';
import InternshipSubscriptions from './components/Public/InternshipSubscriptions';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<InternshipList />} />
        <Route path="settings" element={<Settings />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="offices/list" element={<OfficeList />} />
        <Route path="offices/:id" element={<Office />} />
        <Route path="contacts/list" element={<ContactList />} />
        <Route path="contacts/:id" element={<Contact />} />
        <Route path="students/list" element={<StudentList />} />
        <Route path="students/:id" element={<Student />} />
        <Route path="events/inbox" element={<Inbox />} />
        <Route path="internships/list" element={<InternshipList />} />
        <Route path="internshiptypes/list" element={<InternshipTypeList />} />
        <Route path="internshiptypes/:id" element={<InternshipType />} />
        <Route path="internships/:id" element={<Internship />} />
      </Route>
      <Route path="/approval/:token" element={<InternshipApproval />} />
      <Route path="/emailconfirmation/:userId/:tokenId" element={<ChangeEmailConfirmation />} />
      <Route path="/inforeply/:id/:tokenId" element={<StudentInfoRequest />} />
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Login />}>
        <Route index element={<Login />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/login/:logout" element={<Login />} />
      <Route path="/officeportal/:id/:token" element={<OfficeView />} />
      <Route path="/setPassword/:id/:token" element={<SetPassword />} />
      <Route path="/internshipsubscriptions/:id/:token" element={<InternshipSubscriptions />} />
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
