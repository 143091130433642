import React, { useEffect } from 'react';
import TableList from '../Common/TableList';

import contactService from '../../services/contact.service';

const cols = [
  {
    fieldName: 'fullName',
    description: 'Naam',
    sortable: true,
    link: { type: 'contact', fieldName: 'id' }
  },
  {
    fieldName: 'office.name',
    description: 'Praktijk',
    sortable: true,
    link: { type: 'office', fieldName: 'officeId' }
  },
  {
    fieldName: 'email',
    description: 'E-mail',
    sortable: false
  },
  {
    fieldName: 'phone',
    description: 'Telefoon',
    sortable: false
  },
  {
    fieldName: '',
    description: '',
    editBtn: { type: 'contact', fieldName: 'id', description: 'bekijk' }
  }
];

function ContactWidget({ filters }) {
  useEffect(() => {}, []);
  return (
    <TableList
      entityType="contacten"
      columnDefinition={cols}
      getItems={contactService().getAll}
      deleteItems={contactService().delete}
      archiveItems={contactService().archive}
      unArchiveItems={contactService().unArchive}
      exportItems={contactService().export}
      filters={filters}
      isWidget
    />
  );
}

export default ContactWidget;
