import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-bootstrap/Modal';
import PulseLoader from 'react-spinners/PulseLoader';
import { useNavigate } from 'react-router-dom';
import ValidatedFormField from '../Common/ValidatedFormField';

import internshipTypeService from '../../services/internship-type.service';
import FormDatePicker from '../Common/DatePicker';

function EditInternshipTypeModal({ isVisible, handleClose, internshipTypeId }) {
  const {
    handleSubmit,
    trigger,
    control,
    reset,
    register,
    formState: { isValid, errors }
  } = useForm();

  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(false);
  const [internshipType, setInternshipType] = useState();
  const onSubmit = async (data) => {
    await trigger();
    if (isValid) {
      setIsBusy(true);

      if (internshipTypeId) {
        await internshipTypeService().update(internshipTypeId, data);
        handleClose(true);
      } else {
        const result = await internshipTypeService().create(data);
        if (result !== undefined) {
          navigate(`/internshipTypes/${result.id}`);
        }
      }

      setIsBusy(false);
    }
  };

  const loadEntity = async () => {
    const internshipType = await internshipTypeService().getSingle(internshipTypeId);
    setInternshipType(internshipType);
  };

  useEffect(() => {
    reset(internshipType);
  }, [internshipType]);
  useEffect(() => {
    if (isVisible && internshipTypeId) {
      loadEntity();
    }
  }, [isVisible]);
  return (
    <Modal show={isVisible} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{internshipTypeId ? 'Stagetype bewerken' : 'Nieuw stagetype'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <ValidatedFormField className="mb-4" errors={errors} name="name" description="Naam">
                <input
                  {...register('name', { required: true })}
                  name="name"
                  type="text"
                  className="form-control"
                  defaultValue={internshipType?.name}
                  autoComplete="false"
                  placeholder="vul hier de naam in."
                />
              </ValidatedFormField>
            </div>
            <div className="row">
              <div className="col col-sm">
                <ValidatedFormField
                  className="mb-4"
                  errors={errors}
                  name="start"
                  description="Start"
                >
                  <FormDatePicker
                    name="start"
                    required
                    defaultValue={internshipType?.start}
                    control={control}
                  />
                </ValidatedFormField>
              </div>
              <div className="col col-sm">
                <ValidatedFormField
                  className="mb-4"
                  errors={errors}
                  name="start"
                  description="Einde"
                >
                  <FormDatePicker
                    name="end"
                    required
                    defaultValue={internshipType?.end}
                    control={control}
                  />
                </ValidatedFormField>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary">
            {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Bewaren'}
          </button>
          <button type="button" className="btn btn-white" onClick={handleClose}>
            Sluiten
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default EditInternshipTypeModal;
