import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import PulseLoader from 'react-spinners/PulseLoader';
import { useNavigate } from 'react-router-dom';
import FormSelect from '../Common/FormSelect';
import studentService from '../../services/student.service';
import internshipTypeService from '../../services/internship-type.service';
import regionService from '../../services/region.service';
import ValidatedFormField from '../Common/ValidatedFormField';

function EditStudentModal({ isVisible, handleClose, studentId }) {
  const {
    register,
    handleSubmit,
    trigger,
    control,
    reset,
    formState: { isValid, errors }
  } = useForm();

  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(false);

  const [student, setStudent] = useState();

  const onSubmit = async (data) => {
    await trigger();
    if (isValid) {
      setIsBusy(true);

      if (studentId) {
        await studentService().update(studentId, data);
        handleClose(true);
      } else {
        const result = await studentService().create(data);
        if (result !== undefined) {
          navigate(`/students/${result.id}`);
        }
      }
      setIsBusy(false);
    }
  };

  const loadEntity = async () => {
    const student = await studentService().getSingle(studentId);
    setStudent(student);
  };

  useEffect(() => {
    reset(student);
  }, [student]);
  useEffect(() => {
    if (studentId) {
      loadEntity();
    }
  }, [isVisible]);
  return (
    <Modal show={isVisible} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Nieuwe student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <ValidatedFormField className="mb-4" errors={errors} name="firstName" description="Voornaam">
                <input
                  {...register('firstName', { required: true })}
                  name="firstName"
                  type="text"
                  className="form-control"
                  defaultValue={student?.firstName}
                  autoComplete="false"
                  placeholder="vul hier de voornaam in."
                />
              </ValidatedFormField>
              <ValidatedFormField className="mb-4" errors={errors} name="lastName" description="Achternaam">
                <input
                  {...register('lastName', { required: true })}
                  name="lastName"
                  type="text"
                  className="form-control"
                  defaultValue={student?.lastName}
                  autoComplete="false"
                  placeholder="vul hier de achternaam in."
                />
              </ValidatedFormField>
            </div>
            <div className="row">
              <ValidatedFormField className="mb-4" errors={errors} name="internshipTypeId" description="Stagetype">
                <FormSelect
                  name="internshipTypeId"
                  optionLabels={['name', 'start', 'end']}
                  required
                  defaultValue={student?.internshipTypeId}
                  loadService={internshipTypeService}
                  control={control}
                  iconClassName="bi-calendar-heart"
                />
              </ValidatedFormField>
            </div>
            <div className="row">
              <ValidatedFormField className="mb-4" errors={errors} name="preferredRegion1Id" description="Voorkeursregio 1">
                <FormSelect name="preferredRegion1Id" defaultValue={student?.preferredRegion1} loadService={regionService} control={control} iconClassName="bi-geo-alt" />
              </ValidatedFormField>
              <ValidatedFormField className="mb-4" errors={errors} name="preferredRegion2Id" description="Voorkeursregio 2">
                <FormSelect name="preferredRegion2Id" defaultValue={student?.preferredRegion2} loadService={regionService} control={control} iconClassName="bi-geo-alt" />
              </ValidatedFormField>
            </div>
            <div className="row">
              <div className="col col-sm">
                <ValidatedFormField className="mb-4" errors={errors} name="email" description="E-mail adres">
                  <div className="input-group input-group-merge">
                    <div className="input-group-prepend input-group-text">
                      <i className="bi bi-envelope" />
                    </div>
                    <input
                      {...register('email', { required: true })}
                      type="email"
                      className="form-control"
                      defaultValue={student?.email}
                      name="email"
                      autoComplete="false"
                      placeholder="vul hier het e-mail adres in."
                    />
                  </div>
                </ValidatedFormField>
              </div>
              <ValidatedFormField className="mb-4" errors={errors} name="phone" description="Telefoon">
                <div className="input-group input-group-merge">
                  <div className="input-group-prepend input-group-text">
                    <i className="bi bi-telephone" />
                  </div>
                  <input
                    {...register('phone', { required: true })}
                    type="phone"
                    className="form-control"
                    defaultValue={student?.phone}
                    name="phone"
                    autoComplete="false"
                    placeholder="vul hier het telefoonnummer in."
                  />
                </div>
              </ValidatedFormField>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary">
            {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Bewaren'}
          </button>
          <button type="button" className="btn btn-white" onClick={handleClose}>
            Sluiten
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default EditStudentModal;
