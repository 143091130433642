import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import EntityNote from '../Common/EntityNote';
import Timeline from '../Common/Timeline';
import internshipService from '../../services/internship.service';
import internshipStatusEnum from '../../enum/internship-status-enum';
import mailTemplateTypeEnum from '../../enum/mail-template-type-enum';
import mailTemplateService from '../../services/mail-template.service';
import ErrorToast from '../Common/ErrorToast';
import dateFormat from '../../helpers/date-format';
import EditInternshipModal from './EditInternshipModal';
import MailModal from '../Common/MailModal';
import contactService from '../../services/contact.service';

function Internship() {
  const internshipId = useParams().id;
  const [errorToastVisible, setErrorToastVisible] = useState(false);
  const [internship, setInternship] = useState();
  const [mailTemplate, setMailTemplate] = useState();
  const [tlRefresh, setTlRefresh] = useState();
  const [editInternshipModalVisible, setEditInternshipModalVisible] = useState(false);
  const [mailModalVisible, setMailModalVisible] = useState(false);

  const showErrorToast = () => {
    setErrorToastVisible(true);
  };

  const fetchMailTemplate = async () => {
    const response = await mailTemplateService().getAll({ mailTemplateTypeId: mailTemplateTypeEnum.INTERNSHIP_APPROVAL });
    setMailTemplate(response.data?.rows[0]?.message);
  };

  const fetchInternship = async () => {
    try {
      const response = await internshipService().getSingle(internshipId);
      setInternship(response);
    } catch (error) {
      showErrorToast();
    }
  };

  const handleEditInternshipModalShow = () => {
    setEditInternshipModalVisible(true);
  };

  const handleEditInternshipModalClose = async (modified) => {
    if (modified) {
      await fetchInternship();
    }
    setEditInternshipModalVisible(false);
  };

  const handleMailModalClose = async (data) => {
    if (data && data.message && data.ids?.length > 0) {
      await internshipService().requestApproval(internshipId, data.message, data.ids[0]);
      setMailModalVisible(true);
      await fetchInternship();
      setTlRefresh(new Date());
    }

    setMailModalVisible(false);
  };

  const closeErrorToast = () => {
    setErrorToastVisible(false);
  };

  useEffect(() => {}, [mailTemplate, tlRefresh]);
  useEffect(() => {
    fetchInternship();
    fetchMailTemplate();
  }, []);
  return (
    <>
      <EditInternshipModal
        isVisible={editInternshipModalVisible}
        internshipId={internshipId}
        handleClose={(modified) => {
          handleEditInternshipModalClose(modified);
        }}
      />
      <MailModal
        isVisible={mailModalVisible}
        contactService={contactService}
        message={mailTemplate}
        recipientNames={internship?.mentor?.email}
        selectedIds={[internship?.mentor?.id]}
        handleClose={handleMailModalClose}
        modalTitle="Stage uitnodiging versturen"
      />

      <div className="page-header">
        <ErrorToast handleClose={() => closeErrorToast()} isVisible={errorToastVisible} error="Data kon niet geladen worden. Mogelijk geen internet connectie!" />
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-no-gutter">
                <li className="breadcrumb-item">
                  <a className="breadcrumb-link" href="/internships">
                    Stages
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Details
                </li>
              </ol>
            </nav>
            <div className="d-flex gap-2">
              <div>
                <h1 className="page-header-title d-inline">
                  {internship ? (
                    <span>
                      {internship.internshipType.name} - {internship.student?.firstName} {internship.student?.lastName}
                    </span>
                  ) : (
                    <Skeleton count={1} />
                  )}
                </h1>
                <div className="text-body">
                  {internship ? (
                    <span>
                      <i className="bi-calendar me-2" />
                      {dateFormat(internship.start, false)} - {dateFormat(internship.end, false)}
                    </span>
                  ) : (
                    <Skeleton count={1} />
                  )}
                </div>
              </div>
              <button type="button" className="btn btn-white btn-icon btn-xs rounded-circle" aria-expanded="false" onClick={handleEditInternshipModalShow}>
                <i className="bi-pencil-fill" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Algemeen</h4>
            </div>
            <div className="card-body">
              {internship != null ? (
                <ul className="list-group list-group-flush list-group-no-gutters">
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Info stage</h5>
                      <button type="button" className="btn btn-link" onClick={handleEditInternshipModalShow}>
                        Aanpassen
                      </button>
                    </div>

                    <ul className="list-unstyled list-py-1 text-body">
                      <li>
                        <i className="bi-hospital me-1" />
                        {internship.office?.name}
                      </li>
                      <li>
                        <i className="bi-person me-1" />
                        {internship.mentor?.fullName}
                      </li>
                      <li>
                        <i className="bi-mortarboard me-1" />
                        {internship.student?.fullName}
                      </li>
                    </ul>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Status</h5>
                      <button type="button" className="btn btn-link" onClick={() => setMailModalVisible(true)}>
                        {internship.internshipStatusId === internshipStatusEnum.NEW ? 'Begeleider uitnodigen' : 'Begeleider opnieuw uitnodigen'}
                      </button>
                    </div>
                    <ul className="list-unstyled list-py-1 text-body">
                      <li>
                        <span className="badge bg-soft-primary text-primary rounded-pill h6">{internship?.internshipStatus.name}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              ) : (
                <Skeleton count={3} />
              )}
            </div>
          </div>
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Notities</h4>
            </div>
            <EntityNote entity={internship} entityService={internshipService} />
          </div>
        </div>
        <div className="col-lg-6">
          <Timeline internshipId={internshipId} key={JSON.stringify(tlRefresh)} />
        </div>
      </div>
    </>
  );
}

export default Internship;
