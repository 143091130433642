import entityService from './base/entity.service';

const regionService = () => {
  const baseService = entityService('regions');

  const that = {};

  that.getAll = (page, pageSize, sortField, sortOrder, term) => baseService.getAll(page, pageSize, sortField, sortOrder, term);

  that.getSingle = baseService.getSingle;

  return that;
};

export default regionService;
