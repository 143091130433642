import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import userService from '../../services/user.service';
import HeaderSvg from '../../assets/svg/components/card-6.svg';
import EmailSvg from '../../assets/svg/oc-email-verification.svg';
import Logo from '../../assets/img/logo.png';

function ChangeEmailConfirmation() {
  const { tokenId, userId } = useParams();
  const [submitSuccess, setSubmitSuccess] = useState(true);

  const submitToken = async () => {
    try {
      const response = await userService().confirmEmailAddress(userId, tokenId);
      if (response) {
        setSubmitSuccess(true);
      } else {
        throw new Error('change email failed');
      }
    } catch (error) {
      setSubmitSuccess(false);
    }
  };

  useEffect(() => {
    submitToken();
  }, []);
  return (
    <div className="bg-colored d-flex justify-content-center">
      <div className="position-absolute top-0 start-0 end-0 mt-3 mx-3">
        <div className="d-none d-lg-flex justify-content-between">
          <img src={Logo} style={{ width: '4rem', height: '4rem', zIndex: '2' }} alt="fg-logo" />
        </div>
      </div>
      <div>
        <div className="position-fixed top-0 end-0 start-0 bg-img-start" style={{ height: '32rem', backgroundImage: `url(${HeaderSvg})` }} alt="">
          <div className="shape shape-bottom zi-1">
            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
              <polygon fill="#7575CB" points="0,273 1921,273 1921,0 " />
            </svg>
          </div>
        </div>
      </div>

      <div className="py-10">
        <div className="card card-lg mt-10">
          {!submitSuccess && (
            <div className="card-body p-6">
              <img src={EmailSvg} alt="todo" style={{ width: '20rem' }} className="m-auto d-block" />
              <h1 className="display-5 mt-5 mb-3">Link verlopen..</h1>
              <p style={{ width: '30rem' }}>
                Oeps.. deze link is ondertussen verlopen. <br />
              </p>
            </div>
          )}
          {submitSuccess && (
            <div className="card-body p-6">
              <img src={EmailSvg} alt="todo" style={{ width: '20rem' }} className="m-auto d-block" />
              <h1 className="display-5 mt-5 mb-3">Bevestiging email aanpassing</h1>
              <p style={{ width: '30rem' }}>Hallo, we hebben je email adres aangepast! Je kan nu inloggen met het nieuwe email adres!</p>
              <div className="d-flex justify-content-start">
                <a className="btn btn-primary" href="/login/logout">
                  Naar login
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChangeEmailConfirmation;
