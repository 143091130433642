import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import './datepicker.scss';

function FormDatePicker({ name, required, defaultValue, control, onChange, iconClassName = 'bi-calendar' }) {
  const [selected, setSelected] = useState();
  useEffect(() => {
    setSelected(defaultValue ? new Date(defaultValue) : null);
  }, [defaultValue]);
  return (
    <Controller
      name={name}
      rules={{ required }}
      control={control}
      render={({ field }) => (
        <div className="datepicker-container">
          <i className={iconClassName} />
          <DatePicker
            dateFormat="dd/MM/yyyy"
            placeholderText="Selecteer datum"
            shouldCloseOnSelect
            dropdownMode="select"
            onChange={(date) => {
              field.onChange(date);
              setSelected(date);
              if (onChange) {
                onChange(date);
              }
            }}
            selected={selected}
          />
        </div>
      )}
    />
  );
}

export default FormDatePicker;
