import React, { useState, useEffect } from 'react';
import TableList from '../../Common/TableList';

import mailTemplateService from '../../../services/mail-template.service';
import EditMailTemplateModal from './EditMailTemplateModal';

function UserList() {
  const [editMailTemplateModalVisible, setEditMailTemplateModalVisible] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [refreshKey, setRefreshKey] = useState();

  const handleEditMailTemplateModalShow = () => {
    setEditMailTemplateModalVisible(true);
  };

  const handleEditMailTemplateModalClose = () => {
    setEditMailTemplateModalVisible(false);
    setRefreshKey(new Date());
  };

  const filterControls = [];

  const cols = [
    {
      fieldName: 'id',
      description: 'Id',
      sortable: true
    },
    {
      fieldName: 'mailTemplateType.name',
      description: 'Type',
      sortable: true
    },
    {
      fieldName: 'description',
      description: 'Onderwerp',
      sortable: true
    },
    {
      actionBtn: {
        onClick: (id) => {
          setSelectedTemplateId(id);
          handleEditMailTemplateModalShow();
        },
        description: 'bekijk'
      }
    }
  ];
  useEffect(() => {}, [editMailTemplateModalVisible]);
  return (
    <>
      <EditMailTemplateModal
        isVisible={editMailTemplateModalVisible}
        mailTemplateId={selectedTemplateId}
        handleClose={() => {
          handleEditMailTemplateModalClose();
        }}
      />

      <div>
        <div className="page-header">
          <div className="row align-items-end">
            <div className="col-sm mb-2 mb-sm-0">
              <h1 className="page-header-title">Email templates</h1>
            </div>
            <div className="col-sm-auto">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setSelectedTemplateId(null);
                  handleEditMailTemplateModalShow();
                }}
              >
                <i className="bi-plus me-1" />
                Nieuwe template
              </button>
            </div>
          </div>
        </div>
        <div className="card">
          <TableList entityType="mail-templates" columnDefinition={cols} getItems={mailTemplateService().getAll} filterControls={filterControls} selectHidden searchHidden refreshKey={refreshKey} />
        </div>
      </div>
    </>
  );
}

export default UserList;
