/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import PulseLoader from 'react-spinners/PulseLoader';
import RichEditor from '../Common/RichEditor';
import internshipTypeService from '../../services/internship-type.service';
import FormSelect from '../Common/FormSelect';
import dateFormat from '../../helpers/date-format';

function SubsriptionMailModal({ modalTitle, isVisible, handleClose, selectedIds, message }) {
  const [recipient, setRecipient] = useState();
  const [isBusy, setIsBusy] = useState(false);
  const [editorState, setEditorState] = useState();
  const [wizardStep, setWizardStep] = useState(1);
  const [selectedInternshipType, setSelectedInternshipType] = useState();
  const [selectedInternshipTypes, setSelectedInternshipTypes] = useState([]);

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    register,
    formState: { isValid, errors }
  } = useForm();

  const removeInternshipTypeById = (id) => {
    setSelectedInternshipTypes([...selectedInternshipTypes.filter((item) => item.id !== id)]);
  };

  const addInternshipType = () => {
    if (!selectedInternshipTypes.some((internshipType) => internshipType.id === selectedInternshipType.id)) {
      setSelectedInternshipTypes([...selectedInternshipTypes, selectedInternshipType]);
    }
  };

  const onNextBtnClick = () => setWizardStep(2);
  const onPreviousBtnClick = () => setWizardStep(1);

  const resetForm = () => {
    setEditorState(null);
  };

  const onSubmit = async (data) => {
    await trigger();
    if (isValid) {
      setIsBusy(true);
      data.message = editorState;
      data.ids = recipient;
      data.internshipTypes = selectedInternshipTypes;
      await handleClose(data);
      resetForm();
      setIsBusy(false);
    }
  };

  const onClose = () => {
    resetForm();
    handleClose();
  };

  const onEditorChange = (e) => {
    setEditorState(e);
  };

  useEffect(() => {}, [isBusy, selectedInternshipTypes, selectedInternshipType]);

  useEffect(() => {}, [selectedIds]);
  useEffect(() => {}, [message]);
  return (
    <Modal show={isVisible} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <ul className="step step-sm step-icon-sm step-inline step-item-between mb-3 mb-sm-7">
                <li className={`${wizardStep === 1 ? 'step-item active focus' : 'step-item'} `}>
                  <div className="step-content-wrapper">
                    <span className="step-icon step-icon-soft-dark">1</span>
                    <div className="step-content">
                      <span className="step-title">Kies stages</span>
                    </div>
                  </div>
                </li>
                <li className={`${wizardStep === 2 ? 'step-item active focus' : 'step-item'} `}>
                  <div className="step-content-wrapper">
                    <span className="step-icon step-icon-soft-dark">2</span>
                    <div className="step-content">
                      <span className="step-title">Uitnodigingen verzenden</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {wizardStep === 1 && (
              <>
                <div className="row">
                  <ul className="list-group">
                    {selectedInternshipTypes?.map((internshipType) => (
                      <li className="list-group-item" key={internshipType.id}>
                        <div className="row">
                          <div className="col">
                            <h5>{internshipType.name}</h5>
                            <div className="text-body">
                              <i className="bi-calendar me-2" />
                              Start op {dateFormat(internshipType.start, false)}
                            </div>
                            <div className="text-body">
                              <i className="bi-calendar me-2" />
                              Einde op {dateFormat(internshipType.end, false)}
                            </div>
                          </div>
                          <div className="col-auto">
                            <button type="button" className="btn btn-ghost btn-sm" onClick={() => removeInternshipTypeById(internshipType.id)}>
                              <i className="bi-trash me-1" />
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="row mt-4">
                  <div className="col ps-0">
                    <FormSelect
                      className="ps-0"
                      name="internshipTypeId"
                      required
                      loadService={internshipTypeService}
                      control={control}
                      onChange={(e) => setSelectedInternshipType(e)}
                      iconClassName="bi-calendar-heart"
                    />
                  </div>
                  <div className="col-auto">
                    <button type="button" className={selectedInternshipType ? 'btn btn-primary btn-sm' : 'd-none'} onClick={() => addInternshipType()}>
                      {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Toevoegen'}
                    </button>
                  </div>
                </div>
              </>
            )}
            {wizardStep === 2 && (
              <>
                <div className="row">
                  <input type="text" value={`Ontvangers: ${selectedIds?.length}`} className="form-control mb-4" disabled name="recipient" {...register('recipient', { required: false })} />
                </div>
                <div className="row">
                  <RichEditor onChange={(e) => onEditorChange(e)} onInitEditorState={(e) => onEditorChange(e)} editorStateJson={message} />
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {wizardStep === 1 && (
            <button type="button" className="btn btn-primary" onClick={onNextBtnClick} disabled={selectedInternshipTypes?.length === 0}>
              {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Volgende'}
            </button>
          )}
          {wizardStep === 2 && (
            <>
              <button type="button" className="btn btn-ghost-primary ms-0 me-auto" onClick={onPreviousBtnClick}>
                <i className="bi-chevron-left me-1" />
                Vorige
              </button>
              <button type="submit" className="btn btn-primary">
                {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Verzenden'}
              </button>
            </>
          )}

          <button type="button" className="btn btn-white" onClick={onClose}>
            Sluiten
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default SubsriptionMailModal;
