import React, { useState, useEffect } from 'react';
import TableList from '../Common/TableList';
import EditUserModal from './EditUserModal';
import User from './User';
import userService from '../../services/user.service';

function UserList() {
  const [editUserModalVisible, setEditUserModalVisible] = useState(false);
  const [activeView, setActiveView] = useState('LIST');
  const [selectedUserId, setSelectedUserId] = useState();

  const handleEditUserModalShow = () => {
    setEditUserModalVisible(true);
  };

  const handleEditUserModalClose = () => {
    setEditUserModalVisible(false);
  };

  const filterControls = [];

  const cols = [
    {
      fieldName: 'fullName',
      description: 'Naam',
      sortable: true
    },
    {
      fieldName: 'email',
      description: 'Email',
      sortable: false
    },
    {
      fieldName: 'userRole.name',
      description: 'Rol',
      sortable: true
    },
    {
      actionBtn: {
        onClick: (id) => {
          setSelectedUserId(id);
          setActiveView('USER');
        },
        description: 'bekijk'
      }
    }
  ];
  useEffect(() => {}, [editUserModalVisible]);
  return (
    <>
      <EditUserModal
        isVisible={editUserModalVisible}
        handleClose={() => {
          handleEditUserModalClose();
        }}
      />

      {activeView === 'LIST' && (
        <div>
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title">{activeView === 'LIST' && 'Gebruikers'}</h1>
              </div>
              <div className="col-sm-auto">
                {activeView === 'LIST' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      handleEditUserModalShow();
                    }}
                  >
                    <i className="bi-plus me-1" />
                    Nieuwe gebruiker
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="card">
            <TableList entityType="users" columnDefinition={cols} deleteItems={userService().delete} getItems={userService().getAll} filterControls={filterControls} />
          </div>
        </div>
      )}
      {activeView === 'USER' && <User userId={selectedUserId} handleClose={() => setActiveView('LIST')} />}
    </>
  );
}

export default UserList;
