import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import PulseLoader from 'react-spinners/PulseLoader';
import RichEditor from '../Common/RichEditor';

function CommentModal({ isVisible, handleClose }) {
  const [isBusy, setIsBusy] = useState(false);
  const [editorState, setEditorState] = useState();

  const {
    handleSubmit,
    trigger,
    formState: { isValid }
  } = useForm();

  const resetForm = () => {
    setEditorState(null);
  };

  const onSubmit = async () => {
    await trigger();
    if (isValid) {
      setIsBusy(true);
      await handleClose(editorState);
      resetForm();
      setIsBusy(false);
    }
  };

  const onClose = () => {
    resetForm();
    handleClose();
  };

  const onEditorChange = (e) => {
    setEditorState(e);
  };

  useEffect(() => {}, [isBusy]);
  return (
    <Modal show={isVisible} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>Opmerking / Suggestie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <RichEditor onChange={(e) => onEditorChange(e)} onInitEditorState={(e) => onEditorChange(e)} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary">
            {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Verzenden'}
          </button>
          <button type="button" className="btn btn-white" onClick={onClose}>
            Sluiten
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default CommentModal;
