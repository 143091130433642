import React, { useState, useEffect } from 'react';

import filterControlTypeEnum from '../../enum/filter-control-type-enum';
import FilterSelect from './FilterSelect';
import internshipTypeService from '../../services/internship-type.service';
import internshipStatusService from '../../services/internship-status.service';
import regionService from '../../services/region.service';

const filterControl = ({ className = '', filterControlType, fieldName, onChange, placeholder, defaultValue, isSearchable = true, isClearable = true, clearKey }) => {
  const [control, setControl] = useState();
  function getControl() {
    switch (filterControlType) {
      case filterControlTypeEnum.INTERNSHIP_TYPE:
        return (
          <div className={`${className} mb-3`} style={{ width: '18rem' }}>
            <div className="me-2">{placeholder}:</div>
            <FilterSelect
              name={fieldName}
              clearKey={clearKey}
              defaultValue={defaultValue}
              required
              loadService={internshipTypeService}
              isSearchable={isSearchable}
              isClearable={isClearable}
              onChange={(e) => {
                onChange(fieldName, e);
              }}
            />
          </div>
        );
      case filterControlTypeEnum.INTERNSHIP_STATUS:
        return (
          <div className={`${className} mb-3`} style={{ width: '18rem' }}>
            <div className="me-2">{placeholder}:</div>
            <FilterSelect
              name={fieldName}
              clearKey={clearKey}
              defaultValue={defaultValue}
              required
              loadService={internshipStatusService}
              isSearchable={isSearchable}
              isClearable={isClearable}
              onChange={(e) => {
                onChange(fieldName, e);
              }}
            />
          </div>
        );
      case filterControlTypeEnum.BOOL:
        return (
          <div className={`${className} mb-3`} style={{ width: '18rem' }}>
            <div className="me-2">{placeholder}:</div>
            <FilterSelect
              name={fieldName}
              clearKey={clearKey}
              defaultValue={defaultValue}
              required
              data={[
                { id: true, name: 'Ja' },
                { id: false, name: 'Nee' }
              ]}
              isSearchable={isSearchable}
              isClearable={isClearable}
              onChange={(e) => {
                onChange(fieldName, e);
              }}
            />
          </div>
        );
      case filterControlTypeEnum.OFFICE_REGION:
        return (
          <div className={`${className} mb-3`} style={{ width: '18rem' }}>
            <div className="">{placeholder}:</div>
            <FilterSelect
              name={fieldName}
              clearKey={clearKey}
              defaultValue={defaultValue}
              required
              loadService={regionService}
              isSearchable={isSearchable}
              isClearable={isClearable}
              onChange={(e) => {
                onChange(fieldName, e);
              }}
            />
          </div>
        );
      default:
        return undefined;
    }
  }

  useEffect(() => {
    setControl(getControl());
  }, [clearKey]);
  return control;
};

export default filterControl;
