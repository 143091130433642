import React, { useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';

function ErrorToast({ error, isVisible, handleClose }) {
  useEffect(() => {}, [isVisible]);
  return (
    <Toast
      onClose={() => handleClose()}
      show={isVisible}
      delay={3000}
      autohide
      className="toast-bottom-right"
    >
      <Toast.Header>
        <i className="bi bi-exclamation-circle-fill me-1" />
        <strong className="me-auto"> Er is iets fout gegaan..</strong>
      </Toast.Header>
      <Toast.Body>{error}</Toast.Body>
    </Toast>
  );
}

export default ErrorToast;
