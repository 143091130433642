import entityService from './base/entity.service';

const internshipTypeService = () => {
  const baseService = entityService('internshiptypes');

  const that = {};

  that.getAll = baseService.getAll;

  that.getSingle = baseService.getSingle;
  that.create = baseService.create;
  that.update = baseService.update;
  that.delete = baseService.delete;
  that.export = baseService.export;
  that.archive = baseService.archive;
  that.unArchive = baseService.unArchive;

  that.statistics = async () => {
    const response = await baseService.axios.get(`${baseService.apiUrl}${baseService.path}/statistics`);
    return response.data;
  };

  return that;
};

export default internshipTypeService;
