import React from 'react';
import _ from 'lodash';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import VisibilitySensor from 'react-visibility-sensor';
import 'react-circular-progressbar/dist/styles.css';

function CircularProgressBar({ percentage }) {
  return (
    <div style={{ width: '6rem', margin: 'auto' }}>
      <VisibilitySensor>
        {({ isVisible }) => {
          const perc = isVisible ? percentage : 0;
          return (
            <CircularProgressbar
              value={perc}
              text={`${_.round(perc, 1)}%`}
              strokeWidth={5}
              styles={buildStyles({
                pathTransition: perc === 0 ? 'none' : 'stroke-dashoffset 0.5s ease 0.2s',
                pathColor: '#7575CB',
                trailColor: '#7575CB',
                textColor: '#7575CB'
              })}
            />
          );
        }}
      </VisibilitySensor>
    </div>
  );
}

export default CircularProgressBar;
