import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';

import EntityNote from '../../Common/EntityNote';
import ValidatedFormField from '../../Common/ValidatedFormField';
import FilterSelect from '../../Common/FilterSelect';
import mailTemplateService from '../../../services/mail-template.service';
import mailTemplateTypeEnum from '../../../enum/mail-template-type-enum';

function EditMailTemplateModal({ isVisible, handleClose, mailTemplateId }) {
  const [mailTemplate, setMailTemplate] = useState(null);
  const [mailTemplateTypeId, setMailTemplateTypeId] = useState(mailTemplateTypeEnum.GENERAL_MAIL);
  const [isSystem, setIsSystem] = useState(true);
  const [description, setDescription] = useState('');

  const {
    trigger,
    register,
    getValues,
    reset,
    formState: { isValid, errors }
  } = useForm();

  const resetForm = () => {
    setDescription('');
    reset({ description });
  };

  const loadEntity = async () => {
    const mailTemplate = await mailTemplateService().getSingle(mailTemplateId);
    setMailTemplate(mailTemplate);
    setMailTemplateTypeId(mailTemplate.mailTemplateTypeId);
    setDescription(mailTemplate.description);
    if (mailTemplate.system === false) {
      setIsSystem(false);
    }
  };

  const mailTemplateSaveService = () => {
    const that = {};
    that.update = async (id, entity) => {
      await trigger();
      const { message } = entity;
      if (isValid) {
        const description = getValues('description');
        if (id) {
          await mailTemplateService().update(id, { message, description, mailTemplateTypeId });
        } else {
          await mailTemplateService().create({ mailTemplateTypeId, message, description });
        }
        if (handleClose) {
          handleClose();
        }
      }
    };

    that.delete = async (id) => {
      if (id) {
        await mailTemplateService().delete([id]);
      }
    };
    return that;
  };

  useEffect(() => {}, [mailTemplateId, mailTemplate, isSystem]);
  useEffect(() => {
    reset({ description });
  }, [description]);
  useEffect(() => {
    if (isVisible) {
      if (mailTemplateId) {
        loadEntity();
      } else {
        setMailTemplate({ description: '', message: '' });
        setMailTemplateTypeId(mailTemplateTypeEnum.GENERAL_MAIL);
      }

      resetForm();
    }
  }, [isVisible]);
  return (
    <Modal show={isVisible} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{mailTemplateId ? 'Mailtemplate bewerken' : 'Nieuwe mailtemplate'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form autoComplete="off">
          {(!isSystem || !mailTemplateId) && (
            <>
              <ValidatedFormField className="mb-4" errors={errors} name="mailTemplateTypeId" description="Template type">
                <FilterSelect
                  name="mailTemplateTypeId"
                  required
                  data={[
                    { id: mailTemplateTypeEnum.INTERNSHIP_APPROVAL, name: 'Stage goedkeuring' },
                    { id: mailTemplateTypeEnum.STUDENT_INFO_REQUEST, name: 'Student info aanvraag' },
                    { id: mailTemplateTypeEnum.OFFICE_PORTAL, name: 'Praktijk portaal uitnodiging' },
                    { id: mailTemplateTypeEnum.CONTACT_INTERNSHIP_SUBSCRIPTION, name: 'Uitnodiging stagebegeleiders' },
                    { id: mailTemplateTypeEnum.GENERAL_MAIL, name: 'Algemene mail' }
                  ]}
                  defaultValue={mailTemplateTypeId}
                  isSearchable={false}
                  isClearable={false}
                  onChange={(id) => {
                    setMailTemplateTypeId(id);
                  }}
                />
              </ValidatedFormField>
              <div className="row">
                <ValidatedFormField className="mb-4" errors={errors} name="description" description="Onderwerp">
                  <input
                    {...register('description', { required: true })}
                    name="description"
                    type="text"
                    className="form-control"
                    defaultValue={description}
                    autoComplete="false"
                    placeholder="vul hier het onderwerp in."
                  />
                </ValidatedFormField>
              </div>
            </>
          )}
        </form>

        <EntityNote entity={mailTemplate} entityService={mailTemplateSaveService} fieldName="message" showDelete={!isSystem} handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
}
export default EditMailTemplateModal;
