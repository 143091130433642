import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import PulseLoader from 'react-spinners/PulseLoader';
import { useNavigate } from 'react-router-dom';
import contactService from '../../services/contact.service';
import officeService from '../../services/office.service';
import ValidatedFormField from '../Common/ValidatedFormField';
import FormSelect from '../Common/FormSelect';

function EditContactModal({ isVisible, handleClose, contactId, officeId, publicView }) {
  const {
    register,
    handleSubmit,
    trigger,
    control,
    reset,
    formState: { isValid, errors }
  } = useForm();

  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(false);
  const [contact, setContact] = useState();
  const onSubmit = async (data) => {
    await trigger();
    if (isValid) {
      setIsBusy(true);
      if (contactId) {
        await contactService().update(contactId, data);
        handleClose(true);
      } else {
        const result = await contactService().create(data);
        if (result !== undefined && !publicView) {
          navigate(`/contacts/${result.id}`);
        } else {
          handleClose(true);
        }
      }

      setIsBusy(false);
    }
  };

  const loadEntity = async () => {
    const contact = await contactService().getSingle(contactId);
    setContact(contact);
  };

  useEffect(() => {
    reset(contact);
  }, [contact]);
  useEffect(() => {
    setContact({ officeId });
  }, [officeId]);
  useEffect(() => {
    if (isVisible && contactId) {
      setIsBusy(true);
      loadEntity();
      setIsBusy(false);
    }
  }, [isVisible]);
  return (
    <Modal show={isVisible} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{contactId ? 'Contact bewerken' : 'Nieuw contact'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <ValidatedFormField className="mb-4" errors={errors} name="firstName" description="Voornaam">
                <input
                  {...register('firstName', { required: true })}
                  name="firstName"
                  type="text"
                  className="form-control"
                  defaultValue={contact?.firstName}
                  autoComplete="false"
                  placeholder="vul hier de voornaam in."
                />
              </ValidatedFormField>

              <ValidatedFormField className="mb-4" errors={errors} name="lastName" description="Achternaam">
                <input
                  {...register('lastName', { required: true })}
                  type="text"
                  className="form-control"
                  defaultValue={contact?.lastName}
                  name="lastName"
                  autoComplete="false"
                  placeholder="vul hier de achternaam in."
                />
              </ValidatedFormField>
            </div>
            {!publicView && (
              <div className="row">
                <ValidatedFormField className="mb-4" errors={errors} name="officeId" description="Praktijk">
                  <FormSelect name="officeId" required defaultValue={contact?.officeId} loadService={officeService} control={control} iconClassName="bi-hospital" />
                </ValidatedFormField>
              </div>
            )}

            <div className="row">
              <div className="col col-sm">
                <ValidatedFormField className="mb-4" errors={errors} name="email" description="E-mail adres">
                  <div className="input-group input-group-merge">
                    <div className="input-group-prepend input-group-text">
                      <i className="bi bi-envelope" />
                    </div>
                    <input
                      {...register('email', { required: true })}
                      type="email"
                      className="form-control"
                      defaultValue={contact?.email}
                      name="email"
                      autoComplete="false"
                      placeholder="vul hier het algemene e-mail adres in."
                    />
                  </div>
                </ValidatedFormField>
              </div>
              <div className="col col-sm">
                <ValidatedFormField className="mb-4" errors={errors} name="phone" description="Telefoon">
                  <div className="input-group input-group-merge">
                    <div className="input-group-prepend input-group-text">
                      <i className="bi bi-telephone" />
                    </div>
                    <input
                      {...register('phone', { required: true })}
                      type="phone"
                      className="form-control"
                      defaultValue={contact?.phone}
                      name="phone"
                      autoComplete="false"
                      placeholder="vul hier het algemene telefoonnummer in."
                    />
                  </div>
                </ValidatedFormField>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary">
            {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Bewaren'}
          </button>
          <button type="button" className="btn btn-white" onClick={handleClose}>
            Sluiten
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default EditContactModal;
