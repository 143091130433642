import entityService from './base/entity.service';

const internshipStatusService = () => {
  const baseService = entityService('internshipstatuses');

  const that = {};

  that.getAll = baseService.getAll;

  that.getSingle = baseService.getSingle;
  return that;
};

export default internshipStatusService;
