import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import PulseLoader from 'react-spinners/PulseLoader';
import userService from '../../services/user.service';

function DeleteUser({ user, onError, handleClose }) {
  const [isBusy, setIsBusy] = useState(false);
  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { isValid }
  } = useForm();

  const onSubmit = async () => {
    await trigger();
    if (isValid) {
      try {
        setIsBusy(true);
        await userService().delete(user.id);
        handleClose();
      } catch {
        onError();
      }

      setIsBusy(false);
    }
  };

  useEffect(() => {
    reset(user);
  }, [user]);
  return (
    <div className="row">
      <div className="col-lg">
        <div className="card mb-3 mb-lg-5">
          <div className="card-header card-header-content-between">
            <h4 className="card-title">Verwijder deze gebruiker</h4>
          </div>
          <div className="card-body">
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              {user != null ? (
                <div>
                  <p className="my-4">Wanneer je deze gebruiker verwijdert zullen de gegevens beschikbaar blijven voor anderen. De gebruiker zal niet meer kunnen aanmelden op het platform.</p>
                  <div className="form-check">
                    <input {...register('confirmDelete')} className="form-check-input" name="confirmDelete" type="checkbox" />
                    <label className="form-check-label" htmlFor="deleteAccountCheckbox">
                      Bevestig dat je deze gebruiker wil verwijderen.
                    </label>
                  </div>

                  <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">
                      {isBusy ? <PulseLoader className="loader" color="#fff" size="5" /> : 'Verwijderen'}
                    </button>
                  </div>
                </div>
              ) : (
                <Skeleton count={3} />
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteUser;
