import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import EntityNote from '../Common/EntityNote';
import Timeline from '../Common/Timeline';
import officeService from '../../services/office.service';
import ErrorToast from '../Common/ErrorToast';
import ContactWidget from '../Contacts/ContactWidget';
import InternshipWidget from '../Internships/InternshipWidget';
import EditOfficeModal from './EditOfficeModal';
import EditContactModal from '../Contacts/EditContactModal';
import EditInternshipModal from '../Internships/EditInternshipModal';
import MailModal from '../Common/MailModal';
import mailTemplateTypeEnum from '../../enum/mail-template-type-enum';
import mailTemplateService from '../../services/mail-template.service';
import dateFormat from '../../helpers/date-format';

function Office() {
  const officeId = useParams().id;
  const [errorToastVisible, setErrorToastVisible] = useState(false);
  const [office, setOffice] = useState(null);

  const [editOfficeModalVisible, setEditOfficeModalVisible] = useState(false);
  const [editContactModalVisible, setEditContactModalVisible] = useState(false);
  const [editInternshipModalVisible, setEditInternshipModalVisible] = useState(false);
  const [mailModalVisible, setMailModalVisible] = useState(false);
  const [mailTemplate, setMailTemplate] = useState();

  const closeErrorToast = () => {
    setErrorToastVisible(false);
  };

  const showErrorToast = () => {
    setErrorToastVisible(true);
  };

  const fetchOffice = async () => {
    try {
      const response = await officeService().getSingle(officeId);
      setOffice(response);
    } catch (error) {
      showErrorToast();
    }
  };

  const fetchMailTemplate = async () => {
    const response = await mailTemplateService().getAll({ mailTemplateTypeId: mailTemplateTypeEnum.OFFICE_PORTAL });
    setMailTemplate(response.data?.rows[0]?.message);
  };

  const handleMailModalClose = async (data) => {
    if (data && data.message && officeId) {
      await officeService().sendPortalLink([officeId], data.message);
      setMailModalVisible(true);
    }

    setMailModalVisible(false);
    await fetchOffice();
  };

  const handleEditInternshipModalClose = async (modified) => {
    if (modified) {
      await fetchOffice();
    }
    setEditInternshipModalVisible(false);
  };

  const handleEditInternshipModalShow = () => {
    setEditInternshipModalVisible(true);
  };

  const handleEditOfficeModalShow = () => {
    setEditOfficeModalVisible(true);
  };

  const handleEditOfficeModalClose = async (modified) => {
    if (modified) {
      await fetchOffice();
    }
    setEditOfficeModalVisible(false);
  };

  const handleEditContactModalShow = () => {
    setEditContactModalVisible(true);
  };

  const handleEditContactModalClose = () => {
    setEditContactModalVisible(false);
  };

  const cancelToken = async () => {
    await officeService().cancelToken(office.id);
    await fetchOffice();
  };

  useEffect(() => {
    fetchOffice();
    fetchMailTemplate();
  }, []);
  return (
    <>
      <EditOfficeModal
        isVisible={editOfficeModalVisible}
        officeId={officeId}
        handleClose={(e) => {
          handleEditOfficeModalClose(e);
        }}
      />
      <EditInternshipModal
        isVisible={editInternshipModalVisible}
        officeId={officeId}
        handleClose={(e) => {
          handleEditInternshipModalClose(e);
        }}
      />
      <EditContactModal
        isVisible={editContactModalVisible}
        officeId={office?.id}
        handleClose={(e) => {
          handleEditContactModalClose(e);
        }}
      />
      <MailModal isVisible={mailModalVisible} message={mailTemplate} recipientNames={office?.email} handleClose={handleMailModalClose} modalTitle="Toegang UA Stage portaal" />
      <div className="page-header">
        <ErrorToast handleClose={() => closeErrorToast()} isVisible={errorToastVisible} error="Data kon niet geladen worden. Mogelijk geen internet connectie!" />
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-no-gutter">
                <li className="breadcrumb-item">
                  <a className="breadcrumb-link" href="/offices">
                    Praktijken
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Details
                </li>
              </ol>
            </nav>
            <div className="d-flex gap-2">
              <div>
                <h1 className="page-header-title d-inline">{office?.name || <Skeleton count={1} />}</h1>
                <div className="mt-3">
                  {office?.longInternships === true && <span className="badge bg-soft-primary text-primary rounded-pill me-2">Lange stages</span>}
                  {office?.shortInternships === true && <span className="badge bg-soft-primary text-primary rounded-pill me-2">Korte stages</span>}
                </div>
              </div>
              <button type="button" className="btn btn-white btn-icon btn-xs rounded-circle" aria-expanded="false" onClick={handleEditOfficeModalShow}>
                <i className="bi-pencil-fill" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Algemeen</h4>
            </div>
            <div className="card-body">
              {office != null ? (
                <ul className="list-group list-group-flush list-group-no-gutters">
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Contact info</h5>
                      <button type="button" className="btn btn-link" onClick={handleEditOfficeModalShow}>
                        Aanpassen
                      </button>
                    </div>

                    <ul className="list-unstyled list-py-1 text-body">
                      <li>
                        <i className="bi-at me-1" />
                        {office.email}
                      </li>
                      <li>
                        <i className="bi-phone me-1" />
                        {office.phone}
                      </li>
                    </ul>
                  </li>

                  <li className="list-group-item">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Adresgegevens</h5>
                      <button type="button" className="btn btn-link" onClick={handleEditOfficeModalShow}>
                        Aanpassen
                      </button>
                    </div>
                    <span className="d-block text-body">
                      {office.addressLine1}
                      {office.addressLine2 && (
                        <>
                          <br />
                          {office.addressLine2}
                        </>
                      )}
                      <br />
                      {office.postalCode} - {office.city?.name}
                    </span>
                  </li>
                </ul>
              ) : (
                <Skeleton count={3} />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Bijkomende info</h4>
            </div>
            <div className="card-body">
              {office != null ? (
                <>
                  <ul className="list-group list-group-flush list-group-no-gutters">
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>Kenmerken</h5>
                        <button type="button" className="btn btn-link" onClick={handleEditOfficeModalShow}>
                          Aanpassen
                        </button>
                      </div>
                      <ul className="list-unstyled list-py-1 text-body">
                        <li>
                          <i className="bi-briefcase-fill me-1" />
                          {office.type}
                        </li>
                        <li>
                          <i className="bi-cash-coin me-1" />
                          {office.formOfFinance}
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="list-group list-group-flush list-group-no-gutters">
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>Portaal</h5>
                        <div>
                          <button type="button" className="btn btn-link" onClick={() => setMailModalVisible(true)}>
                            Portaal link versturen
                          </button>
                          {office?.tokenExpiration && (
                            <button type="button" className="btn btn-link" onClick={cancelToken}>
                              Toegang intrekken
                            </button>
                          )}
                        </div>
                      </div>
                      <ul className="list-unstyled list-py-1 text-body">
                        <li>
                          <i className="bi-layout-text-window me-1" />
                          Toegang tot: {dateFormat(office?.tokenExpiration)}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </>
              ) : (
                <Skeleton count={3} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Stages</h4>
              <button type="button" className="btn btn-ghost-secondary" onClick={handleEditInternshipModalShow}>
                <i className="bi-plus" /> Nieuwe stage
              </button>
            </div>
            {office ? <InternshipWidget filters={{ officeId }} /> : <Skeleton count={5} />}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Contacten</h4>
              <button type="button" className="btn btn-ghost-secondary" onClick={handleEditContactModalShow}>
                <i className="bi-plus" /> Nieuw contact
              </button>
            </div>
            {office ? <ContactWidget filters={{ officeId }} /> : <Skeleton count={5} />}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Notities</h4>
            </div>
            <EntityNote entity={office} entityService={officeService} />
          </div>
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Infrastructuur</h4>
            </div>
            <EntityNote entity={office} fieldName="infrastructure" entityService={officeService} />
          </div>
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Leeropportuniteiten</h4>
            </div>
            <EntityNote entity={office} fieldName="learningOpportunities" entityService={officeService} />
          </div>
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Disciplines</h4>
            </div>
            <EntityNote entity={office} fieldName="disciplines" entityService={officeService} />
          </div>
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Religieuze tekens</h4>
            </div>
            <EntityNote entity={office} fieldName="religiousSigns" entityService={officeService} />
          </div>
        </div>
        <div className="col-lg-6">
          <Timeline officeId={officeId} />
        </div>
      </div>
    </>
  );
}

export default Office;
