import React, { useState, useEffect } from 'react';

import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import './rich-editor.scss';

function RichEditor({ onChange, onInitEditorState, editorStateJson }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const editor = React.useRef(null);

  const focusEditor = () => {
    editor.current.focus();
  };

  const onEditorChange = (e) => {
    if (onChange) {
      onChange(convertToRaw(editorState.getCurrentContent(e)));
    }
    setEditorState(e);
  };

  const onInlineClick = (e, style) => {
    e.preventDefault();
    const nextState = RichUtils.toggleInlineStyle(editorState, style);
    setEditorState(nextState);
  };

  const onBlockClick = (e, style) => {
    e.preventDefault();
    const nextState = RichUtils.toggleBlockType(editorState, style);
    setEditorState(nextState);
  };

  useEffect(() => {
    const contentState = editorStateJson ? convertFromRaw(editorStateJson) : undefined;
    const editorState = contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty();
    setEditorState(editorState);
    if (contentState && onInitEditorState) {
      onInitEditorState(convertToRaw(editorState.getCurrentContent()));
    }
  }, [editorStateJson]);
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div onClick={focusEditor} className="quill-custom">
      <div className="ql-toolbar ql-snow">
        <span className="ql-formats">
          <button type="button" className="ql-bold" onMouseDown={(e) => onInlineClick(e, 'BOLD')}>
            <i className="bi bi-type-bold" />
          </button>
          <button type="button" className="ql-bold" onMouseDown={(e) => onInlineClick(e, 'ITALIC')}>
            <i className="bi bi-type-italic" />
          </button>
          <button type="button" className="ql-bold" onMouseDown={(e) => onInlineClick(e, 'UNDERLINE')}>
            <i className="bi bi-type-underline" />
          </button>
          <button type="button" className="ql-bold" onMouseDown={(e) => onBlockClick(e, 'unordered-list-item')}>
            <i className="bi bi-list-ul" />
          </button>
          <button type="button" className="ql-bold" onMouseDown={(e) => onBlockClick(e, 'ordered-list-item')}>
            <i className="bi bi-list-ol" />
          </button>
        </span>
      </div>
      <div className="ql-editor ql-blank">
        <Editor ref={editor} editorState={editorState} onChange={onEditorChange} />
      </div>
    </div>
  );
}

export default RichEditor;
