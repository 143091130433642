import React, { useState, useEffect } from 'react';
import TableList from '../Common/TableList';
import EditContactModal from './EditContactModal';
import contactService from '../../services/contact.service';
import internshipService from '../../services/internship.service';
import SubscriptionMailModel from './SubscriptionMailModal';
import BulkMailModel from '../Common/BulkMailModal';
import mailTemplateTypeEnum from '../../enum/mail-template-type-enum';
import mailTemplateService from '../../services/mail-template.service';

function ContactList() {
  const [editContactModalVisible, setEditContactModalVisible] = useState(false);
  const [mailTemplate, setMailTemplate] = useState();
  const [mailModalVisible, setMailModalVisible] = useState(false);
  const [bulkMailModalVisible, setBulkMailModalVisible] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const handleEditContactModalShow = () => {
    setEditContactModalVisible(true);
  };

  const handleEditContactModalClose = () => {
    setEditContactModalVisible(false);
  };

  const fetchMailTemplate = async () => {
    const response = await mailTemplateService().getAll({ mailTemplateTypeId: mailTemplateTypeEnum.CONTACT_INTERNSHIP_SUBSCRIPTION });
    setMailTemplate(response.data?.rows[0]?.message);
  };

  const handleMailModalClose = async (data) => {
    if (data && data.message) {
      await internshipService().requestSubscriptions(selectedContacts, data.message, data.internshipTypes);
    }

    setMailModalVisible(false);
  };

  const handleBulkRequestSubscriptions = (selected) => {
    setSelectedContacts(selected);
    setMailModalVisible(true);
  };

  const handleBulkMail = (selected) => {
    setSelectedContacts(selected);
    setBulkMailModalVisible(true);
  };

  const handleBulkMailModalClose = async () => {
    setBulkMailModalVisible(false);
  };

  const actionButtons = [
    {
      description: 'Stageplaats bevraging',
      iconClassName: 'bi-envelope me-2',
      action: handleBulkRequestSubscriptions
    },
    {
      description: 'Mail versturen',
      iconClassName: 'bi-envelope me-2',
      action: handleBulkMail
    }
  ];

  const cols = [
    {
      fieldName: 'id',
      description: 'Nr',
      sortable: true
    },
    {
      fieldName: 'firstName',
      description: 'Voornaam',
      sortable: true
    },
    {
      fieldName: 'lastName',
      description: 'Achternaam',
      sortable: true
    },
    {
      fieldName: 'office.name',
      description: 'Praktijk',
      sortable: false,
      link: { type: 'office', fieldName: 'officeId' }
    },
    {
      fieldName: 'email',
      description: 'E-mail',
      sortable: false
    },
    {
      fieldName: 'phone',
      description: 'Telefoon',
      sortable: false
    },
    {
      fieldName: '',
      description: '',
      editBtn: { type: 'contact', fieldName: 'id', description: 'bekijk' }
    }
  ];

  useEffect(() => {
    fetchMailTemplate();
  }, []);
  useEffect(() => {}, [editContactModalVisible, mailTemplate]);
  return (
    <>
      <EditContactModal
        isVisible={editContactModalVisible}
        handleClose={() => {
          handleEditContactModalClose();
        }}
      />
      <SubscriptionMailModel isVisible={mailModalVisible} selectedIds={selectedContacts} message={mailTemplate} handleClose={handleMailModalClose} modalTitle="Voorkeur bevraging versturen" />
      <BulkMailModel isVisible={bulkMailModalVisible} selectedIds={selectedContacts} handleClose={handleBulkMailModalClose} modalTitle="Mail versturen" entityService={contactService} />
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-no-gutter">
                <li className="breadcrumb-item">
                  <a className="breadcrumb-link" href="/stages">
                    Contacten
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Lijst
                </li>
              </ol>
            </nav>

            <h1 className="page-header-title">Contacten</h1>
          </div>
          <div className="col-sm-auto">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleEditContactModalShow();
              }}
            >
              <i className="bi-plus me-1" />
              Nieuw contact
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <TableList
          entityType="contacten"
          columnDefinition={cols}
          deleteItems={contactService().delete}
          getItems={contactService().getAll}
          exportItems={contactService().export}
          archiveItems={contactService().archive}
          unArchiveItems={contactService().unArchive}
          actionButtons={actionButtons}
        />
      </div>
    </>
  );
}

export default ContactList;
