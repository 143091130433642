/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import paginationHelper from '../../helpers/pagination.helper';

function PaginationCtrl({ currentPage, pageSize, totalItems, pageChanged, pageSizeChanged }) {
  const [items, setItems] = useState([]);

  const onPageSizeChange = (limit) => {
    pageSizeChanged(limit);
    pageChanged(1);
  };

  useEffect(() => {
    const pagination = paginationHelper(currentPage, Math.ceil(totalItems / pageSize));
    const newItems = [];

    if (pagination) {
      newItems.push(<Pagination.First onClick={() => pageChanged(1)} key="first" disabled={pagination.current === 1} />);
      newItems.push(<Pagination.Prev onClick={() => pageChanged(pagination.prev)} key="prev" disabled={pagination.prev == null} />);
      for (let i = 0; i < pagination.items.length; i += 1) {
        if (pagination.items[i] !== '…') {
          newItems.push(
            <Pagination.Item key={pagination.items[i]} active={pagination.items[i] === currentPage} onClick={() => pageChanged(pagination.items[i])}>
              {pagination.items[i]}
            </Pagination.Item>
          );
        } else {
          newItems.push(<Pagination.Ellipsis key={`${pagination.items[i]}${i}`} disabled />);
        }
      }
      if (pagination.next) {
        newItems.push(<Pagination.Next key="next" onClick={() => pageChanged(pagination.next)} />);
        newItems.push(<Pagination.Last key="last" onClick={() => pageChanged(Math.ceil(totalItems / pageSize))} />);
      }
    }

    setItems(newItems);
  }, [currentPage, totalItems, pageSize]);

  return (
    <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
      <div className="col-sm mb-2 mb-sm-0">
        <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
          <span className="me-2">Getoond:</span>
          <div>
            <select className="form-select form-select-borderless w-auto ts-hidden-accessible" defaultValue={pageSize} onChange={(e) => onPageSizeChange(e.target.value)}>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
            </select>
          </div>

          <span className="text-secondary me-2">of</span>

          <span>{totalItems}</span>
        </div>
      </div>

      <div className="col-sm-auto">
        <div className="d-flex justify-content-center justify-content-sm-end">
          <Pagination>{items}</Pagination>
        </div>
      </div>
    </div>
  );
}

export default PaginationCtrl;
