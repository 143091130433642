import entityService from './base/entity.service';

const eventService = () => {
  const baseService = entityService('events');

  const that = {};

  that.getAll = baseService.getAll;

  that.getSingle = baseService.getSingle;
  that.create = baseService.create;
  that.update = baseService.update;
  that.delete = baseService.delete;

  return that;
};

export default eventService;
