import React, { useState, useEffect } from 'react';
import TableList from '../Common/TableList';
import EditInternshipTypeModal from './EditInternshipTypeModal';
import internshipTypeService from '../../services/internship-type.service';

function InternshipTypeList() {
  const [editInternshipTypeModalVisible, setEditInternshipTypeModalVisible] = useState(false);

  const handleEditInternshipTypeModalShow = () => {
    setEditInternshipTypeModalVisible(true);
  };

  const handleEditInternshipTypeModalClose = () => {
    setEditInternshipTypeModalVisible(false);
  };

  const cols = [
    {
      fieldName: 'id',
      description: 'Nr',
      sortable: true
    },
    {
      fieldName: 'name',
      description: 'Naam',
      sortable: true
    },
    {
      fieldName: 'start',
      description: 'Start',
      type: 'date',
      sortable: true
    },
    {
      fieldName: 'end',
      description: 'Einde',
      type: 'date',
      sortable: true
    },
    {
      fieldName: '',
      description: '',
      editBtn: { type: 'internshipType', fieldName: 'id', description: 'bekijk' }
    }
  ];
  useEffect(() => {}, [editInternshipTypeModalVisible]);
  return (
    <>
      <EditInternshipTypeModal
        isVisible={editInternshipTypeModalVisible}
        handleClose={() => {
          handleEditInternshipTypeModalClose();
        }}
      />
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-no-gutter">
                <li className="breadcrumb-item">
                  <a className="breadcrumb-link" href="/stages">
                    Stagetypes
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Lijst
                </li>
              </ol>
            </nav>

            <h1 className="page-header-title">Stagetypes</h1>
          </div>
          <div className="col-sm-auto">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleEditInternshipTypeModalShow();
              }}
            >
              <i className="bi-plus me-1" />
              Nieuw stagetype
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <TableList
          entityType="stagetypes"
          columnDefinition={cols}
          deleteItems={internshipTypeService().delete}
          getItems={internshipTypeService().getAll}
          archiveItems={internshipTypeService().archive}
          unArchiveItems={internshipTypeService().unArchive}
          exportItems={internshipTypeService().export}
        />
      </div>
    </>
  );
}

export default InternshipTypeList;
