import entityService from './base/entity.service';

const internshipService = () => {
  const baseService = entityService('internships');

  const that = {};

  that.getAll = (page, pageSize, sortField, sortOrder, term, filters) => {
    if (filters.includePlanned === true) {
      filters.excludePlanned = false;
    } else if (filters.includePlanned === false) {
      filters.excludePlanned = true;
    }
    return baseService.getAll(page, pageSize, sortField, sortOrder, term, filters);
  };

  that.requestSubscriptions = async (ids, message, internshipTypes) => {
    const response = await baseService.axios.post(`${baseService.apiUrl}${baseService.path}/requestSubscriptions`, { ids, message, internshipTypes });
    return response.status === 200 ? response.data : undefined;
  };

  that.processSubscriptions = async (token, id, internshipTypeIds) => {
    const response = await baseService.axios.post(`${baseService.apiUrl}${baseService.path}/processSubscriptions/${id}/${token}`, { internshipTypeIds });
    return response.status === 200 ? response.data : undefined;
  };

  that.requestApproval = async (id, message, contactId) => {
    const response = await baseService.axios.post(`${baseService.apiUrl}${baseService.path}/requestApproval/${id}`, { message, contactId });
    return response.status === 200 ? response.data : undefined;
  };

  that.approve = async (token, internshipStatusId, denialReason) => {
    const response = await baseService.axios.post(`${baseService.apiUrl}${baseService.path}/approve/${token}`, { internshipStatusId, denialReason });
    return response.status === 200 ? response.data : undefined;
  };

  that.getSingleByToken = async (token) => {
    const response = await baseService.axios.get(`${baseService.apiUrl}${baseService.path}/bytoken/${token}`);
    return response.data;
  };

  that.getSubscriptionRequest = async (token, id) => {
    const response = await baseService.axios.get(`${baseService.apiUrl}${baseService.path}/getInternshipTypesBySubscriptionRequestToken/${id}/${token}`);
    return response.data;
  };

  that.startAutomatching = async (internshipTypeId) => {
    const response = await baseService.axios.post(`${baseService.apiUrl}${baseService.path}/automatching/start`, { internshipTypeId });
    return response.status === 200 ? response.data : undefined;
  };

  that.stopAutomatching = async (jobId) => {
    const response = await baseService.axios.post(`${baseService.apiUrl}${baseService.path}/automatching/stop/${jobId}`);
    return response.status === 200 ? response.data : undefined;
  };

  that.getAutomatchingJobs = async () => {
    const response = await baseService.axios.get(`${baseService.apiUrl}${baseService.path}/automatching/status`);
    return response.status === 200 ? response.data : undefined;
  };

  that.getSingle = baseService.getSingle;
  that.create = baseService.create;
  that.update = baseService.update;
  that.delete = baseService.delete;
  that.export = baseService.export;
  that.archive = baseService.archive;
  that.unArchive = baseService.unArchive;

  return that;
};

export default internshipService;
