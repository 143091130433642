import React from 'react';

function ValidatedFormField({ className = '', children, errors, name, description, tooltip }) {
  return (
    <div className={className}>
      <label htmlFor={name} className="form-label">
        {description}
        {tooltip && <i className="bi-question-circle text-body ms-1" data-toggle="tooltip" data-placement="top" title={description} />}
      </label>
      {children}
      <span className="invalid-feedback ms-1" style={{ display: errors[name] ? 'block' : 'none' }}>
        Gelieve een {description.toLowerCase()} op te geven.
      </span>
    </div>
  );
}

export default ValidatedFormField;
