import axios from 'axios';
import config from '../config';

const importService = () => {
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        window.location = '/login';
      }
      throw error;
    }
  );

  const that = {};

  that.uploadImportFile = async (csvFile, type) => {
    const formData = new FormData();
    formData.append('importFile', csvFile);
    formData.append('type', type);
    const response = await axios.post(`${config.apiUrl}imports/uploadImportFile`, formData);
    return response.status === 200 ? response.data : undefined;
  };

  return that;
};

export default importService;
