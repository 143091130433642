import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import _ from 'lodash';
import CircularProgressBar from '../Common/CircularProgressBar';
import Timeline from '../Common/Timeline';
import InternshipWidget from '../Internships/InternshipWidget';
import internshipStatusEnum from '../../enum/internship-status-enum';
import ErrorToast from '../Common/ErrorToast';
import internshipTypeService from '../../services/internship-type.service';
import studentService from '../../services/student.service';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.font.size = 14;
ChartJS.register(ArcElement, Tooltip, Legend);

function Dashboard() {
  const [percInRequest, setPercInRequest] = useState(0.0);
  const [percConfirmed, setPercConfirmed] = useState(0);
  const [percDenied, setPercDenied] = useState(0);
  const [percIsNew, setPercIsNew] = useState(0);

  const [inRequest, setInRequest] = useState(0.0);
  const [confirmed, setConfirmed] = useState(0);
  const [denied, setDenied] = useState(0);
  const [isNew, setIsNew] = useState(0);

  const [studentStats, setStudentStats] = useState(0);

  const [errorToastVisible, setErrorToastVisible] = useState(false);
  const calculateFeedback = (data) => {
    let total = 0;

    total += _.sumBy(data, (item) => _.parseInt(item.count));

    const isNew = parseInt(data.filter((f) => f.internshipStatusId === internshipStatusEnum.NEW)[0]?.count, 10) || 0;
    const request = parseInt(data.filter((f) => f.internshipStatusId === internshipStatusEnum.REQUESTED)[0]?.count, 10) || 0;
    const confirmed = parseInt(data.filter((f) => f.internshipStatusId === internshipStatusEnum.CONFIRMED)[0]?.count, 10) || 0;
    const denied = parseInt(data.filter((f) => f.internshipStatusId === internshipStatusEnum.DECLINED)[0]?.count, 10) || 0;

    setPercInRequest((request * 100) / total || 0);
    setPercConfirmed((confirmed * 100) / total || 0);
    setPercDenied((denied * 100) / total || 0);
    setPercIsNew((isNew * 100) / total || 0);
    setInRequest(request);
    setConfirmed(confirmed);
    setDenied(denied);
    setIsNew(isNew);
  };

  const showErrorToast = () => {
    setErrorToastVisible(true);
  };

  const closeErrorToast = () => {
    setErrorToastVisible(false);
  };

  const fetchInternshipStatistics = async () => {
    try {
      const response = await internshipTypeService().statistics();
      calculateFeedback(response);
    } catch (error) {
      showErrorToast();
    }
  };

  const fetchStudentStatistics = async () => {
    try {
      const response = await studentService().statistics();
      const studentsWith = parseInt(response.filter((f) => f.hasInternships === true)[0]?.count, 10) || 0;
      const studentsWithout = parseInt(response.filter((f) => f.hasInternships === false)[0]?.count, 10) || 0;
      setStudentStats({ studentsWith, studentsWithout });
      console.log(studentStats);
    } catch (error) {
      showErrorToast();
    }
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'center'
      }
    }
  };
  const data = {
    labels: ['Met stage', 'Zonder stage'],
    datasets: [
      {
        label: 'studenten',
        data: [studentStats?.studentsWith, studentStats?.studentsWithout],
        backgroundColor: ['#7575CB', '#EE8484'],
        borderColor: ['#7575CB', '#EE8484'],
        borderWidth: 1
      }
    ]
  };
  useEffect(() => {}, [percInRequest, percConfirmed, percDenied]);
  useEffect(() => {
    fetchInternshipStatistics();
    fetchStudentStatistics();
  }, []);
  return (
    <>
      <div className="page-header">
        <ErrorToast handleClose={() => closeErrorToast()} isVisible={errorToastVisible} error="Data kon niet geladen worden. Mogelijk geen internet connectie!" />
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">Dashboard</h1>
          </div>
        </div>
      </div>
      <div className="overflow-auto">
        <div className="row">
          <div className="col-lg-6">
            <Timeline canEdit={false} height="50vh" />
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-subtitle mt-2">Studenten vs stage</h4>
              </div>
              <div className="card-body d-flex justify-content-center p-6" style={{ height: '50vh' }}>
                <Doughnut data={data} options={options} style={{ maxHeight: '25rem', maxWidth: '25rem' }} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="card mb-3 mb-lg-5">
              <div className="card-header card-header-content-between">
                <h4 className="card-header-title">Geweigerde stages</h4>
              </div>
              <InternshipWidget filters={{ internshipStatusId: internshipStatusEnum.DECLINED }} />
            </div>
            <div className="card mb-3 mb-lg-5">
              <div className="card-header card-header-content-between">
                <h4 className="card-header-title">Stages in afwachting van goedkeuring</h4>
              </div>
              <InternshipWidget filters={{ internshipStatusId: internshipStatusEnum.REQUESTED }} />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-subtitle mt-2">Stages volgens status</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-3 mb-3 mb-xl-6 d-flex justify-content-center text-center flex-column">
                    <h4 className="mb-3">
                      <span
                        className="legend-indicator"
                        style={{
                          backgroundColor: '#377dff'
                        }}
                      />
                      Nieuw
                    </h4>
                    <CircularProgressBar percentage={percIsNew} />
                    <h5 className="mt-2">{isNew}</h5>
                  </div>
                  <div className="col-3 mb-3 mb-xl-6 d-flex justify-content-center text-center flex-column">
                    <h4 className="mb-3">
                      <span
                        className="legend-indicator"
                        style={{
                          backgroundColor: '#377dff'
                        }}
                      />
                      In aanvraag
                    </h4>
                    <CircularProgressBar percentage={percInRequest} />
                    <h5 className="mt-2">{inRequest}</h5>
                  </div>
                  <div className="col-3 mb-3 mb-xl-6 d-flex justify-content-center text-center flex-column">
                    <h4 className="mb-3">
                      <span
                        className="legend-indicator"
                        style={{
                          backgroundColor: 'rgb(0, 201, 167)'
                        }}
                      />
                      Bevestigd
                    </h4>
                    <CircularProgressBar percentage={percConfirmed} />
                    <h5 className="mt-2">{confirmed}</h5>
                  </div>

                  <div className="col-3 mb-3 mb-xl-6 d-flex justify-content-center text-center flex-column">
                    <h4 className="mb-3">
                      <span
                        className="legend-indicator"
                        style={{
                          backgroundColor: 'rgb(237, 76, 120)'
                        }}
                      />
                      Geweigerd
                    </h4>
                    <CircularProgressBar percentage={percDenied} />
                    <h5 className="mt-2">{denied}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
