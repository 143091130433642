import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import PulseLoader from 'react-spinners/PulseLoader';
import RichEditor from './RichEditor';

function MailModal({ modalTitle, isVisible, handleClose, selectedIds, message, recipientNames }) {
  const [isBusy, setIsBusy] = useState(false);
  const [editorState, setEditorState] = useState();

  const {
    handleSubmit,
    trigger,

    register,
    formState: { isValid }
  } = useForm();

  const resetForm = () => {
    setEditorState(message);
  };

  const onSubmit = async (data) => {
    await trigger();
    if (isValid) {
      setIsBusy(true);
      data.message = editorState;
      data.ids = selectedIds;
      await handleClose(data);
      resetForm();
      setIsBusy(false);
    }
  };

  const onClose = () => {
    handleClose();
  };

  const onEditorChange = (e) => {
    setEditorState(e);
  };

  useEffect(() => {}, [selectedIds]);
  useEffect(() => {
    resetForm();
  }, [isVisible]);
  useEffect(() => {}, [isBusy, message]);

  return (
    <Modal show={isVisible} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <input
                type="text"
                value={`Ontvangers: ${recipientNames || selectedIds?.length}`}
                className="form-control mb-4"
                disabled
                name="recipient"
                {...register('recipient', { required: false })}
              />
            </div>
            <div className="row">
              <RichEditor onChange={(e) => onEditorChange(e)} onInitEditorState={(e) => onEditorChange(e)} editorStateJson={message} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary">
            {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Verzenden'}
          </button>
          <button type="button" className="btn btn-white" onClick={onClose}>
            Sluiten
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default MailModal;
