import entityService from './base/entity.service';

const mailTemplateService = () => {
  const baseService = entityService('mailtemplates');

  const that = {};

  that.getAll = ({ mailTemplateTypeId }) => baseService.getAll(1, 250, undefined, undefined, undefined, { mailTemplateTypeId });

  that.getSingle = baseService.getSingle;
  that.create = baseService.create;
  that.update = baseService.update;
  that.delete = baseService.delete;

  return that;
};

export default mailTemplateService;
