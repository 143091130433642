import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import PulseLoader from 'react-spinners/PulseLoader';

import RichEditor from './RichEditor';
import FilterSelect from './FilterSelect';
import ValidatedFormField from './ValidatedFormField';
import mailTemplateService from '../../services/mail-template.service';
import mailTemplateTypeEnum from '../../enum/mail-template-type-enum';

function BulkMailModel({ modalTitle, isVisible, handleClose, selectedIds, entityService }) {
  const [isBusy, setIsBusy] = useState(false);
  const [editorState, setEditorState] = useState();
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState(null);

  const [mailTemplates, setMailTemplates] = useState([]);

  const {
    handleSubmit,
    trigger,
    register,
    reset,
    formState: { isValid, errors }
  } = useForm();

  const resetForm = () => {
    reset({ description });
  };

  const loadSelectedTemplate = (id) => {
    if (id) {
      const selectedTemplate = mailTemplates.find((template) => template.id === id);
      if (selectedTemplate) {
        setMessage(selectedTemplate.message);
        setDescription(selectedTemplate.description);
      }
    }
  };

  const loadTemplates = async () => {
    const result = await mailTemplateService().getAll({ mailTemplateTypeId: mailTemplateTypeEnum.GENERAL_MAIL });
    if (result.data && result.data.rows) {
      setMailTemplates(result.data.rows);
      loadSelectedTemplate(result.data.rows[0]?.id);
    }
  };

  const onSubmit = async (data) => {
    await trigger();
    if (isValid) {
      setIsBusy(true);
      const message = editorState;
      const ids = selectedIds;

      await entityService().sendMail(ids, message, data.description);

      await handleClose();
      resetForm();
      setIsBusy(false);
    }
  };

  const onClose = () => {
    resetForm();
    handleClose();
  };

  const onEditorChange = (e) => {
    setEditorState(e);
  };

  useEffect(() => {
    loadTemplates();
    resetForm();
  }, [isVisible]);
  useEffect(() => {
    resetForm();
  }, [description]);
  useEffect(() => {}, [isBusy, mailTemplates]);
  useEffect(() => {}, [selectedIds]);
  return (
    <Modal show={isVisible} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <input type="text" value={`Ontvangers: ${selectedIds.length}`} className="form-control mb-4" disabled name="recipient" {...register('recipient', { required: false })} />
            </div>
            {mailTemplates?.length > 0 && (
              <div className="row">
                <ValidatedFormField className="mb-4" errors={errors} name="mailTemplateId" description="Template type">
                  <FilterSelect
                    name="mailTemplateId"
                    required
                    optionLabels="description"
                    data={mailTemplates}
                    defaultValue={mailTemplates[0].id}
                    isSearchable={false}
                    isClearable={false}
                    onChange={(id) => {
                      loadSelectedTemplate(id);
                    }}
                  />
                </ValidatedFormField>
              </div>
            )}

            <div className="row">
              <ValidatedFormField className="mb-4" errors={errors} name="description" description="Onderwerp">
                <input
                  {...register('description', { required: true })}
                  name="description"
                  type="text"
                  className="form-control"
                  defaultValue={description}
                  autoComplete="false"
                  placeholder="vul hier het onderwerp in."
                />
              </ValidatedFormField>
            </div>
            <div className="row">
              <RichEditor onChange={(e) => onEditorChange(e)} onInitEditorState={(e) => onEditorChange(e)} editorStateJson={message} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary">
            {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Verzenden'}
          </button>
          <button type="button" className="btn btn-white" onClick={onClose}>
            Sluiten
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default BulkMailModel;
