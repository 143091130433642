import React, { useState, useEffect } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import TableList from '../Common/TableList';
import EditInternshipModal from './EditInternshipModal';
import AutomatchModal from './AutomatchModal';
import internshipService from '../../services/internship.service';
import filterControlTypeEnum from '../../enum/filter-control-type-enum';

function InternshipList() {
  const [editInternshipModalVisible, setEditInternshipModalVisible] = useState(false);
  const [automatchModalVisible, setAutomatchModalVisible] = useState(false);
  const [automatchRunning, setAutomatchRunning] = useState(false);
  const [refreshKey, setRefreshKey] = useState();

  const handleEditInternshipModalShow = () => {
    setEditInternshipModalVisible(true);
  };

  const handleEditInternshipModalClose = () => {
    setEditInternshipModalVisible(false);
  };

  const handleAutomatchClose = () => {
    setAutomatchModalVisible(false);
  };

  const handleAutomatchShow = () => {
    setAutomatchModalVisible(true);
  };

  const checkRunningAutomatchingJobs = async () => {
    const result = (await internshipService().getAutomatchingJobs())?.data?.rows;
    setAutomatchRunning(result.length > 0);
    if (result.length > 0) {
      setTimeout(() => {
        setRefreshKey(new Date());
      }, 5000); // 5000 milliseconds = 5 seconds
    }
  };

  const actionButtons = [
    {
      className: 'btn btn-primary btn-sm me-2 ms-2',
      description: <span>Automatching {automatchRunning && <PulseLoader className="loader d-inline" color="#fff" size="5px" />}</span>,
      iconClassName: 'bi-magic d-inline me-2',
      action: handleAutomatchShow,
      showAlways: true
    }
  ];

  const filterControls = [
    {
      filterControlType: filterControlTypeEnum.INTERNSHIP_TYPE,
      fieldName: 'internshipTypeId',
      placeholder: 'Stagetype'
    },
    {
      filterControlType: filterControlTypeEnum.INTERNSHIP_STATUS,
      fieldName: 'internshipStatusId',
      placeholder: 'Status'
    },
    {
      filterControlType: filterControlTypeEnum.BOOL,
      fieldName: 'includePlanned',
      placeholder: 'Student ingepland'
    }
  ];

  const cols = [
    {
      fieldName: 'id',
      description: 'Nr',
      sortable: true
    },
    {
      fieldName: 'internshipType.name',
      description: 'Type',
      sortable: false
    },
    {
      fieldName: 'student.fullName',
      description: 'Student',
      sortable: false,
      link: { type: 'student', fieldName: 'studentId' }
    },
    {
      fieldName: 'office.name',
      description: 'Praktijk',
      sortable: false,
      link: { type: 'office', fieldName: 'officeId' }
    },
    {
      fieldName: 'start',
      description: 'Start',
      type: 'date',
      sortable: true
    },
    {
      fieldName: 'end',
      description: 'Einde',
      type: 'date',
      sortable: true
    },
    {
      fieldName: 'internshipStatus.name',
      description: 'Status',
      type: 'internshipStatus',
      sortable: false
    },
    {
      fieldName: '',
      description: '',
      editBtn: { type: 'internship', fieldName: 'id', description: 'bekijk' }
    }
  ];
  useEffect(() => {
    // Call the method initially
    checkRunningAutomatchingJobs();

    // Call the method every 5 seconds
    const intervalId = setInterval(checkRunningAutomatchingJobs, 5000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {}, [editInternshipModalVisible, automatchRunning]);
  return (
    <>
      <EditInternshipModal
        isVisible={editInternshipModalVisible}
        handleClose={() => {
          handleEditInternshipModalClose();
        }}
      />
      <AutomatchModal
        isVisible={automatchModalVisible}
        handleClose={() => {
          handleAutomatchClose();
        }}
      />
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-no-gutter">
                <li className="breadcrumb-item">
                  <a className="breadcrumb-link" href="/stages">
                    Stages
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Lijst
                </li>
              </ol>
            </nav>

            <h1 className="page-header-title">Stages</h1>
          </div>
          <div className="col-sm-auto">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleEditInternshipModalShow();
              }}
            >
              <i className="bi-plus me-1" />
              Nieuwe stage
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <TableList
          refreshKey={refreshKey}
          entityType="stages"
          columnDefinition={cols}
          deleteItems={internshipService().delete}
          archiveItems={internshipService().archive}
          unArchiveItems={internshipService().unArchive}
          getItems={internshipService().getAll}
          exportItems={internshipService().export}
          filterControls={filterControls}
          actionButtons={actionButtons}
        />
      </div>
    </>
  );
}

export default InternshipList;
