import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PulseLoader from 'react-spinners/PulseLoader';
import RichEditor from './RichEditor';

function EntityNote({ entity, entityService, fieldName, showDelete, handleClose }) {
  const [initialState, setInitialState] = useState(entity?.note);
  const [noteEditorState, setNoteEditorState] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const { handleSubmit } = useForm();

  const onNoteEditorChange = (e) => {
    setNoteEditorState(e);
    setIsDirty(true);
  };

  const onSubmitNote = async () => {
    if (entity) {
      if (fieldName) {
        entity[fieldName] = noteEditorState;
      } else {
        entity.note = noteEditorState;
      }
    }

    setIsBusy(true);
    await entityService().update(entity?.id, entity ?? noteEditorState);
    setIsBusy(false);
    setIsDirty(false);
  };

  const onCancel = async () => {
    setInitialState(null);
    setIsDirty(false);
    if (handleClose) {
      handleClose();
    }
  };

  const onDelete = async () => {
    setIsBusy(true);
    await entityService().delete(entity?.id);
    setIsBusy(false);
    setIsDirty(false);
    if (handleClose) {
      handleClose();
    }
  };

  useEffect(() => {
    if (entity) {
      let state;
      if (fieldName) {
        state = entity[fieldName];
      } else {
        state = entity.note;
      }
      setInitialState(state);
    } else {
      setInitialState(null);
    }
  }, [entity, initialState]);
  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmitNote)}>
      <div className="card-body">
        <RichEditor onChange={onNoteEditorChange} editorStateJson={initialState} />
      </div>
      <div className={`card-footer ${isDirty ? 'd-block' : 'd-none'}`}>
        <div className="d-flex justify-content-end gap-3 mt-4">
          <button type="button" className="btn btn-white" onClick={() => onCancel()}>
            Annuleer
          </button>
          <button type="submit" className="btn btn-primary">
            {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Bewaar'}
          </button>
          {entity && showDelete && (
            <button
              type="button"
              className="btn btn-danger ms-0"
              onClick={() => {
                // eslint-disable-next-line no-alert
                if (window.confirm('Ben je zeker dat je wil verwijderen?')) onDelete();
              }}
            >
              {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Verwijder'}
            </button>
          )}
        </div>
      </div>
    </form>
  );
}
export default EntityNote;
