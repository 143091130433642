import entityService from './base/entity.service';

const userService = () => {
  const baseService = entityService('users');

  const that = {};

  that.getAll = (page, pageSize, sortField, sortOrder, term) => baseService.getAll(page, pageSize, sortField, sortOrder, term);

  that.getSingle = baseService.getSingle;

  that.getProfile = async () => {
    const response = await baseService.axios.get(`${baseService.apiUrl}${baseService.path}/profile`);
    return response.data;
  };

  that.updateProfileImage = async (img, userId) => {
    const formData = new FormData();
    formData.append('profileImage', img);
    formData.append('userId', userId);
    const response = await baseService.axios.post(`${baseService.apiUrl}${baseService.path}/uploadProfileImage`, formData);
    return response.status === 200 ? response.data : undefined;
  };

  that.changeEmailAddress = async (userId, email) => {
    const response = await baseService.axios.put(`${baseService.apiUrl}${baseService.path}/${userId}/changeEmailAddress`, { email });
    return response;
  };

  that.confirmEmailAddress = async (userId, tokenId) => {
    const response = await baseService.axios.put(`${baseService.apiUrl}${baseService.path}/${userId}/confirmEmailAddress`, { tokenId });
    return response.status === 200 ? response.data : undefined;
  };

  that.changePassword = async (userId, currentPassword, newPassword) => {
    const response = await baseService.axios.put(`${baseService.apiUrl}${baseService.path}/${userId}/changePassword`, { currentPassword, newPassword });
    return response;
  };

  that.setPassword = async (userId, tokenId, password) => {
    const response = await baseService.axios.put(`${baseService.apiUrl}${baseService.path}/${userId}/setPassword`, { tokenId, password });
    return response.status === 200 ? response.data : undefined;
  };

  that.getProfileImage = async (userId) => {
    const response = await baseService.axios.get(`${baseService.apiUrl}${baseService.path}/${userId}/getProfileImage`);
    return response.data;
  };

  that.update = baseService.update;
  that.create = baseService.create;
  that.delete = baseService.delete;

  return that;
};

export default userService;
