import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import EntityNote from '../Common/EntityNote';
import studentService from '../../services/student.service';
import ErrorToast from '../Common/ErrorToast';
import Timeline from '../Common/Timeline';
import InternshipWidget from '../Internships/InternshipWidget';
import EditStudentModal from './EditStudentModal';
import MailModal from '../Common/MailModal';
import dateFormat from '../../helpers/date-format';
import mailTemplateTypeEnum from '../../enum/mail-template-type-enum';
import mailTemplateService from '../../services/mail-template.service';
import EditInternshipModal from '../Internships/EditInternshipModal';

function Student() {
  const studentId = useParams().id;
  const [errorToastVisible, setErrorToastVisible] = useState(false);
  const [student, setStudent] = useState(null);
  const [tlRefresh, setTlRefresh] = useState();
  const [mailTemplate, setMailTemplate] = useState();
  const [editStudentModalVisible, setEditStudentModalVisible] = useState(false);
  const [editInternshipModalVisible, setEditInternshipModalVisible] = useState(false);
  const [mailModalVisible, setMailModalVisible] = useState(false);

  const fetchMailTemplate = async () => {
    const response = await mailTemplateService().getAll({ mailTemplateTypeId: mailTemplateTypeEnum.STUDENT_INFO_REQUEST });
    setMailTemplate(response.data?.rows[0]?.message);
  };

  const handleEditStudentModalShow = () => {
    setEditStudentModalVisible(true);
  };

  const closeErrorToast = () => {
    setErrorToastVisible(false);
  };

  const showErrorToast = () => {
    setErrorToastVisible(true);
  };

  const fetchStudent = async () => {
    try {
      const response = await studentService().getSingle(studentId);
      setStudent(response);
    } catch (error) {
      showErrorToast();
    }
  };

  const handleMailModalClose = async (data) => {
    if (data && data.message) {
      await studentService().requestInfo([studentId], data.message);
      setMailModalVisible(true);
      await fetchStudent();
      setTlRefresh(new Date());
    }

    setMailModalVisible(false);
  };

  const handleEditInternshipModalClose = async (modified) => {
    if (modified) {
      await fetchStudent();
    }
    setEditInternshipModalVisible(false);
  };

  const handleEditInternshipModalShow = () => {
    setEditInternshipModalVisible(true);
  };

  const handleEditStudentModalClose = async (modified) => {
    if (modified) {
      await fetchStudent();
    }
    setEditStudentModalVisible(false);
  };

  useEffect(() => {}, [mailTemplate, tlRefresh]);
  useEffect(() => {
    fetchStudent();
    fetchMailTemplate();
  }, []);
  return (
    <>
      <EditStudentModal
        isVisible={editStudentModalVisible}
        studentId={studentId}
        handleClose={(e) => {
          handleEditStudentModalClose(e);
        }}
      />
      <EditInternshipModal
        isVisible={editInternshipModalVisible}
        studentId={studentId}
        handleClose={(e) => {
          handleEditInternshipModalClose(e);
        }}
      />
      <MailModal
        isVisible={mailModalVisible}
        message={mailTemplate}
        contactService={studentService}
        selectedIds={[studentId]}
        handleClose={handleMailModalClose}
        modalTitle="Voorkeur bevraging versturen"
      />
      <div className="page-header">
        <ErrorToast handleClose={() => closeErrorToast()} isVisible={errorToastVisible} error="Data kon niet geladen worden. Mogelijk geen internet connectie!" />
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-no-gutter">
                <li className="breadcrumb-item">
                  <a className="breadcrumb-link" href="/students">
                    Studenten
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Details
                </li>
              </ol>
            </nav>
            <div className="d-flex gap-2">
              <div>
                <h1 className="page-header-title d-inline">{student?.fullName || <Skeleton count={1} />}</h1>
                <div className="mt-3">
                  {student?.level && (
                    <span className="badge bg-soft-primary text-primary rounded-pill me-2">
                      <i className="bi-award me-1" />
                      {student?.internshipType.name}
                    </span>
                  )}
                </div>
              </div>
              <button type="button" className="btn btn-white btn-icon btn-xs rounded-circle" aria-expanded="false" onClick={handleEditStudentModalShow}>
                <i className="bi-pencil-fill" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Algemeen</h4>
            </div>
            <div className="card-body">
              {student != null ? (
                <ul className="list-group list-group-flush list-group-no-gutters">
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Contact info</h5>
                      <button type="button" className="btn btn-link" onClick={handleEditStudentModalShow}>
                        Aanpassen
                      </button>
                    </div>

                    <ul className="list-unstyled list-py-1 text-body">
                      <li>
                        <i className="bi-at me-1" />
                        {student.email}
                      </li>
                      <li>
                        <i className="bi-phone me-1" />
                        {student.phone}
                      </li>
                    </ul>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Stage type</h5>
                      <button type="button" className="btn btn-link" onClick={handleEditStudentModalShow}>
                        Aanpassen
                      </button>
                    </div>

                    <ul className="list-unstyled list-py-1 text-body">
                      <li>
                        <i className="bi-calendar-check me-1" />
                        {student.internshipType?.name}
                      </li>
                      <li>
                        <i className="bi-calendar me-1" />
                        {dateFormat(student.internshipType.start, false)} - {dateFormat(student.internshipType.end, false)}
                      </li>
                    </ul>
                  </li>
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Voorkeuren</h5>
                      <div>
                        <button type="button" className="btn btn-link" onClick={handleEditStudentModalShow}>
                          Aanpassen
                        </button>
                        <button type="button" className="btn btn-link" onClick={() => setMailModalVisible(true)}>
                          Voorkeur opvragen
                        </button>
                      </div>
                    </div>
                    <ul className="list-unstyled list-py-1 text-body">
                      <li>
                        <i className="bi-geo-alt me-1" />
                        1: {student.preferredRegion1?.name}
                      </li>
                      <li>
                        <i className="bi-geo-alt me-1" />
                        2: {student.preferredRegion2?.name}
                      </li>
                    </ul>
                  </li>
                </ul>
              ) : (
                <Skeleton count={3} />
              )}
            </div>
          </div>
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Notities</h4>
            </div>
            <EntityNote entity={student} entityService={studentService} />
          </div>
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Stages</h4>
              <button type="button" className="btn btn-ghost-secondary" onClick={handleEditInternshipModalShow}>
                <i className="bi-plus" /> Nieuwe stage
              </button>
            </div>
            {student ? <InternshipWidget filters={{ studentId }} /> : <Skeleton count={5} />}
          </div>
        </div>
        <div className="col-lg-6">
          <Timeline studentId={studentId} key={JSON.stringify(tlRefresh)} />
        </div>
      </div>
    </>
  );
}

export default Student;
