import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PulseLoader from 'react-spinners/PulseLoader';
import userService from '../../services/user.service';

function ChangeEmail({ user, onError }) {
  const [isBusy, setIsBusy] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [submitSucces, setSubmitSuccess] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);
  const {
    register,
    handleSubmit,
    trigger,
    formState: { isValid, errors }
  } = useForm();

  const onSubmit = async (data) => {
    setEmailInUse(false);
    setSubmitSuccess(false);
    await trigger();
    if (isValid) {
      try {
        setIsBusy(true);
        await userService().changeEmailAddress(user.id, data.emailAddress);
        setSubmitSuccess(true);
      } catch (error) {
        if (error.response?.data?.error === 'EMAIL_IN_USE') {
          setEmailInUse(true);
        }

        onError();
      }

      setIsBusy(false);
    }
  };

  useEffect(() => {}, [user]);
  return (
    <div className="row">
      <div className="col-lg">
        <div className="card mb-3 mb-lg-5">
          <div className="card-header card-header-content-between">
            <h4 className="card-title">E-mail adres wijzigen</h4>
          </div>
          <div className="card-body">
            {user != null && !submitSucces && (
              <div>
                Het huidige e-mail adres is
                <span className="fw-semibold ms-1">
                  {user?.email}
                  <button
                    type="button"
                    className="btn btn-white btn-icon btn-xs rounded-circle ms-1"
                    aria-expanded="false"
                    onClick={() => {
                      setIsEditing(!isEditing);
                    }}
                  >
                    <i className="bi-pencil-fill" />
                  </button>
                </span>
                {isEditing && (
                  <form key={2} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row pt-4">
                      <div className="col-4 col-lg-3 col-sm-5">
                        <label htmlFor="emailAddress" className="form-label">
                          Nieuw e-mail adres
                        </label>
                      </div>
                      <div className="col-8 col-sm-7 col-lg-6">
                        <input
                          {...register('emailAddress', {
                            required: 'Vul het e-mail adres in!',
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: 'Vul een geldig e-mail adres in!'
                            }
                          })}
                          name="emailAddress"
                          type="text"
                          className="form-control"
                          autoComplete="false"
                          placeholder="vul hier het nieuwe e-mail adres in."
                        />
                      </div>
                    </div>
                    <div className="row">
                      <span className="invalid-feedback ms-1" style={{ display: errors['emailAddress'] ? 'block' : 'none' }}>
                        Gelieve het e-mail adres op te geven.
                      </span>
                    </div>

                    <div className="row">
                      <span className="invalid-feedback ms-1" style={{ display: emailInUse ? 'block' : 'none' }}>
                        Het opgegeven e-mail adres is reeds in gebruik. Gelieve een ander e-mail adres op te geven.
                      </span>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button type="submit" className="btn btn-primary">
                        {isBusy ? <PulseLoader className="loader" color="#fff" size="5" /> : 'Bewaren'}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            )}
            {submitSucces && <p>We hebben een email ter bevestiging verstuurd. Gelieve uw inbox na te kijken!</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeEmail;
