import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import CountUp from 'react-countup';
import Skeleton from 'react-loading-skeleton';
import CircularProgressBar from '../Common/CircularProgressBar';
import internshipTypeService from '../../services/internship-type.service';
import ErrorToast from '../Common/ErrorToast';
import EntityNote from '../Common/EntityNote';
import Timeline from '../Common/Timeline';
import InternshipWidget from '../Internships/InternshipWidget';
import EditInternshipModal from '../Internships/EditInternshipModal';
import EditInternshipTypeModal from './EditInternshipTypeModal';
import dateFormat from '../../helpers/date-format';
import internshipStatusEnum from '../../enum/internship-status-enum';

function InternshipType() {
  const params = useParams();
  const [errorToastVisible, setErrorToastVisible] = useState(false);
  const [internshipType, setInternshipType] = useState(null);
  const internshipTypeId = params.id;
  const [editInternshipTypeModalVisible, setEditInternshipTypeModalVisible] = useState(false);
  const [editInternshipModalVisible, setEditInternshipModalVisible] = useState(false);
  const [percInRequest, setPercInRequest] = useState(0.0);
  const [percConfirmed, setPercConfirmed] = useState(0);
  const [percDenied, setPercDenied] = useState(0);
  const [total, setTotal] = useState(0);

  const calculateFeedback = (internshipType) => {
    let total = 0;

    total += _.sumBy(internshipType.countByStatus, (item) => _.parseInt(item.count));

    const request = parseInt(internshipType.countByStatus.filter((f) => f.internshipStatusId === internshipStatusEnum.REQUESTED)[0]?.count, 10) || 0;
    const confirmed = parseInt(internshipType.countByStatus.filter((f) => f.internshipStatusId === internshipStatusEnum.CONFIRMED)[0]?.count, 10) || 0;
    const denied = parseInt(internshipType.countByStatus.filter((f) => f.internshipStatusId === internshipStatusEnum.DECLINED)[0]?.count, 10) || 0;

    setPercInRequest((request * 100) / total || 0);
    setPercConfirmed((confirmed * 100) / total || 0);
    setPercDenied((denied * 100) / total || 0);
    setTotal(total || 0);
  };

  const showErrorToast = () => {
    setErrorToastVisible(true);
  };

  const fetchInternshipType = async () => {
    try {
      const response = await internshipTypeService().getSingle(internshipTypeId);
      setInternshipType(response);
      calculateFeedback(response);
    } catch (error) {
      showErrorToast();
    }
  };

  const handleEditInternshipTypeModalShow = () => {
    setEditInternshipTypeModalVisible(true);
  };

  const handleEditInternshipTypeModalClose = async (modified) => {
    if (modified) {
      await fetchInternshipType();
    }
    setEditInternshipTypeModalVisible(false);
  };

  const handleEditInternshipModalClose = async (modified) => {
    if (modified) {
      await fetchInternshipType();
    }
    setEditInternshipModalVisible(false);
  };

  const handleEditInternshipModalShow = () => {
    setEditInternshipModalVisible(true);
  };

  const closeErrorToast = () => {
    setErrorToastVisible(false);
  };

  useEffect(() => {
    fetchInternshipType();
  }, []);
  return (
    <>
      <EditInternshipTypeModal
        internshipTypeId={internshipTypeId}
        isVisible={editInternshipTypeModalVisible}
        handleClose={(modified) => {
          handleEditInternshipTypeModalClose(modified);
        }}
      />
      <EditInternshipModal
        isVisible={editInternshipModalVisible}
        internshipTypeId={internshipTypeId}
        handleClose={(e) => {
          handleEditInternshipModalClose(e);
        }}
      />
      <div className="page-header">
        <ErrorToast handleClose={() => closeErrorToast()} isVisible={errorToastVisible} error="Data kon niet geladen worden. Mogelijk geen internet connectie!" />
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-no-gutter">
                <li className="breadcrumb-item">
                  <a className="breadcrumb-link" href="/internshipTypes">
                    Stagetypes
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Details
                </li>
              </ol>
            </nav>
            <div className="d-flex gap-2">
              <div>
                <h1 className="page-header-title d-inline">{internshipType?.name || <Skeleton count={1} />}</h1>
              </div>
              <button type="button" className="btn btn-white btn-icon btn-xs rounded-circle" aria-expanded="false" onClick={handleEditInternshipTypeModalShow}>
                <i className="bi-pencil-fill" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Algemeen</h4>
            </div>
            <div className="card-body">
              {internshipType != null ? (
                <div>
                  <ul className="list-group list-group-flush list-group-no-gutters">
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>Stagetype info</h5>
                        <button type="button" className="btn btn-link" onClick={handleEditInternshipTypeModalShow}>
                          Aanpassen
                        </button>
                      </div>

                      <ul className="list-unstyled list-py-1 text-body">
                        <li>
                          <span>
                            <i className="bi-calendar me-2" />
                            Start op {dateFormat(internshipType.start, false)}
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="bi-calendar me-2" />
                            Einde op {dateFormat(internshipType.end, false)}
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="bi-mortarboard  me-2" />
                            <CountUp end={total} duration={1} suffix=" " separator="." />
                            inschrijvingen
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              ) : (
                <Skeleton count={3} />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Status</h4>
            </div>
            <div className="card-body pt-6 px-4">
              {internshipType != null ? (
                <div>
                  <div className="row">
                    <div className="col-4 mb-3 mb-xl-6 d-flex justify-content-center text-center flex-column">
                      <h4 className="mb-3">
                        <span
                          className="legend-indicator"
                          style={{
                            backgroundColor: '#377dff'
                          }}
                        />
                        In aanvraag
                      </h4>
                      <CircularProgressBar percentage={percInRequest} />
                    </div>
                    <div className="col-4 mb-3 mb-xl-6 d-flex justify-content-center text-center flex-column">
                      <h4 className="mb-3">
                        <span
                          className="legend-indicator"
                          style={{
                            backgroundColor: 'rgb(0, 201, 167)'
                          }}
                        />
                        Bevestigd
                      </h4>
                      <CircularProgressBar percentage={percConfirmed} />
                    </div>

                    <div className="col-4 mb-3 mb-xl-6 d-flex justify-content-center text-center flex-column">
                      <h4 className="mb-3">
                        <span
                          className="legend-indicator"
                          style={{
                            backgroundColor: 'rgb(237, 76, 120)'
                          }}
                        />
                        Geweigerd
                      </h4>
                      <CircularProgressBar percentage={percDenied} />
                    </div>
                  </div>
                </div>
              ) : (
                <Skeleton count={3} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Stages</h4>
              <button type="button" className="btn btn-ghost-secondary" onClick={handleEditInternshipModalShow}>
                <i className="bi-plus" /> Nieuwe stage
              </button>
            </div>
            {internshipType ? <InternshipWidget filters={{ internshipTypeId }} /> : <Skeleton count={5} />}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Notities</h4>
            </div>
            <EntityNote entity={internshipType} entityService={internshipTypeService} />
          </div>
        </div>
        <div className="col-lg-6">
          <Timeline internshipTypeId={internshipTypeId} />
        </div>
      </div>
    </>
  );
}

export default InternshipType;
