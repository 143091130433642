import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import PulseLoader from 'react-spinners/PulseLoader';
import userRoleService from '../../services/user-role.service';
import FormSelect from '../Common/FormSelect';
import userService from '../../services/user.service';

function EditUserModal({ isVisible, handleClose }) {
  const {
    register,
    handleSubmit,
    trigger,
    control,
    formState: { isValid, errors }
  } = useForm();

  const [emailInUse, setEmailInUse] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const onSubmit = async (data) => {
    await trigger();
    if (isValid) {
      try {
        setIsBusy(true);
        const result = await userService().create(data);
        if (result !== undefined) {
          handleClose();
        }
      } catch (error) {
        if (error.response?.data?.error === 'EMAIL_IN_USE') {
          setEmailInUse(true);
        }
      }

      setIsBusy(false);
    }
  };

  useEffect(() => {}, [isVisible, isBusy]);
  return (
    <Modal show={isVisible} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Nieuwe gebruiker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label">
                  Voornaam
                </label>
                <input type="text" className="form-control" name="firstName" autoComplete="false" placeholder="vul hier de voornaam in." {...register('firstName')} />
                <span className="invalid-feedback ms-1" style={{ display: errors.firstName ? 'block' : 'none' }}>
                  Gelieve de voornaam op te geven
                </span>
              </div>
              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">
                  Achternaam
                </label>
                <input type="text" className="form-control" name="lastName" autoComplete="false" placeholder="vul hier de achternaam in." {...register('lastName')} />
                <span className="invalid-feedback ms-1" style={{ display: errors.lastName ? 'block' : 'none' }}>
                  Gelieve de achternaam op te geven
                </span>
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  E-mail
                </label>
                <input
                  type="e-mail"
                  className="form-control"
                  name="email"
                  autoComplete="false"
                  placeholder="vul hier het e-mail adres in."
                  {...register('email', {
                    required: 'Vul het e-mail adres in!',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Vul een geldig e-mail adres in!'
                    }
                  })}
                />
                <span className="invalid-feedback ms-1" style={{ display: errors.email ? 'block' : 'none' }}>
                  Gelieve het e-mail adres op te geven
                </span>
                <span className="invalid-feedback ms-1" style={{ display: emailInUse ? 'block' : 'none' }}>
                  Het opgegeven e-mail adres is reeds in gebruik. Gelieve een ander e-mail adres op te geven.
                </span>
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <label htmlFor="role" className="form-label">
                  Rol
                </label>
                <FormSelect name="userRoleId" required loadService={userRoleService} control={control} iconClassName="bi-person" />
                <span className="invalid-feedback ms-1" style={{ display: errors.email ? 'block' : 'none' }}>
                  Gelieve het e-mail adres op te geven
                </span>
              </div>
            </div>
            <div className="row">
              <span className="invalid-feedback ms-1" style={{ display: errors['userRoleId'] ? 'block' : 'none' }}>
                Gelieve een rol te selecteren.
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary">
            {isBusy ? <PulseLoader className="loader" color="#fff" size="5" /> : 'Gebruiker aanmaken'}
          </button>
          <button type="button" className="btn btn-white" onClick={handleClose}>
            Sluiten
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default EditUserModal;
