import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './sidebar.scss';
import Logo from '../../assets/img/logo.png';

function Sidebar({ setHeaderMargin, darkMode, setDarkMode }) {
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [menuWidth, setMenuWidth] = useState('13rem');
  const menuMiniWidth = '6rem';
  const menuMaxWidth = '13rem';

  useEffect(() => {
    if (menuCollapsed) {
      setMenuWidth(menuMiniWidth);
      setHeaderMargin(menuMiniWidth);
    } else {
      setMenuWidth(menuMaxWidth);
      setHeaderMargin(menuMaxWidth);
    }
  }, [menuCollapsed, setHeaderMargin, darkMode]);
  return (
    <div
      className="navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white navbar-vertical-aside-initialized"
      style={{
        width: menuWidth,
        transition: 'width 0.15s ease-out'
      }}
    >
      <button
        type="button"
        onClick={() => setMenuCollapsed(!menuCollapsed)}
        className="navbar-aside-toggler"
        style={{
          opacity: 1
        }}
      >
        <i
          className="bi-arrow-bar-left navbar-toggler-short-align"
          style={{
            display: menuCollapsed !== false ? 'none' : 'block'
          }}
        />
        <i
          className="bi-arrow-bar-right navbar-toggler-full-align"
          style={{
            display: menuCollapsed !== true ? 'none' : 'block'
          }}
        />
      </button>
      <div className="navbar-vertical-container">
        <div className="navbar-vertical-footer-offset">
          <div className="navbar-brand">
            <img src={Logo} className="logo-xs" alt="fg-logo-small" />
          </div>
          <div className="navbar-vertical-content">
            <div className="nav nav-pills nav-vertical card-navbar-nav">
              <div className="nav-item">
                <Link to="/dashboard" className="nav-link link-dark">
                  <i className="bi-house-door nav-icon" width="16" height="16" />
                  <span
                    className="nav-link-title"
                    style={{
                      display: menuCollapsed !== true ? 'block' : 'none'
                    }}
                  >
                    Dashboard
                  </span>
                </Link>
              </div>
              {/*    <div className="nav-item">
                <Link to="/events/inbox" className="nav-link link-dark">
                  <i className="bi-message nav-icon" width="16" height="16" />
                  <span
                    className="nav-link-title"
                    style={{
                      display: menuCollapsed !== true ? 'block' : 'none'
                    }}
                  >
                    Inbox
                  </span>
                </Link>
              </div> */}
              <div className="nav-item">
                <Link to="/students/list" className="nav-link link-dark">
                  <i className="bi-mortarboard nav-icon" width="16" height="16" />
                  <span
                    className="nav-link-title"
                    style={{
                      display: menuCollapsed !== true ? 'block' : 'none'
                    }}
                  >
                    Studenten
                  </span>
                </Link>
              </div>
              <div className="nav-item">
                <Link to="/internships/list" className="nav-link link-dark">
                  <i className="bi-calendar-check nav-icon" width="16" height="16" />
                  <span
                    className="nav-link-title"
                    style={{
                      display: menuCollapsed !== true ? 'block' : 'none'
                    }}
                  >
                    Stages
                  </span>
                </Link>
              </div>
              <div className="nav-item">
                <Link to="/internshiptypes/list" className="nav-link link-dark">
                  <i className="bi bi-calendar-heart nav-icon" width="16" height="16" />
                  <span
                    className="nav-link-title"
                    style={{
                      display: menuCollapsed !== true ? 'block' : 'none'
                    }}
                  >
                    Stagetypes
                  </span>
                </Link>
              </div>
              <div className="nav-item">
                <Link to="/contacts/list" className="nav-link link-dark">
                  <i className="bi-people nav-icon" width="16" height="16" />
                  <span
                    className="nav-link-title"
                    style={{
                      display: menuCollapsed !== true ? 'block' : 'none'
                    }}
                  >
                    Contacten
                  </span>
                </Link>
              </div>
              <div className="nav-item">
                <Link to="/offices/list" className="nav-link link-dark">
                  <i className="bi-hospital nav-icon" width="16" height="16" />
                  <span
                    className="nav-link-title"
                    style={{
                      display: menuCollapsed !== true ? 'block' : 'none'
                    }}
                  >
                    Praktijken
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="navbar-vertical-footer"
            style={{
              display: menuCollapsed !== false ? 'none' : 'block'
            }}
          >
            <ul className="navbar-vertical-footer-list">
              <li className="navbar-vertical-footer-list-item">
                <div className="dropdown dropup">
                  <button type="button" className="btn btn-ghost-secondary btn-icon rounded-circle" onClick={() => setDarkMode()}>
                    <i className={`${darkMode ? 'bi-brightness-high' : 'bi-moon'}`} />
                  </button>
                </div>
              </li>
              <li className="navbar-vertical-footer-list-item">
                <div className="dropdown dropup">
                  <button type="button" className="btn btn-ghost-secondary btn-icon rounded-circle">
                    <i className="bi-info-circle" />
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Sidebar;
