/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import internshipService from '../../services/internship.service';
import internshipStatusEnum from '../../enum/internship-status-enum';
import ErrorToast from '../Common/ErrorToast';
import HeaderSvg from '../../assets/svg/components/card-6.svg';
import EmailSvg from '../../assets/svg/oc-email-verification.svg';
import dateFormat from '../../helpers/date-format';
import Logo from '../../assets/img/logo.png';

function InternshipSubscriptions() {
  const {
    handleSubmit,

    trigger,
    register,
    formState: { isValid, errors }
  } = useForm();

  const { token, id } = useParams();
  const [errorToastVisible, setErrorToastVisible] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [internshipTypes, setInternshipTypes] = useState([]);
  const [approved, setApproved] = useState(true);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleInternshipTypeCheck = (id, isChecked) => setInternshipTypes(internshipTypes.map((internshipType) => (internshipType.id === id ? { ...internshipType, isChecked } : internshipType)));

  const closeErrorToast = () => {
    setErrorToastVisible(false);
  };

  const showErrorToast = () => {
    setErrorToastVisible(true);
  };

  const fetchInternshipTypes = async () => {
    try {
      const response = await internshipService().getSubscriptionRequest(token, id);
      setInternshipTypes(response);
    } catch (error) {
      showErrorToast();
    }
  };

  const onFbChanged = (value) => {
    setApproved(value);
  };

  const onSubmit = async () => {
    setIsBusy(true);
    if (token) {
      const checkedIds = internshipTypes.filter((internshipType) => internshipType.isChecked).map((internshipType) => internshipType.id);

      await internshipService().processSubscriptions(token, id, checkedIds);
      setSubmitSuccess(true);
    }

    setIsBusy(false);
  };

  useEffect(() => {
    fetchInternshipTypes();
  }, []);
  useEffect(() => {}, [internshipTypes]);
  return (
    <div className="bg-colored d-flex justify-content-center">
      <ErrorToast handleClose={() => closeErrorToast()} isVisible={errorToastVisible} error="Data kon niet geladen worden. Mogelijk geen internet connectie!" />
      <div className="position-absolute top-0 start-0 end-0 mt-3 mx-3">
        <div className="d-none d-lg-flex justify-content-between">
          <img src={Logo} style={{ width: '4rem', height: '4rem', zIndex: '2' }} alt="fg-logo" />
        </div>
      </div>
      <div>
        <div className="position-fixed top-0 end-0 start-0 bg-img-start" style={{ height: '32rem', backgroundImage: `url(${HeaderSvg})` }} alt="">
          <div className="shape shape-bottom zi-1">
            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
              <polygon fill="#7575CB" points="0,273 1921,273 1921,0 " />
            </svg>
          </div>
        </div>
      </div>

      <div className="py-10">
        <div className="card card-lg mt-10 p-5">
          {internshipTypes.length === 0 && (
            <div className="card-body p-6">
              <img src={EmailSvg} alt="todo" style={{ width: '20rem' }} className="m-auto d-block" />
              <h1 className="display-5 mt-5 mb-3">Uitnodiging verlopen..</h1>
              <p style={{ width: '30rem' }}>
                Oeps.. deze uitnodiging is ondertussen verlopen. <br />
                Je hoeft dus niets te ondernemen. <br />
                Indien nodig versturen we een nieuwe uitnodiging!
              </p>
            </div>
          )}
          {!submitSuccess && internshipTypes.length > 0 && (
            <div className="card-body">
              <img src={EmailSvg} alt="todo" style={{ width: '20rem' }} className="m-auto d-block" />
              <h1 className="display-5 mt-5 mb-3">Inschrijving stageplaatsen</h1>
              <p style={{ width: '30rem' }}>
                Hieronder kan u de stagedata terugvinden voor de komende periode. Gelieve uw mogelijkheden door te geven. Geef ons gerust een seintje bij vragen en/of opmerkingen!
              </p>
              <div className="row">
                <ul className="list-group">
                  {internshipTypes?.map((internshipType) => (
                    <li className="list-group-item" key={internshipType.id}>
                      <div className="row">
                        <div className="col">
                          <h5>{internshipType.name}</h5>
                          <div className="text-body">
                            <i className="bi-calendar me-2" />
                            Start op {dateFormat(internshipType.start, false)}
                          </div>
                          <div className="text-body">
                            <i className="bi-calendar me-2" />
                            Einde op {dateFormat(internshipType.end, false)}
                          </div>
                        </div>
                        <div className="col-auto">
                          <button
                            type="button"
                            className={internshipType.isChecked ? 'btn btn-success btn-sm' : 'btn btn-primary btn-sm'}
                            onClick={() => handleInternshipTypeCheck(internshipType.id, !internshipType.isChecked)}
                          >
                            {internshipType.isChecked ? 'Deelname' : 'Inschrijven'}
                            <i className="bi-check me-1" />
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {submitSuccess && (
            <div className="card-body p-6">
              <img src={EmailSvg} alt="todo" style={{ width: '20rem' }} className="m-auto d-block" />
              <h1 className="display-5 mt-5 mb-3">Feedback verstuurd</h1>
              <p style={{ width: '30rem' }}>Gelukt! Bedankt voor uw feedback!</p>
            </div>
          )}
          {!submitSuccess && internshipTypes.length > 0 && (
            <div className="card-footer">
              <button type="button" className="btn btn-primary ms-auto me-0 d-block" onClick={onSubmit}>
                <i className="bi-send-check me-2" />
                {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Feedback versturen'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InternshipSubscriptions;
