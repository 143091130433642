import React, { useState, useEffect } from 'react';
import TableList from '../Common/TableList';
import EditOfficeModal from './EditOfficeModal';
import BulkPortalMailModal from './BulkPortalMailModal';
import BulkMailModel from '../Common/BulkMailModal';
import officeService from '../../services/office.service';
import filterControlTypeEnum from '../../enum/filter-control-type-enum';
import mailTemplateTypeEnum from '../../enum/mail-template-type-enum';
import mailTemplateService from '../../services/mail-template.service';

function OfficeList() {
  const [editOfficeModalVisible, setEditOfficeModalVisible] = useState(false);
  const [mailTemplate, setMailTemplate] = useState();
  const [bulkPortalMailModalVisible, setBulkPortalMailModalVisible] = useState(false);
  const [bulkMailModalVisible, setBulkMailModalVisible] = useState(false);
  const [selectedOffices, setSelectedOffices] = useState([]);

  const handleEditOfficeModalShow = () => {
    setEditOfficeModalVisible(true);
  };

  const handleEditOfficeModalClose = () => {
    setEditOfficeModalVisible(false);
  };

  const fetchMailTemplate = async () => {
    const response = await mailTemplateService().getAll({ mailTemplateTypeId: mailTemplateTypeEnum.OFFICE_PORTAL });
    setMailTemplate(response.data?.rows[0]?.message);
  };

  const handleBulkPortalMailModalClose = async (data) => {
    if (data && data.message) {
      await officeService().sendPortalLink(selectedOffices, data.message);
    }

    setBulkPortalMailModalVisible(false);
  };

  const handleBulkRequestPortalLink = (selected) => {
    setSelectedOffices(selected);
    setBulkPortalMailModalVisible(true);
  };

  const handleBulkMail = (selected) => {
    setSelectedOffices(selected);
    setBulkMailModalVisible(true);
  };

  const handleBulkMailModalClose = async () => {
    setBulkMailModalVisible(false);
  };

  const actionButtons = [
    {
      description: 'Portaal link versturen',
      iconClassName: 'bi-envelope me-2',
      action: handleBulkRequestPortalLink
    },
    {
      description: 'Mail versturen',
      iconClassName: 'bi-envelope me-2',
      action: handleBulkMail
    }
  ];

  const filterControls = [
    {
      filterControlType: filterControlTypeEnum.OFFICE_REGION,
      fieldName: 'regionId',
      placeholder: 'Regio'
    },
    {
      filterControlType: filterControlTypeEnum.BOOL,
      fieldName: 'shortInternships',
      placeholder: 'Korte stages'
    },
    {
      filterControlType: filterControlTypeEnum.BOOL,
      fieldName: 'longInternships',
      placeholder: 'Lange stages'
    }
  ];

  const cols = [
    {
      fieldName: 'id',
      description: 'Nr',
      sortable: true
    },
    {
      fieldName: 'name',
      description: 'Naam',
      sortable: true,
      link: { type: 'office', fieldName: 'id' }
    },
    {
      fieldName: 'type',
      description: 'Type',
      sortable: true
    },
    {
      fieldName: 'phone',
      description: 'Telefoon',
      sortable: false
    },
    {
      fieldName: 'email',
      description: 'E-mail',
      sortable: false
    },
    {
      fieldName: 'shortInternships',
      description: 'Korte stages',
      type: 'bool',
      sortable: true
    },
    {
      fieldName: 'longInternships',
      description: 'Lange stages',
      type: 'bool',
      sortable: true
    },
    {
      fieldName: '',
      description: '',
      editBtn: { type: 'office', fieldName: 'id', description: 'bekijk' }
    }
  ];

  useEffect(() => {}, [selectedOffices]);
  useEffect(() => {
    fetchMailTemplate();
  }, [editOfficeModalVisible]);
  return (
    <>
      <EditOfficeModal
        isVisible={editOfficeModalVisible}
        handleClose={() => {
          handleEditOfficeModalClose();
        }}
      />
      <BulkPortalMailModal
        isVisible={bulkPortalMailModalVisible}
        selectedOffices={`${selectedOffices.length} ontvanger(s)`}
        message={mailTemplate}
        handleClose={handleBulkPortalMailModalClose}
        modalTitle="Uitnodiging portaal versturen"
      />
      <BulkMailModel isVisible={bulkMailModalVisible} selectedIds={selectedOffices} handleClose={handleBulkMailModalClose} modalTitle="Mail versturen" entityService={officeService} />
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-no-gutter">
                <li className="breadcrumb-item">
                  <a className="breadcrumb-link" href="/stages">
                    Praktijken
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Lijst
                </li>
              </ol>
            </nav>

            <h1 className="page-header-title">Praktijken</h1>
          </div>
          <div className="col-sm-auto">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleEditOfficeModalShow();
              }}
            >
              <i className="bi-plus me-1" />
              Nieuwe praktijk
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <TableList
          entityType="praktijken"
          columnDefinition={cols}
          deleteItems={officeService().delete}
          getItems={officeService().getAll}
          exportItems={officeService().export}
          archiveItems={officeService().archive}
          unArchiveItems={officeService().unArchive}
          filterControls={filterControls}
          actionButtons={actionButtons}
        />
      </div>
    </>
  );
}

export default OfficeList;
