import React, { useState, useEffect } from 'react';
import TableList from '../Common/TableList';
import EditStudentModal from './EditStudentModal';
import studentService from '../../services/student.service';
import filterControlTypeEnum from '../../enum/filter-control-type-enum';
import BulkMailModel from '../Common/BulkMailModal';
import MailModal from '../Common/MailModal';
import mailTemplateTypeEnum from '../../enum/mail-template-type-enum';
import mailTemplateService from '../../services/mail-template.service';

function StudentList() {
  const [editStudentModalVisible, setEditStudentModalVisible] = useState(false);
  const [mailTemplate, setMailTemplate] = useState();
  const [mailModalVisible, setMailModalVisible] = useState(false);
  const [bulkMailModalVisible, setBulkMailModalVisible] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);

  const handleEditStudentModalShow = () => {
    setEditStudentModalVisible(true);
  };

  const handleEditStudentModalClose = () => {
    setEditStudentModalVisible(false);
  };

  const fetchMailTemplate = async () => {
    const response = await mailTemplateService().getAll({ mailTemplateTypeId: mailTemplateTypeEnum.STUDENT_INFO_REQUEST });
    setMailTemplate(response.data?.rows[0]?.message);
  };

  const handleMailModalClose = async (data) => {
    if (data && data.message) {
      await studentService().requestInfo(selectedStudents, data.message, '');
    }

    setMailModalVisible(false);
  };

  const handleBulkAskPreferences = (selected) => {
    setSelectedStudents(selected);
    setMailModalVisible(true);
  };

  const handleBulkMail = (selected) => {
    setSelectedStudents(selected);
    setBulkMailModalVisible(true);
  };

  const handleBulkMailModalClose = async () => {
    setBulkMailModalVisible(false);
  };

  const filterControls = [
    {
      filterControlType: filterControlTypeEnum.INTERNSHIP_TYPE,
      fieldName: 'internshipTypeId',
      placeholder: 'Stagetype'
    },
    {
      filterControlType: filterControlTypeEnum.BOOL,
      fieldName: 'hasInternships',
      placeholder: 'Geplande stage'
    }
  ];

  const actionButtons = [
    {
      description: 'Voorkeuren opvragen',
      iconClassName: 'bi-envelope me-2',
      action: handleBulkAskPreferences
    },
    {
      description: 'Mail versturen',
      iconClassName: 'bi-envelope me-2',
      action: handleBulkMail
    }
  ];

  const cols = [
    {
      fieldName: 'id',
      description: 'Nr',
      sortable: true
    },
    {
      fieldName: 'fullName',
      description: 'Naam',
      sortable: false,
      link: { type: 'student', fieldName: 'id' }
    },
    {
      fieldName: 'phone',
      description: 'Telefoon',
      sortable: false
    },
    {
      fieldName: 'email',
      description: 'E-mail',
      sortable: false
    },
    {
      fieldName: 'internshipType.name',
      description: 'Stage type',
      sortable: false
    },
    {
      fieldName: 'hasInternships',
      description: 'Geplande stage',
      type: 'bool',
      sortable: false
    },
    {
      fieldName: '',
      description: '',
      editBtn: { type: 'student', fieldName: 'id', description: 'bekijk' }
    }
  ];
  useEffect(() => {
    fetchMailTemplate();
  }, []);
  useEffect(() => {}, [editStudentModalVisible]);
  return (
    <>
      <EditStudentModal
        isVisible={editStudentModalVisible}
        handleClose={() => {
          handleEditStudentModalClose();
        }}
      />

      <MailModal isVisible={mailModalVisible} selectedIds={selectedStudents} message={mailTemplate} handleClose={handleMailModalClose} modalTitle="Voorkeur bevraging versturen" />
      <BulkMailModel isVisible={bulkMailModalVisible} selectedIds={selectedStudents} handleClose={handleBulkMailModalClose} modalTitle="Mail versturen" entityService={studentService} />
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-no-gutter">
                <li className="breadcrumb-item">
                  <a className="breadcrumb-link" href="/stages">
                    Studenten
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Lijst
                </li>
              </ol>
            </nav>

            <h1 className="page-header-title">Studenten</h1>
          </div>
          <div className="col-sm-auto">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleEditStudentModalShow();
              }}
            >
              <i className="bi-plus me-1" />
              Nieuwe student
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <TableList
          entityType="studenten"
          columnDefinition={cols}
          deleteItems={studentService().delete}
          getItems={studentService().getAll}
          exportItems={studentService().export}
          archiveItems={studentService().archive}
          unArchiveItems={studentService().unArchive}
          filterControls={filterControls}
          actionButtons={actionButtons}
        />
      </div>
    </>
  );
}

export default StudentList;
