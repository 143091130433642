import axios from 'axios';
import config from '../../config';

const entityService = (path) => {
  const that = {};
  that.path = path;
  that.apiUrl = config.apiUrl;
  that.axios = axios;

  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        window.location = '/login';
      }
      throw error;
    }
  );

  that.getAll = async (page, pageSize, sortField, sortOrder, term, filters) => {
    const data = {
      page,
      pageSize,
      sortField,
      sortOrder,
      term,
      ...filters
    };
    const response = await axios.post(`${config.apiUrl}${path}/query`, data);
    return response.status === 200 ? response.data : undefined;
  };

  that.export = async (sortField, sortOrder, term, filters) => {
    const data = {
      sortField,
      sortOrder,
      term,
      ...filters
    };
    const response = await axios.post(`${config.apiUrl}${path}/export`, data);
    return response.status === 200 ? response.data : undefined;
  };

  that.getSingle = async (id) => {
    const response = await axios.get(`${config.apiUrl}${path}/${id}`);
    return response.data;
  };

  that.create = async (data) => {
    const response = await axios.post(`${config.apiUrl}${path}`, data);
    return response.status === 200 ? response.data : undefined;
  };

  that.delete = async (ids) => {
    const response = await axios.post(`${config.apiUrl}${path}/delete`, {
      ids
    });
    return response.status === 200 ? response.data : undefined;
  };

  that.archive = async (ids) => {
    const response = await axios.post(`${config.apiUrl}${path}/archive`, {
      ids
    });
    return response.status === 200 ? response.data : undefined;
  };

  that.unArchive = async (ids) => {
    const response = await axios.post(`${config.apiUrl}${path}/unArchive`, {
      ids
    });
    return response.status === 200 ? response.data : undefined;
  };

  that.update = async (id, data) => {
    const response = await axios.put(`${config.apiUrl}${path}/${id}`, data);
    return response.status === 200 ? response.data : undefined;
  };

  that.sendMail = async (ids, message, description) => {
    const response = await axios.post(`${config.apiUrl}${path}/sendMail`, {
      ids,
      message,
      description
    });
    return response.status === 200 ? response.data : undefined;
  };

  return that;
};

export default entityService;
