import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import EntityNote from '../Common/EntityNote';
import officeService from '../../services/office.service';
import ErrorToast from '../Common/ErrorToast';
import dateFormat from '../../helpers/date-format';
import EditOfficeModal from '../Offices/EditOfficeModal';
import HeaderSvg from '../../assets/svg/components/card-6.svg';
import Logo from '../../assets/img/logo.png';
import CommentModal from './CommentModal';
import EmailSvg from '../../assets/svg/oc-email-verification.svg';

function OfficeView() {
  const navigate = useNavigate();
  const { token, id } = useParams();
  const [errorToastVisible, setErrorToastVisible] = useState(false);
  const [office, setOffice] = useState(null);
  const [editOfficeModalVisible, setEditOfficeModalVisible] = useState(false);
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [noAccess, setNoAccess] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const closeErrorToast = () => {
    setErrorToastVisible(false);
  };

  const showErrorToast = () => {
    setErrorToastVisible(true);
  };

  const handleCommentModalClose = async (data) => {
    if (data) {
      await officeService().sendCommentByToken(id, token, { description: data });
    }

    setCommentModalVisible(false);
  };

  const publicOfficeService = () => {
    const that = {};
    that.update = async (id, data) => {
      await officeService().updateByToken(id, token, data);
    };

    return that;
  };

  const fetchOffice = async () => {
    try {
      setIsBusy(true);
      const response = await officeService().getSingleByToken(id, token);
      setOffice(response);
    } catch (error) {
      showErrorToast();
      setNoAccess(true);
    }
    setIsBusy(false);
  };

  const handleEditOfficeModalShow = () => {
    setEditOfficeModalVisible(true);
  };

  const handleEditOfficeModalClose = async (modified) => {
    if (modified) {
      await fetchOffice();
    }
    setEditOfficeModalVisible(false);
  };

  useEffect(() => {
    if (token) {
      fetchOffice();
    } else {
      navigate('/login');
    }
  }, []);
  return (
    <>
      {noAccess === false && (
        <div className="p-lg-10" style={{ backgroundColor: 'rgb(248, 250, 255)' }}>
          <EditOfficeModal
            token={token}
            isVisible={editOfficeModalVisible}
            officeId={id}
            handleClose={(e) => {
              handleEditOfficeModalClose(e);
            }}
          />

          <CommentModal isVisible={commentModalVisible} handleClose={handleCommentModalClose} />
          <ErrorToast handleClose={() => closeErrorToast()} isVisible={errorToastVisible} error="Data kon niet geladen worden. Mogelijk geen internet connectie!" />
          <div className="zi-1 d-flex py-6 justify-content-between align-items-center">
            <div className="zi-1 d-flex">
              <img src={Logo} className="logo-xs" alt="fg-logo-small" style={{ marginTop: 0, zIndex: '1' }} />
              <h1 className="d-inline ms-4">{office?.name} - info pagina</h1>
            </div>
            <button type="button" className="zi-1 btn-white btn" onClick={() => setCommentModalVisible(true)}>
              Vraag of opmerking?
            </button>
          </div>
          <div className="position-fixed top-0 end-0 start-0 bg-img-start" style={{ height: '32rem', backgroundImage: `url(${HeaderSvg})`, zIndex: '0' }} alt="">
            <div className="shape shape-bottom zi-1">
              <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
                <polygon fill="rgb(248,250,255)" points="0,273 1921,273 1921,0 " />
              </svg>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h4 className="card-header-title">Algemeen</h4>
                  <button type="button" className="btn btn-link" onClick={handleEditOfficeModalShow}>
                    Aanpassen
                  </button>
                </div>
                <div className="card-body">
                  {office != null ? (
                    <ul className="list-group list-group-flush list-group-no-gutters">
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between align-items-center">
                          <h5>Contact info</h5>
                        </div>

                        <ul className="list-unstyled list-py-1 text-body">
                          <li>
                            <i className="bi-at me-1" />
                            {office.email}
                          </li>
                          <li>
                            <i className="bi-phone me-1" />
                            {office.phone}
                          </li>
                        </ul>
                      </li>

                      <li className="list-group-item">
                        <div className="d-flex justify-content-between align-items-center">
                          <h5>Adresgegevens</h5>
                        </div>
                        <span className="d-block text-body">
                          {office.addressLine1}
                          {office.addressLine2 && (
                            <>
                              <br />
                              {office.addressLine2}
                            </>
                          )}
                          <br />
                          {office.postalCode} - {office.city?.name}
                        </span>
                      </li>

                      <li className="list-group-item">
                        <div className="d-flex justify-content-between align-items-center">
                          <h5>Kenmerken</h5>
                        </div>
                        <ul className="list-unstyled list-py-1 text-body">
                          <li>
                            <i className="bi-briefcase-fill me-1" />
                            {office.type}
                          </li>
                          <li>
                            <i className="bi-cash-coin me-1" />
                            {office.formOfFinance}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ) : (
                    isBusy && <Skeleton count={3} />
                  )}
                </div>
              </div>

              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h4 className="card-header-title">Leeropportuniteiten</h4>
                </div>
                <EntityNote entity={office} fieldName="learningOpportunities" entityService={publicOfficeService} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h4 className="card-header-title">Disciplines</h4>
                </div>
                <EntityNote entity={office} fieldName="disciplines" entityService={publicOfficeService} />
              </div>
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h4 className="card-header-title">Infrastructuur</h4>
                </div>
                <EntityNote entity={office} fieldName="infrastructure" entityService={publicOfficeService} />
              </div>
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h4 className="card-header-title">Religieuze tekens</h4>
                </div>
                <EntityNote entity={office} fieldName="religiousSigns" entityService={publicOfficeService} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h4 className="card-header-title">Contacten</h4>
                </div>
                <div className="p-4">
                  {office?.contacts && office.contacts.data.rows.length > 0 ? (
                    <ul className="list-group">
                      {office.contacts.data.rows.map((contact) => (
                        <li className="list-group-item text-body">
                          <div>
                            <div className="row align-items-center">
                              <div className="col">
                                <h5 className="mb-0">
                                  <div className="text-strong">
                                    <i className="bi-person me-2" width="16" height="16" />
                                    {contact?.fullName}
                                  </div>
                                </h5>
                              </div>
                              <div className="col-auto">
                                <ul className="list-unstyled list-py-1 text-body">
                                  <li>
                                    <i className="bi-at me-1" />
                                    {contact?.email}
                                  </li>
                                  <li>
                                    <i className="bi-phone me-1" />
                                    {contact?.phone}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    isBusy && <Skeleton count={5} />
                  )}
                </div>
              </div>
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h4 className="card-header-title">Stages</h4>
                </div>
                <div className="p-4">
                  {office?.internships && office.internships.data.rows.length > 0 ? (
                    <ul className="list-group">
                      {office.internships.data.rows.map((internship) => (
                        <li className="list-group-item text-body">
                          <div>
                            <div className="row align-items-center">
                              <div className="col">
                                <h5 className="mb-0">
                                  <div className="text-strong">
                                    <i className="bi-mortarboard me-2" width="16" height="16" />
                                    {internship.student?.fullName}
                                  </div>
                                </h5>
                                <div className="mt-2">
                                  <i className="bi-calendar-check me-2" width="16" height="16" />
                                  {dateFormat(internship.start, false)} - {dateFormat(internship.end, false)}
                                </div>
                              </div>
                              <div className="col-auto">
                                <span
                                  className="legend-indicator"
                                  style={{
                                    backgroundColor: `${internship.internshipStatus?.color}`
                                  }}
                                />
                                {internship.internshipStatus?.name}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    isBusy && <Skeleton count={5} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {noAccess === true && (
        <div className="py-10 px-10">
          <div className="card card-lg mt-10 w-auto">
            <div className="card-body p-6 align-content-center">
              <img src={EmailSvg} alt="todo" style={{ width: '20rem' }} className="m-auto d-block" />
              <h1 className="display-5 mt-5 mb-3 text-center">Uitnodiging verlopen..</h1>
              <p style={{ width: '30rem' }} className="m-auto text-center">
                Oeps.. deze uitnodiging is ondertussen verlopen. <br />
                Je hoeft dus niets te ondernemen. <br />
                Indien nodig versturen we een nieuwe uitnodiging!
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OfficeView;
