import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import PasswordControl from '../Common/PasswordControl';
import userService from '../../services/user.service';
import './set-password.scss';

function SetPassword() {
  const { token, id } = useParams();
  const [isBusy, setIsBusy] = useState(false);
  const [pwdChangeSuccess, setPwdChangeSuccess] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    formState: { isValid, errors }
  } = useForm();

  const onSubmit = async (data) => {
    await trigger();
    if (isValid) {
      try {
        setIsBusy(true);
        await userService().setPassword(id, token, data.newPassword);
        setPwdChangeSuccess(true);
        navigate('/login');
      } catch (error) {
        setIsBusy(false);
      }
    }
  };

  return (
    <div className="pw-container">
      <div className="row">
        <div className="col-lg">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-title">Wachtwoord instellen</h4>
            </div>
            <div className="card-body">
              {!pwdChangeSuccess && (
                <form key={3} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row pt-4">
                    <div className="col-4 col-lg-3 col-sm-5">
                      <label htmlFor="newPassword" className="form-label">
                        Nieuw wachtwoord
                      </label>
                    </div>
                    <div className="col-8 col-sm-7 col-lg-6">
                      <PasswordControl register={register} fieldName="newPassword" name="newPassword" placeholder="vul hier het nieuwe wachtwoord in." />
                    </div>
                  </div>
                  <div className="row">
                    <span className="invalid-feedback ms-1" style={{ display: errors['newPassword'] ? 'block' : 'none' }}>
                      {errors.newPassword?.message}
                    </span>
                  </div>
                  <div className="row pt-4">
                    <div className="col-4 col-lg-3 col-sm-5">
                      <label htmlFor="confirmPassword" className="form-label">
                        Bevestig wachtwoord
                      </label>
                    </div>
                    <div className="col-8 col-sm-7 col-lg-6">
                      <input
                        {...register('confirmPassword', {
                          required: 'Bevestig aub het wachtwoord.',
                          validate: (match) => {
                            const password = getValues('newPassword');
                            return match === password || 'Wachtwoorden komen niet overeen!';
                          }
                        })}
                        name="confirmPassword"
                        type="password"
                        className="form-control"
                        autoComplete="false"
                        placeholder="vul hier nogmaals het nieuwe wachtwoord in."
                      />
                    </div>
                  </div>
                  <div className="row">
                    <span className="invalid-feedback ms-1" style={{ display: errors['confirmPassword'] ? 'block' : 'none' }}>
                      {errors.confirmPassword?.message}
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-4 col-lg-3 col-sm-5" />
                    <div className="col-8 col-sm-7 col-lg-6 pt-4">
                      <h5>Wachtwoord vereisten:</h5>

                      <p className="fs-6 mb-2">Zorg ervoor dat aan deze vereisten wordt voldaan:</p>

                      <ul className="fs-6">
                        <li>Minimaal 8 tekens lang - hoe meer, hoe beter</li>
                        <li>Ten minste één kleine letter</li>
                        <li>Ten minste één hoofdletter</li>
                        <li>Ten minste één cijfer, symbool of witruimteteken</li>
                      </ul>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">
                      {isBusy ? <PulseLoader className="loader" color="#fff" size="5" /> : 'Bewaren'}
                    </button>
                  </div>
                </form>
              )}
              {pwdChangeSuccess && <p>Uw wachtwoord is succesvol aangepast!</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SetPassword;
