import entityService from './base/entity.service';

const cityService = () => {
  const baseService = entityService('cities');
  const that = {};

  that.getAll = baseService.getAll;
  that.getSingle = baseService.getSingle;

  return that;
};

export default cityService;
