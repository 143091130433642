import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import PulseLoader from 'react-spinners/PulseLoader';
import { useNavigate } from 'react-router-dom';
import officeService from '../../services/office.service';
import regionService from '../../services/region.service';
import cityService from '../../services/city.service';
import ValidatedFormField from '../Common/ValidatedFormField';
import FormSelect from '../Common/FormSelect';

function EditOfficeModal({ isVisible, handleClose, officeId, token }) {
  const [wizardStep, setWizardStep] = useState(1);
  const [isBusy, setIsBusy] = useState(false);
  const [office, setOffice] = useState({});

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    control,
    reset,
    formState: { isValid, errors }
  } = useForm();

  const navigate = useNavigate();

  const onCityChange = async (value) => {
    const n = getValues();

    n.cityId = value.id;
    n.postalCode = value.postalCode;
    n.regionId = value.regionId;

    reset(n);
    setOffice(n);
  };

  const loadEntity = async () => {
    const office = token ? await officeService().getSingleByToken(officeId, token) : await officeService().getSingle(officeId);
    setOffice(office);
  };

  const handleNext = async () => {
    await trigger();
    if (isValid) {
      setWizardStep(wizardStep + 1);
    }
  };

  const handleBack = () => {
    setWizardStep(wizardStep - 1);
  };

  const onSubmit = async (data) => {
    await trigger();
    if (isValid) {
      setIsBusy(true);
      const contacts = [data.contact];
      delete data.contact;
      data.contacts = contacts;
      if (officeId) {
        if (!token) {
          await officeService().update(officeId, data);
        } else {
          // public view
          await officeService().updateByToken(officeId, token, data);
        }

        handleClose(true);
      } else {
        const result = await officeService().create(data);
        if (result !== undefined) {
          navigate(`/offices/${result.id}`);
        }
      }

      setIsBusy(false);
    }
  };

  useEffect(() => {
    reset(office);
  }, [office]);
  useEffect(() => {
    if (isVisible && officeId) {
      setIsBusy(true);
      loadEntity();
      setIsBusy(false);
    }
  }, [isVisible]);
  return (
    <Modal show={isVisible} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{officeId ? 'Praktijk bewerken' : 'Nieuwe praktijk'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!officeId && (
            <ul className="step step-sm step-icon-sm step-inline step-item-between mb-3 mb-sm-7">
              <li className={`${wizardStep === 1 ? 'step-item active focus' : 'step-item'} `}>
                <div className="step-content-wrapper">
                  <span className="step-icon step-icon-soft-dark">1</span>
                  <div className="step-content">
                    <span className="step-title">Algemeen</span>
                  </div>
                </div>
              </li>
              <li className={`${wizardStep === 2 ? 'step-item active focus' : 'step-item'} `}>
                <div className="step-content-wrapper">
                  <span className="step-icon step-icon-soft-dark">2</span>
                  <div className="step-content">
                    <span className="step-title">Primair contact</span>
                  </div>
                </div>
              </li>
            </ul>
          )}
          {wizardStep === 1 && (
            <div>
              {token && <h3>{office?.name}</h3>}
              {!token && (
                <ValidatedFormField className="mb-4" errors={errors} name="name" description="Praktijk Naam">
                  <input
                    {...register('name', { required: true })}
                    name="name"
                    type="text"
                    className="form-control"
                    defaultValue={office?.name}
                    autoComplete="false"
                    placeholder="vul hier de naam in."
                  />
                </ValidatedFormField>
              )}

              <div className="d-flex">
                <input name="longInternships" type="checkbox" {...register('longInternships')} id="longInternships" className="me-2" />
                <label htmlFor="longInternships" className="form-label mt-2">
                  Lange stages
                </label>
              </div>
              <div className="d-flex mb-2">
                <input name="shortInternships" type="checkbox" {...register('shortInternships')} id="shortInternships" className="me-2" />
                <label htmlFor="shortInternships" className="form-label mt-2">
                  Korte stages
                </label>
              </div>
              <ValidatedFormField className="mb-4" errors={errors} name="type" description="Type">
                <FormSelect
                  name="type"
                  required
                  defaultValue={office?.type}
                  data={[
                    { id: 'Solo', name: 'Solo' },
                    { id: 'Duo', name: 'Duo' },
                    { id: 'Groep', name: 'Groep' },
                    { id: 'Gezondheidscentrum', name: 'Gezondheidscentrum' }
                  ]}
                  control={control}
                  iconClassName="bi-briefcase-fill"
                />
              </ValidatedFormField>
              <ValidatedFormField className="mb-4" errors={errors} name="formOfFinance" description="Financieringsvorm">
                <FormSelect
                  name="formOfFinance"
                  defaultValue={office?.formOfFinance}
                  data={[
                    { id: 'Forfaitair', name: 'Forfaitair' },
                    { id: 'Prestaties', name: 'Prestaties' }
                  ]}
                  control={control}
                  iconClassName="bi-cash-coin"
                />
              </ValidatedFormField>
              {!token && (
                <>
                  <ValidatedFormField className="mb-4" errors={errors} name="cityId" description="Plaats">
                    <FormSelect
                      name="cityId"
                      required
                      defaultValue={office?.cityId}
                      onChange={(e) => onCityChange(e)}
                      loadService={cityService}
                      control={control}
                      optionLabels={['name', 'postalCode']}
                      iconClassName="bi-geo-alt"
                    />
                  </ValidatedFormField>
                  <ValidatedFormField className="mb-4" errors={errors} name="postalCode" description="Postcode">
                    <input
                      {...register('postalCode', { required: true })}
                      name="postalCode"
                      type="text"
                      className="form-control"
                      defaultValue={office?.postalCode}
                      autoComplete="false"
                      placeholder="vul hier de postcode in."
                    />
                  </ValidatedFormField>
                  <ValidatedFormField
                    className="mb-4"
                    errors={errors}
                    name="regionId"
                    description="Eerstelijnszone"
                    tooltip="De eerstelijnszones zijn regionale groeperingen van praktijken op basis van postcode"
                  >
                    <FormSelect name="regionId" required defaultValue={office?.regionId} loadService={regionService} control={control} iconClassName="bi-geo-alt" />
                  </ValidatedFormField>
                  <ValidatedFormField className="mb-4" errors={errors} name="addressLine1" description="Adreslijn 1">
                    <input
                      {...register('addressLine1', { required: true })}
                      name="addressLine1"
                      type="text"
                      className="form-control"
                      defaultValue={office?.addressLine1}
                      autoComplete="false"
                      placeholder="vul hier het adres in."
                    />
                  </ValidatedFormField>
                  <ValidatedFormField className="mb-4" errors={errors} name="addressLine2" description="Adreslijn 2">
                    <input
                      {...register('addressLine2')}
                      name="addressLine2"
                      type="text"
                      className="form-control"
                      defaultValue={office?.addressLine2}
                      autoComplete="false"
                      placeholder="vul hier het adres in."
                    />
                  </ValidatedFormField>
                </>
              )}
              <div className="row">
                <div className="col col-sm">
                  <div className="mb-4">
                    <label htmlFor="email" className="form-label">
                      Email adres
                    </label>
                    <div className="input-group input-group-merge">
                      <div className="input-group-prepend input-group-text">
                        <i className="bi bi-envelope" />
                      </div>
                      <input
                        {...register('email')}
                        type="e-mail"
                        className="form-control"
                        defaultValue={office?.email}
                        name="email"
                        autoComplete="false"
                        placeholder="vul hier het algemene email adres in."
                      />
                    </div>
                  </div>
                </div>
                <div className="col col-sm">
                  <div className="mb-4">
                    <label htmlFor="phone" className="form-label">
                      Telefoon
                    </label>
                    <div className="input-group input-group-merge">
                      <div className="input-group-prepend input-group-text">
                        <i className="bi bi-telephone" />
                      </div>
                      <input
                        {...register('phone')}
                        type="phone"
                        className="form-control"
                        defaultValue={office?.phone}
                        name="phone"
                        autoComplete="false"
                        placeholder="vul hier het algemene telefoonnummer in."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {wizardStep === 2 && (
            <div>
              <div className="col-lg-4 mb-3 mb-lg-0">
                <h4>Contactpersoon</h4>
                <p>Het primaire contact van de praktijk.</p>
              </div>
              <ValidatedFormField className="mb-4" errors={errors} name="contact.firstName" description="Voornaam">
                <input {...register('contact.firstName')} name="contact.firstName" type="text" className="form-control" autoComplete="false" placeholder="vul hier de voornaam in." />
              </ValidatedFormField>
              <ValidatedFormField className="mb-4" errors={errors} name="contact.lastName" description="Achternaam">
                <input {...register('contact.lastName')} name="contact.lastName" type="text" className="form-control" autoComplete="false" placeholder="vul hier de achternaam in." />
              </ValidatedFormField>
              <ValidatedFormField className="mb-4" errors={errors} name="contact.email" description="E-mail adres">
                <div className="input-group input-group-merge">
                  <div className="input-group-prepend input-group-text">
                    <i className="bi bi-envelope" />
                  </div>
                  <input
                    {...register('contact.email', { required: true })}
                    type="email"
                    className="form-control"
                    name="contact.email"
                    autoComplete="false"
                    placeholder="vul hier het e-mail adres in."
                  />
                </div>
              </ValidatedFormField>
              <ValidatedFormField className="mb-4" errors={errors} name="contact.phone" description="Telefoon">
                <div className="input-group input-group-merge">
                  <div className="input-group-prepend input-group-text">
                    <i className="bi bi-telephone" />
                  </div>
                  <input
                    {...register('contact.phone', { required: true })}
                    type="phone"
                    className="form-control"
                    name="contact.phone"
                    autoComplete="false"
                    placeholder="vul hier het telefoonnummer in."
                  />
                </div>
              </ValidatedFormField>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {wizardStep > 1 && (
            <button type="button" className="btn btn-ghost-primary ms-0 me-auto" onClick={handleBack}>
              <i className="bi-chevron-left me-1" />
              Vorige
            </button>
          )}
          {wizardStep < 3 && !officeId && (
            <button type="button" className="btn btn-primary" onClick={handleNext}>
              Volgende
              <i className="bi-chevron-right ms-1" />
            </button>
          )}
          {(wizardStep === 3 || officeId) && (
            <button type="submit" className="btn btn-primary">
              {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Bewaren'}
            </button>
          )}
          <button type="button" className="btn btn-white" onClick={handleClose}>
            Sluiten
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default EditOfficeModal;
