import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce';
import dateFormat from '../../helpers/date-format';

import './form-select.scss';

function FormSelect({ name, required, loadService, defaultValue, control, onChange, orderBy = 'name', optionValue = 'id', optionLabels = 'name', iconClassName, filters = {}, data, className }) {
  const [selectedValue, setSelectedValue] = useState();
  const fetchData = (query, callback) => {
    if (data) {
      callback(data);
    } else {
      loadService()
        .getAll(1, 20, orderBy, 'DESC', query, filters)
        .then((resp) => callback(resp.data?.rows));
    }
  };

  const getOptionLabels = (option) => {
    if (optionLabels && !Array.isArray(optionLabels)) {
      return `${option[optionLabels]}`;
    }

    const labelValues = optionLabels.map((label) => {
      const value = option[label];
      if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/)) {
        const date = new Date(value);
        if (!Number.isNaN(date)) {
          return dateFormat(date, false);
        }
      }
      return value;
    });

    const lbls = labelValues.join(' - ');
    return lbls;
  };

  const getOrFetchValue = async () => {
    if (defaultValue != null) {
      if (defaultValue.id) {
        setSelectedValue(defaultValue);
      } else if (loadService) {
        const fetched = await loadService().getSingle(defaultValue);
        setSelectedValue(fetched);
      } else if (data) {
        setSelectedValue(data.find((item) => item.id === defaultValue));
      } else {
        setSelectedValue(defaultValue);
      }
    }
  };

  const loadData = debounce(fetchData, 200);

  useEffect(() => {
    getOrFetchValue();
  }, [defaultValue]);
  return (
    <Controller
      key={JSON.stringify({ filters })}
      name={name}
      rules={{ required }}
      control={control}
      render={({ field, onBlur, ref }) => (
        <div className={`form-select-container ${className}`}>
          <i className={iconClassName} />
          <AsyncSelect
            components={{
              IndicatorSeparator: () => null
            }}
            loadOptions={loadData}
            placeholder="Type voor suggesties..."
            getOptionValue={(option) => `${option[optionValue]}`}
            getOptionLabel={(option) => getOptionLabels(option)}
            onChange={(e, meta) => {
              if (meta.action === 'clear') {
                field.onChange(null);
                setSelectedValue(null);
                if (onChange) {
                  onChange(e);
                }
              } else {
                field.onChange(e[optionValue]);
                setSelectedValue(e);
                if (onChange) {
                  onChange(e);
                }
              }
            }}
            inputRef={ref}
            onBlur={onBlur}
            isClearable
            isSearchable
            defaultOptions
            noOptionsMessage={() => 'Geen resultaten'}
            value={selectedValue}
            style={{ zIndex: 999 }}
            unstyled
            classNames={{
              control: () => 'react-select',
              menu: () => 'react-select-menu',
              option: () => 'react-select-option',
              dropdownIndicator: () => 'react-select-indicators',
              clearIndicator: () => 'react-select-indicators',
              valueContainer: () => 'react-select-value-container',
              placeholder: () => 'react-select-placeholder'
            }}
          />
        </div>
      )}
    />
  );
}

export default FormSelect;
