import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Logo from '../../assets/img/logo.png';
import '../../scss/theme.scss';
import authService from '../../services/auth.service';
import styles from './Login.module.scss';

function Login() {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(false);
  const {
    register,
    handleSubmit,
    trigger,
    formState: { isValid, errors }
  } = useForm();

  const isLogout = useParams().logout === 'logout';

  const onSubmit = async (data) => {
    setLoginError(false);
    await trigger();
    if (isValid) {
      try {
        await authService().login({
          username: data.email,
          password: data.password
        });
        navigate('/');
      } catch {
        setLoginError(true);
      }
    }
  };

  const logOut = async () => {
    await authService().logout();
  };

  useEffect(() => {
    if (isLogout) {
      logOut();
    }
  }, []);
  return (
    <div>
      <main id="content" role="main" className="main pt-0">
        <div className="container-fluid px-3">
          <div className={`row ${styles.row}`}>
            <div className={`col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0 ${styles['bg-login']}`}>
              <div className="position-absolute top-0 start-0 end-0 mt-3 mx-3">
                <div className="d-none d-lg-flex justify-content-between">
                  <img src={Logo} className={styles.logo} alt="fg-logo" />
                </div>
              </div>
              <div
                style={{
                  maxWidth: '55rem'
                }}
              >
                <div className="text-center mb-5" />
                <div className="mb-5">
                  <h1 className="display-2 text-white fw-bold">Stageplanner.</h1>
                  <div
                    className="display-4 text-white fw-lighter"
                    style={{
                      maxWidth: '40rem'
                    }}
                  >
                    Alles bij de hand, zo gepland.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
              <div
                className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1"
                style={{
                  maxWidth: '25rem'
                }}
              >
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>E-mail adres</Form.Label>
                    <Form.Control {...register('email', { required: true })} type="email" placeholder="Enter email" required />
                    <Form.Control.Feedback type="invalid" style={{ display: errors.email ? 'block' : 'none' }}>
                      Geldig e-mail adres vereist
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="w-100 d-block">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>Wachtwoord</span>
                        <a className="form-label-link mb-0" href="/auth/forgotpwd">
                          Wachtwoord vergeten?
                        </a>
                      </span>
                    </Form.Label>
                    <Form.Control {...register('password', { required: true })} type="password" placeholder="Password" required />
                    <Form.Control.Feedback type="invalid" style={{ display: errors.password ? 'block' : 'none' }}>
                      Wachtwoord is vereist
                    </Form.Control.Feedback>
                  </Form.Group>
                  <span className="invalid-feedback ms-1 mb-3" style={{ display: loginError ? 'block' : 'none' }}>
                    Er is geen combinatie van het opgegeven e-mail adres en wachtwoord gevonden.
                  </span>
                  <Button className="w-100" variant="primary" type="submit">
                    Aanmelden
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Login;
