import entityService from './base/entity.service';

const officeService = () => {
  const baseService = entityService('offices');

  const that = {};

  that.getAll = baseService.getAll;

  that.sendPortalLink = async (ids, message) => {
    const response = await baseService.axios.post(`${baseService.apiUrl}${baseService.path}/sendPortalLink`, { message, ids });
    return response.status === 200 ? response.data : undefined;
  };

  that.getSingleByToken = async (id, token) => {
    const response = await baseService.axios.get(`${baseService.apiUrl}${baseService.path}/${id}/bytoken/${token}`);
    return response.data;
  };

  that.updateByToken = async (id, token, data) => {
    const response = await baseService.axios.put(`${baseService.apiUrl}${baseService.path}/${id}/bytoken/${token}`, data);
    return response.data;
  };

  that.cancelToken = async (id) => {
    const response = await baseService.axios.post(`${baseService.apiUrl}${baseService.path}/canceltoken/${id}`);
    return response.data;
  };

  that.sendCommentByToken = async (id, token, data) => {
    const response = await baseService.axios.post(`${baseService.apiUrl}${baseService.path}/${id}/sendcomment/bytoken/${token}`, data);
    return response.data;
  };

  that.getSingle = baseService.getSingle;
  that.create = baseService.create;
  that.update = baseService.update;
  that.delete = baseService.delete;
  that.export = baseService.export;
  that.archive = baseService.archive;
  that.unArchive = baseService.unArchive;
  that.sendMail = baseService.sendMail;

  return that;
};

export default officeService;
