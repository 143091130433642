import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, Outlet } from 'react-router-dom';
import Sidebar from './components/Layouts/Sidebar';
import nameInitials from './helpers/name-initials';
import 'react-loading-skeleton/dist/skeleton.css';
import userService from './services/user.service';
import officeService from './services/office.service';
import contactService from './services/contact.service';
import studentService from './services/student.service';
import internshipService from './services/internship.service';

function App() {
  const [headerMargin, setHeaderMargin] = useState('12.25rem');
  const [rootMargin, setRootMargin] = useState('13.5rem');
  const [darkMode, setDarkMode] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [user, setUser] = useState();

  const getUser = async () => {
    try {
      const response = await userService().getProfile();
      setUser(response);
    } catch (error) {
      console.log('failed to load user');
    }
  };

  const fetchProfileImage = async () => {
    try {
      const response = await userService().getProfileImage();
      setProfileImage(response);
    } catch (error) {
      console.log('failed to load profile image');
    }
  };

  const onHeaderMarginChanged = (value) => {
    setHeaderMargin(value);
    setRootMargin(`${parseInt(value, 10) + 1.6}rem`);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
  };

  const search = async () => {
    const sr = { contacts: [], offices: [], students: [], internships: [] };

    sr.contacts = (await contactService().getAll(1, 10, 'id', 'ASC', searchTerm, {}))?.data?.rows;
    sr.offices = (await officeService().getAll(1, 10, 'id', 'ASC', searchTerm, {}))?.data?.rows;
    sr.students = (await studentService().getAll(1, 10, 'id', 'ASC', searchTerm, {}))?.data?.rows;
    sr.internships = (await internshipService().getAll(1, 10, 'id', 'ASC', searchTerm, {}))?.data?.rows;

    sr.totalCount = sr.contacts.length + sr.offices.length + sr.students.length + sr.internships.length;
    setSearchResults(sr);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchTerm) {
      const getData = setTimeout(async () => {
        await search();
        clearTimeout(getData);
      }, 200);
    }
  }, [searchTerm]);
  useEffect(() => {
    getUser();
    fetchProfileImage();
  }, []);
  useEffect(() => {
    if (darkMode) {
      document.body.style.background = '#1e2022';
    } else {
      document.body.style.background = '#fff';
    }
  }, [darkMode]);

  return (
    <div className={`App ${darkMode ? 'dark' : 'light'}`}>
      <Sidebar setHeaderMargin={(e) => onHeaderMarginChanged(e)} setDarkMode={() => toggleDarkMode()} darkMode={darkMode} />
      <header
        id="header"
        className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-container navbar-bordered bg-white navbar-vertical-aside"
        style={{
          marginLeft: headerMargin
        }}
      >
        <div className="navbar-nav-wrap">
          <div className="d-none d-lg-block">
            <div className="input-group input-group-merge input-group-borderless input-group-hover-light navbar-input-group">
              <div className="input-group-prepend input-group-text">
                <i className="bi-search" />
              </div>

              <input
                type="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control focus"
                placeholder="Zoeken in stagemanager"
                aria-label="Zoeken in stagemanager"
              />
              <button type="button" className="input-group-append input-group-text" onClick={clearSearch}>
                <i className="bi-x-lg" style={{ display: searchTerm.length > 0 ? 'block' : 'none', opacity: 1.02333 }} />
              </button>
            </div>
            <div
              className="card search-dropdown"
              style={{ visibility: searchTerm.length > 0 ? 'visible' : 'hidden', opacity: searchTerm.length > 0 ? '100' : '0', transition: 'opacity 300ms linear' }}
            >
              <div className="card-body">
                {searchResults.totalCount > 0 ? <span className="dropdown-header">Zoekresultaten</span> : <span className="dropdown-header">Geen resultaten gevonden.</span>}

                {searchResults.contacts?.length > 0 && <span className="dropdown-header">Contacten</span>}
                {searchResults &&
                  searchResults.contacts &&
                  searchResults.contacts.map((contact) => (
                    <Link className="dropdown-item" to={`/contacts/${contact.id}`} onClick={clearSearch} key={contact.id}>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <span className="icon icon-soft-dark icon-xs icon-circle">
                            <i className="bi-person" />
                          </span>
                        </div>

                        <div className="flex-grow-1 text-truncate text-dark ms-2">
                          <span>{contact.fullName}</span>
                        </div>
                      </div>
                    </Link>
                  ))}

                {searchResults.offices?.length > 0 && (
                  <>
                    <div className="dropdown-divider" />
                    <span className="dropdown-header">Praktijken</span>
                  </>
                )}
                {searchResults &&
                  searchResults.offices &&
                  searchResults.offices.map((office) => (
                    <Link className="dropdown-item" to={`/offices/${office.id}`} onClick={clearSearch} key={office.id}>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <span className="icon icon-soft-dark icon-xs icon-circle">
                            <i className="bi-hospital" />
                          </span>
                        </div>

                        <div className="flex-grow-1 text-truncate text-dark ms-2">
                          <span>{office.name}</span>
                        </div>
                      </div>
                    </Link>
                  ))}

                {searchResults.students?.length > 0 && (
                  <>
                    <div className="dropdown-divider" />
                    <span className="dropdown-header">Studenten</span>
                  </>
                )}
                {searchResults &&
                  searchResults.students &&
                  searchResults.students.map((student) => (
                    <Link className="dropdown-item" to={`/students/${student.id}`} onClick={clearSearch} key={student.id}>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <span className="icon icon-soft-dark icon-xs icon-circle">
                            <i className="bi-mortarboard" />
                          </span>
                        </div>

                        <div className="flex-grow-1 text-truncate text-dark ms-2">
                          <span>{student?.fullName}</span>
                        </div>
                      </div>
                    </Link>
                  ))}

                {searchResults.internships?.length > 0 && (
                  <>
                    <div className="dropdown-divider" />
                    <span className="dropdown-header">Stages</span>
                  </>
                )}
                {searchResults &&
                  searchResults.internships &&
                  searchResults.internships.map((internship) => (
                    <Link className="dropdown-item" to={`/internships/${internship.id}`} onClick={clearSearch} key={internship.id}>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <span className="icon icon-soft-dark icon-xs icon-circle">
                            <i className="bi-calendar-check" />
                          </span>
                        </div>

                        <div className="flex-grow-1 text-truncate text-dark ms-2">
                          <span>
                            {internship.student?.fullName} - {internship.office?.name}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
          <div className="navbar-nav-wrap-content-end">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Dropdown>
                  <Dropdown.Toggle drop="left" className="navbar-dropdown-account-wrapper">
                    <span className="avatar avatar-primary avatar-circle">
                      {!profileImage && <span className="avatar-initials">{nameInitials(user?.fullName)}</span>}
                      {profileImage && <img className="avatar-img" src={profileImage} alt="" />}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Link className="dropdown-item" to="/settings">
                      Instellingen
                    </Link>
                    <Link className="dropdown-item" to="/login/logout">
                      Afmelden
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <main className="main">
        <div
          className="content container-fluid"
          style={{
            paddingLeft: rootMargin
          }}
        >
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export default App;
