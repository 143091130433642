import React, { useEffect } from 'react';
import TableList from '../Common/TableList';

import internshipService from '../../services/internship.service';

function InternshipWidget({ filters }) {
  const cols = [
    {
      fieldName: 'id',
      description: 'Nr',
      sortable: true
    },
    {
      fieldName: 'internshipType.name',
      description: 'Type',
      sortable: false
    },
    {
      fieldName: 'student.fullName',
      description: 'Student',
      sortable: false,
      link: { type: 'student', fieldName: 'studentId' }
    },
    {
      fieldName: 'office.name',
      description: 'Praktijk',
      sortable: false,
      link: { type: 'office', fieldName: 'officeId' }
    },
    {
      fieldName: 'start',
      description: 'Start',
      type: 'date',
      sortable: true
    },
    {
      fieldName: 'end',
      description: 'Einde',
      type: 'date',
      sortable: true
    },
    {
      fieldName: 'internshipStatus.name',
      description: 'Status',
      type: 'internshipStatus',
      sortable: false
    },
    {
      fieldName: '',
      description: '',
      editBtn: { type: 'internship', fieldName: 'id', description: 'bekijk' }
    }
  ];
  useEffect(() => {}, []);
  return (
    <TableList
      entityType="stages"
      columnDefinition={cols}
      deleteItems={internshipService().delete}
      archiveItems={internshipService().archive}
      unArchiveItems={internshipService().unArchive}
      exportItems={internshipService().export}
      getItems={internshipService().getAll}
      filters={filters}
      isWidget
    />
  );
}

export default InternshipWidget;
