import React, { useEffect, useState } from 'react';
import userService from '../../services/user.service';
import ErrorToast from '../Common/ErrorToast';
import EditUserModal from './EditUserModal';
import ChangeProfile from './ChangeProfile';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import DeleteUser from './DeleteUser';

function User({ userId, handleClose }) {
  const [errorToastVisible, setErrorToastVisible] = useState(false);
  const [user, setUser] = useState(null);

  const [editUserModalVisible, setEditUserModalVisible] = useState(false);

  const closeErrorToast = () => {
    setErrorToastVisible(false);
  };

  const showErrorToast = () => {
    setErrorToastVisible(true);
  };

  const fetchUser = async () => {
    try {
      const response = await userService().getSingle(userId);
      setUser(response);
    } catch (error) {
      showErrorToast();
    }
  };

  const handleEditUserModalClose = async (modified) => {
    if (modified) {
      await fetchUser();
    }
    setEditUserModalVisible(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);
  return (
    <>
      <EditUserModal
        isVisible={editUserModalVisible}
        userId={userId}
        handleClose={(e) => {
          handleEditUserModalClose(e);
        }}
      />
      <ErrorToast handleClose={() => closeErrorToast()} isVisible={errorToastVisible} error="Data kon niet geladen worden. Mogelijk geen internet connectie!" />
      <button type="button" className="btn btn-link mb-4" onClick={() => handleClose()}>
        <i className="bi-chevron-left me-2" />
        Terug naar overzicht
      </button>
      <ChangeProfile user={user} onError={showErrorToast} />
      <ChangeEmail user={user} onError={showErrorToast} />
      <ChangePassword user={user} onError={showErrorToast} />
      <DeleteUser user={user} onError={showErrorToast} handleClose={handleClose} />
      <button type="button" className="btn btn-link mb-4" onClick={() => handleClose()}>
        <i className="bi-chevron-left me-2" />
        Terug naar overzicht
      </button>
    </>
  );
}

export default User;
