const dateFormat = (value, withTime) => {
  if (withTime) {
    return value
      ? new Intl.DateTimeFormat('nl-BE', { dateStyle: 'short', timeStyle: 'short' }).format(
          Date.parse(value)
        )
      : null;
  }
  return value ? new Intl.DateTimeFormat('nl-BE').format(Date.parse(value)) : null;
};

export default dateFormat;
