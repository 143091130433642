import entityService from './base/entity.service';

const userRoleService = () => {
  const baseService = entityService('userRoles');

  const that = {};

  that.getAll = (page, pageSize, sortField, sortOrder, term) => baseService.getAll(page, pageSize, sortField, sortOrder, term);

  that.getSingle = baseService.getSingle;
  that.create = baseService.create;
  that.update = baseService.update;
  that.delete = baseService.delete;

  return that;
};

export default userRoleService;
