import axios from 'axios';
import config from '../config';

const authService = () => {
  const that = {};
  that.path = 'auth';
  that.apiUrl = config.apiUrl;
  axios.defaults.withCredentials = true;
  that.axios = axios;

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      throw error;
    }
  );

  that.login = async (data) => {
    const response = await axios.post(`${config.apiUrl}${that.path}/login`, data);
    return response.status === 200;
  };

  that.logout = async () => {
    const response = await axios.post(`${config.apiUrl}${that.path}/logout`);
    return response.status === 200 ? response.data : undefined;
  };

  return that;
};

export default authService;
