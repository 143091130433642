import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce';

function FilterSelect({
  loadService,
  defaultValue,
  onChange,
  orderBy = 'name',
  optionValue = 'id',
  optionLabels = 'name',
  placeholder = 'Maak een keuze',
  filters = {},
  data,
  isClearable = true,
  isSearchable = true,
  clearKey
}) {
  const [selectedValue, setSelectedValue] = useState();
  const fetchData = (query, callback) => {
    if (data) {
      callback(data);
    } else {
      loadService()
        .getAll(1, 20, orderBy, 'DESC', query, filters)
        .then((resp) => callback(resp.data?.rows));
    }
  };

  const getOptionLabels = (option) => {
    if (optionLabels && !Array.isArray(optionLabels)) {
      return `${option[optionLabels]}`;
    }
    const a = option[`${optionLabels[0]}`];
    const b = option[`${optionLabels[1]}`];
    const lbls = `${a} - ${b}`;
    return lbls;
  };

  const getOrFetchValue = async () => {
    if (defaultValue != null) {
      if (defaultValue.id) {
        setSelectedValue(defaultValue);
      } else if (loadService) {
        const fetched = await loadService().getSingle(defaultValue);
        setSelectedValue(fetched);
      } else if (data) {
        setSelectedValue(data.find((item) => item.id === defaultValue));
      } else {
        setSelectedValue(defaultValue);
      }
    }
  };

  const loadData = debounce(fetchData, 200);
  useEffect(() => {
    setSelectedValue(null);
    getOrFetchValue();
  }, [clearKey]);
  useEffect(() => {
    getOrFetchValue();
  }, [defaultValue]);
  return (
    <AsyncSelect
      components={{
        IndicatorSeparator: () => null
      }}
      loadOptions={loadData}
      placeholder={placeholder}
      getOptionValue={(option) => `${option[optionValue]}`}
      getOptionLabel={(option) => getOptionLabels(option)}
      onChange={(e, meta) => {
        if (meta.action === 'clear') {
          setSelectedValue(null);
          onChange(undefined);
        } else {
          setSelectedValue(e);
          if (onChange) {
            onChange(e[optionValue]);
          }
        }
      }}
      isClearable={isClearable}
      isSearchable={isSearchable}
      defaultOptions
      noOptionsMessage={() => 'Geen resultaten'}
      value={selectedValue}
      unstyled
      style={{ zIndex: 999 }}
      classNames={{
        control: () => 'react-select',
        menu: () => 'react-select-menu',
        option: () => 'react-select-option',
        dropdownIndicator: () => 'react-select-indicators',
        clearIndicator: () => 'react-select-indicators',
        placeholder: () => 'react-select-placeholder'
      }}
    />
  );
}

export default FilterSelect;
