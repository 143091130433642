import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import EntityNote from '../Common/EntityNote';
import Timeline from '../Common/Timeline';
import contactService from '../../services/contact.service';
import ErrorToast from '../Common/ErrorToast';
import InternshipWidget from '../Internships/InternshipWidget';
import EditContactModal from './EditContactModal';
import EditInternshipModal from '../Internships/EditInternshipModal';

function Contact() {
  const contactId = useParams().id;
  const [errorToastVisible, setErrorToastVisible] = useState(false);
  const [contact, setContact] = useState(null);

  const [editContactModalVisible, setEditContactModalVisible] = useState(false);
  const [editInternshipModalVisible, setEditInternshipModalVisible] = useState(false);

  const showErrorToast = () => {
    setErrorToastVisible(true);
  };

  const fetchContact = async () => {
    try {
      const response = await contactService().getSingle(contactId);
      setContact(response);
    } catch (error) {
      showErrorToast();
    }
  };

  const handleEditInternshipModalClose = async (modified) => {
    if (modified) {
      await fetchContact();
    }
    setEditInternshipModalVisible(false);
  };

  const handleEditInternshipModalShow = () => {
    setEditInternshipModalVisible(true);
  };

  const handleEditContactModalShow = () => {
    setEditContactModalVisible(true);
  };

  const handleEditContactModalClose = async (modified) => {
    if (modified) {
      await fetchContact();
    }
    setEditContactModalVisible(false);
  };

  const closeErrorToast = () => {
    setErrorToastVisible(false);
  };

  useEffect(() => {}, [contact]);
  useEffect(() => {
    fetchContact();
  }, []);
  return (
    <>
      <EditContactModal
        contactId={contactId}
        isVisible={editContactModalVisible}
        handleClose={(modified) => {
          handleEditContactModalClose(modified);
        }}
      />
      <EditInternshipModal
        isVisible={editInternshipModalVisible}
        contactId={contactId}
        officeId={contact?.officeId}
        handleClose={(e) => {
          handleEditInternshipModalClose(e);
        }}
      />
      <div className="page-header">
        <ErrorToast handleClose={() => closeErrorToast()} isVisible={errorToastVisible} error="Data kon niet geladen worden. Mogelijk geen internet connectie!" />
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-no-gutter">
                <li className="breadcrumb-item">
                  <a className="breadcrumb-link" href="/contacts">
                    Contacten
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Details
                </li>
              </ol>
            </nav>
            <div className="d-flex gap-2">
              <div>
                <h1 className="page-header-title d-inline">{contact?.fullName || <Skeleton count={1} />}</h1>
              </div>
              <button type="button" className="btn btn-white btn-icon btn-xs rounded-circle" aria-expanded="false" onClick={handleEditContactModalShow}>
                <i className="bi-pencil-fill" />
              </button>
            </div>
            <div className="text-body">
              {contact ? (
                <span>
                  <i className="bi-hospital me-2" />
                  {contact.office?.name}
                </span>
              ) : (
                <Skeleton count={1} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Algemeen</h4>
            </div>
            <div className="card-body">
              {contact != null ? (
                <ul className="list-group list-group-flush list-group-no-gutters">
                  <li className="list-group-item">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Contact info</h5>
                      <button type="button" className="btn btn-link" onClick={handleEditContactModalShow}>
                        Aanpassen
                      </button>
                    </div>

                    <ul className="list-unstyled list-py-1 text-body">
                      <li>
                        <i className="bi-at me-1" />
                        {contact?.email}
                      </li>
                      <li>
                        <i className="bi-phone me-1" />
                        {contact?.phone}
                      </li>
                    </ul>
                  </li>
                </ul>
              ) : (
                <Skeleton count={3} />
              )}
            </div>
          </div>
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Notities</h4>
            </div>
            <EntityNote entity={contact} entityService={contactService} />
          </div>
          <div className="card mb-3 mb-lg-5">
            <div className="card-header card-header-content-between">
              <h4 className="card-header-title">Stages</h4>
              <button type="button" className="btn btn-ghost-secondary" onClick={handleEditInternshipModalShow}>
                <i className="bi-plus" /> Nieuwe stage
              </button>
            </div>
            {contact ? <InternshipWidget filters={{ mentorId: contactId }} /> : <Skeleton count={5} />}
          </div>
        </div>
        <div className="col-lg-6">
          <Timeline contactId={contactId} />
        </div>
      </div>
    </>
  );
}

export default Contact;
