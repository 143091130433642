import React, { useEffect, useState } from 'react';
import UserList from '../Users/UserList';
import TemplateList from './MailTemplates/TemplateList';
import Import from './Import';

function Settings() {
  const [activeSettingView, setActiveSettingView] = useState('USERS');

  useEffect(() => {}, []);
  return (
    <>
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-no-gutter">
                <li className="breadcrumb-item">
                  <a className="breadcrumb-link" href="/stages">
                    Instellingen
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Gebruikers
                </li>
              </ol>
            </nav>

            <h1 className="page-header-title">Instellingen</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
          <ul className="card card-navbar-nav nav nav-tabs nav-lg nav-vertical">
            <li className="nav-item">
              <button type="button" className={`nav-link ${activeSettingView === 'IMPORT' ? 'active' : ''}`} onClick={() => setActiveSettingView('IMPORT')}>
                <i className="bi bi-file-earmark-arrow-up nav-icon me-2" /> Gegevens importeren
              </button>
            </li>
            <li className="nav-item">
              <button type="button" className={`nav-link ${activeSettingView === 'EMAIL' ? 'active' : ''}`} onClick={() => setActiveSettingView('EMAIL')}>
                <i className="bi bi-envelope nav-icon me-2" /> Email templates
              </button>
            </li>
            <li className="nav-item">
              <button type="button" className={`nav-link ${activeSettingView === 'USERS' ? 'active' : ''}`} onClick={() => setActiveSettingView('USERS')}>
                <i className="bi-people nav-icon me-2" /> Gebruikers beheren
              </button>
            </li>
          </ul>
        </div>
        <div className="col-lg-8 col-md-6 col-sm-12">
          {activeSettingView === 'USERS' && <UserList />}
          {activeSettingView === 'IMPORT' && <Import />}
          {activeSettingView === 'EMAIL' && <TemplateList />}
        </div>
      </div>
    </>
  );
}

export default Settings;
