import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import importService from '../../services/import.service';
import TableList from '../Common/TableList';
import config from '../../config';

function Import() {
  const [studentImportResult, setStudentImportResult] = useState({});
  const [officeImportResult, setOfficeImportResult] = useState({});

  const handleUpload = async (e, type) => {
    const result = await importService().uploadImportFile(e.target.files[0], type);

    if (type === 'students') {
      setStudentImportResult(result);
    } else {
      setOfficeImportResult(result);
    }
  };

  const getErrors = (type, page, pageSize) => {
    const result = type === 'students' ? studentImportResult : officeImportResult;
    const listErr = result.errors?.map((err) => ({
      ...err.error,
      lineNumber: err.lineNumber,
      msg: err.error.details.map((d) => d.message).join()
    }));

    const v = { data: { rows: listErr.slice((page - 1) * pageSize, page * pageSize), count: result.errors.length } };
    return v;
  };

  const cols = [
    {
      fieldName: 'lineNumber',
      description: 'Lijnnummer',
      sortable: false
    },
    {
      fieldName: 'msg',
      description: 'Fout',
      sortable: false
    }
  ];

  useEffect(() => {}, [studentImportResult, officeImportResult]);
  return (
    <>
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <h1 className="page-header-title">Imports</h1>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">Studenten</div>
        <div className="card-body">
          <p>
            Selecteer een import bestand of download een{' '}
            <Link to={`${config.apiUrl}download/studenten.csv`} download target="_blank">
              voorbeeldbestand
            </Link>
          </p>
          <input
            type="file"
            className="form-control"
            accept=".csv"
            id="studentImportUploaderModal"
            onChange={async (e) => {
              await handleUpload(e, 'students');
            }}
          />
          {studentImportResult.errors && studentImportResult.errors.length > 0 && (
            <>
              <div className="alert alert-soft-danger p-4 mt-4">
                <span className="text-body">Corrigeer onderstaande fouten en importeer het bestand opnieuw.</span>
              </div>
              <TableList entityType="Import fouten" columnDefinition={cols} getItems={(page, pageSize) => getErrors('students', page, pageSize)} searchHidden selectHidden />
            </>
          )}
          {studentImportResult.imported > 0 && (
            <div className="alert alert-soft-primary p-4 mt-4">
              <h3 className="text-inherit mb-1">Importeren voltooid</h3>
              <span className="text-body">{studentImportResult?.imported} studenten geïmporteerd</span>
              <div>
                <Link to="/students/list" className="btn btn-primary mt-2">
                  Naar studenten
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="card mt-6">
        <div className="card-header">Praktijken</div>
        <div className="card-body">
          <p>
            Selecteer een import bestand of download een{' '}
            <Link to={`${config.apiUrl}download/praktijken.csv`} download target="_blank">
              voorbeeldbestand
            </Link>
          </p>
          <input
            type="file"
            className="form-control"
            accept=".csv"
            id="officeImportUploaderModal"
            onChange={async (e) => {
              await handleUpload(e, 'offices');
            }}
          />
          {officeImportResult.errors && officeImportResult.errors.length > 0 && (
            <>
              <div className="alert alert-soft-danger p-4 mt-4">
                <span className="text-body">Corrigeer onderstaande fouten en importeer het bestand opnieuw.</span>
              </div>
              <TableList entityType="Import fouten" columnDefinition={cols} getItems={(page, pageSize) => getErrors('offices', page, pageSize)} searchHidden selectHidden />
            </>
          )}
          {officeImportResult.imported > 0 && (
            <div className="alert alert-soft-primary p-4 mt-4">
              <h3 className="text-inherit mb-1">Importeren voltooid</h3>
              <span className="text-body">{officeImportResult?.imported} praktijken geïmporteerd</span>
              <div>
                <Link to="/offices/list" className="btn btn-primary mt-2">
                  Naar praktijken
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Import;
