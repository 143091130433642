import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import studentService from '../../services/student.service';
import ValidatedFormField from '../Common/ValidatedFormField';
import ErrorToast from '../Common/ErrorToast';
import HeaderSvg from '../../assets/svg/components/card-6.svg';
import EmailSvg from '../../assets/svg/oc-email-verification.svg';
import regionService from '../../services/region.service';
import Logo from '../../assets/img/logo.png';
import FormSelect from '../Common/FormSelect';

function StudentInfoRequest() {
  const {
    handleSubmit,

    trigger,
    control,
    formState: { isValid, errors }
  } = useForm();

  const { tokenId, id } = useParams();
  const [errorToastVisible, setErrorToastVisible] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [student, setStudent] = useState();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const closeErrorToast = () => {
    setErrorToastVisible(false);
  };

  const showErrorToast = () => {
    setErrorToastVisible(true);
  };

  const fetchStudent = async () => {
    try {
      const response = await studentService().getSingleByToken(id, tokenId);
      setStudent(response);
    } catch (error) {
      showErrorToast();
    }
  };

  const onSubmit = async (data) => {
    await trigger();
    if (isValid) {
      setIsBusy(true);
      if (tokenId) {
        await studentService().setPreferences(student.id, tokenId, data.preferredRegion1Id, data.preferredRegion2Id);
        setSubmitSuccess(true);
      }

      setIsBusy(false);
    }
  };

  useEffect(() => {
    fetchStudent();
  }, []);
  useEffect(() => {}, [student]);
  return (
    <div className="bg-colored d-flex justify-content-center">
      <ErrorToast handleClose={() => closeErrorToast()} isVisible={errorToastVisible} error="Data kon niet geladen worden. Mogelijk geen internet connectie!" />
      <div className="position-absolute top-0 start-0 end-0 mt-3 mx-3">
        <div className="d-none d-lg-flex justify-content-between">
          <img src={Logo} style={{ width: '4rem', height: '4rem', zIndex: '2' }} alt="fg-logo" />
        </div>
      </div>
      <div>
        <div className="position-fixed top-0 end-0 start-0 bg-img-start" style={{ height: '32rem', backgroundImage: `url(${HeaderSvg})` }} alt="">
          <div className="shape shape-bottom zi-1">
            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
              <polygon fill="#7575CB" points="0,273 1921,273 1921,0 " />
            </svg>
          </div>
        </div>
      </div>

      <div className="py-10">
        <div className="card card-lg mt-10">
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
            {!student && (
              <div className="card-body p-6">
                <img src={EmailSvg} alt="todo" style={{ width: '20rem' }} className="m-auto d-block" />
                <h1 className="display-5 mt-5 mb-3">Uitnodiging verlopen..</h1>
                <p style={{ width: '30rem' }}>
                  Oeps.. deze uitnodiging is ondertussen verlopen. <br />
                  Je hoeft dus niets te ondernemen. <br />
                  Indien nodig versturen we een nieuwe uitnodiging!
                </p>
              </div>
            )}
            {!submitSuccess && student && (
              <div className="card-body p-6">
                <img src={EmailSvg} alt="todo" style={{ width: '20rem' }} className="m-auto d-block" />
                <h1 className="display-5 mt-5 mb-3">Bevestiging stagevoorkeuren</h1>
                <p style={{ width: '30rem' }}>
                  Hallo, om de stages zo goed mogelijk in te plannen is het belangrijk om je regiovoorkeur te ontvangen. Voor communicatie rond je stage verwijzen we je graag door naar blackboard.
                </p>

                <span className="divider-start text-cap mt-5">Gegevens</span>
                <div className="my-5">
                  <div className="my-2">
                    <i className="bi-mortarboard me-2" />
                    <strong>Student:</strong> {student?.fullName}
                  </div>
                </div>
                <span className="divider-start text-cap mb-5">Uw feedback</span>
                <div className="row">
                  <ValidatedFormField className="mb-4" errors={errors} name="preferredRegion1Id" description="Voorkeursregio 1">
                    <FormSelect name="preferredRegion1Id" defaultValue={student?.preferredRegion1} loadService={regionService} control={control} iconClassName="bi-geo-alt" />
                  </ValidatedFormField>
                  <ValidatedFormField className="mb-4" errors={errors} name="preferredRegion2Id" description="Voorkeursregio 2">
                    <FormSelect name="preferredRegion2Id" defaultValue={student?.preferredRegion2} loadService={regionService} control={control} iconClassName="bi-geo-alt" />
                  </ValidatedFormField>
                </div>
                <div>
                  <p>
                    Niet bekend met eerstelijnszones?{' '}
                    <a href="https://www.eerstelijnszone.be/zones" target="_blank" rel="noreferrer">
                      Klik hier voor meer info.
                    </a>
                  </p>
                </div>
              </div>
            )}
            {submitSuccess && (
              <div className="card-body p-6">
                <img src={EmailSvg} alt="todo" style={{ width: '20rem' }} className="m-auto d-block" />
                <h1 className="display-5 mt-5 mb-3">Feedback verstuurd</h1>
                <p style={{ width: '30rem' }}>Gelukt! Bedankt voor uw feedback!</p>
              </div>
            )}
            {!submitSuccess && student && (
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  <i className="bi-send-check me-2" />
                  {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Feedback versturen'}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default StudentInfoRequest;
