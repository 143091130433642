import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import internshipService from '../../services/internship.service';
import internshipStatusEnum from '../../enum/internship-status-enum';
import ValidatedFormField from '../Common/ValidatedFormField';
import ErrorToast from '../Common/ErrorToast';
import dateFormat from '../../helpers/date-format';
import HeaderSvg from '../../assets/svg/components/card-6.svg';
import EmailSvg from '../../assets/svg/oc-email-verification.svg';
import Logo from '../../assets/img/logo.png';

function InternshipApproval() {
  const {
    handleSubmit,

    trigger,
    register,
    formState: { isValid, errors }
  } = useForm();

  const { token } = useParams();
  const [errorToastVisible, setErrorToastVisible] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [internship, setInternship] = useState();
  const [approved, setApproved] = useState(true);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const closeErrorToast = () => {
    setErrorToastVisible(false);
  };

  const showErrorToast = () => {
    setErrorToastVisible(true);
  };

  const fetchInternship = async () => {
    try {
      const response = await internshipService().getSingleByToken(token);
      setInternship(response);
    } catch (error) {
      showErrorToast();
    }
  };

  const onFbChanged = (value) => {
    setApproved(value);
  };

  const onSubmit = async (data) => {
    await trigger();
    if (isValid) {
      setIsBusy(true);
      data.internshipStatusId = approved ? internshipStatusEnum.CONFIRMED : internshipStatusEnum.DECLINED;
      if (token) {
        await internshipService().approve(token, data.internshipStatusId, data.declinedReason);
        setSubmitSuccess(true);
      }

      setIsBusy(false);
    }
  };

  useEffect(() => {
    fetchInternship();
  }, []);
  useEffect(() => {}, [internship]);
  return (
    <div className="bg-colored d-flex justify-content-center">
      <ErrorToast handleClose={() => closeErrorToast()} isVisible={errorToastVisible} error="Data kon niet geladen worden. Mogelijk geen internet connectie!" />
      <div className="position-absolute top-0 start-0 end-0 mt-3 mx-3">
        <div className="d-none d-lg-flex justify-content-between">
          <img src={Logo} style={{ width: '4rem', height: '4rem', zIndex: '2' }} alt="fg-logo" />
        </div>
      </div>
      <div>
        <div className="position-fixed top-0 end-0 start-0 bg-img-start" style={{ height: '32rem', backgroundImage: `url(${HeaderSvg})` }} alt="">
          <div className="shape shape-bottom zi-1">
            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
              <polygon fill="#7575CB" points="0,273 1921,273 1921,0 " />
            </svg>
          </div>
        </div>
      </div>

      <div className="py-10">
        <div className="card card-lg mt-10">
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
            {!internship && (
              <div className="card-body p-6">
                <img src={EmailSvg} alt="todo" style={{ width: '20rem' }} className="m-auto d-block" />
                <h1 className="display-5 mt-5 mb-3">Uitnodiging verlopen..</h1>
                <p style={{ width: '30rem' }}>
                  Oeps.. deze uitnodiging is ondertussen verlopen. <br />
                  Je hoeft dus niets te ondernemen. <br />
                  Indien nodig versturen we een nieuwe uitnodiging!
                </p>
              </div>
            )}
            {!submitSuccess && internship && (
              <div className="card-body p-6">
                <img src={EmailSvg} alt="todo" style={{ width: '20rem' }} className="m-auto d-block" />
                <h1 className="display-5 mt-5 mb-3">Bevestiging stage</h1>
                <p style={{ width: '30rem' }}>Hallo, we hebben een stage ingepland in uw praktijk. Hieronder kan u de details terugvinden. Geef ons gerust een seintje bij vragen en/of opmerkingen!</p>
                <span className="divider-start text-cap mt-5">Gegevens</span>
                <div className="my-5">
                  <div className="my-2">
                    <i className="bi-calendar-heart me-2" />
                    <strong>Type stage:</strong> {internship?.internshipType?.name}
                  </div>
                  <div className="my-2">
                    <i className="bi-mortarboard me-2" />
                    <strong>Student:</strong> {internship?.student?.fullName}
                  </div>
                  <div className="my-2">
                    <i className="bi-calendar me-2" />
                    <strong>Start op: </strong>
                    {dateFormat(internship?.start, false)}
                  </div>
                  <div className="my-2">
                    <i className="bi-calendar me-2" />
                    <strong>Einde op: </strong>
                    {dateFormat(internship?.end, false)}
                  </div>
                </div>

                <span className="divider-start text-cap mb-5">Uw feedback</span>
                <div className="mt-5">
                  <div className="form-check form-check-inline">
                    <input type="radio" id="formInlineRadio1" className="form-check-input" name="confirmed" checked={approved} onChange={() => onFbChanged(true)} />
                    <label className="form-check-label" htmlFor="formInlineRadio1">
                      Prima, alles ok!
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input type="radio" id="formInlineRadio2" className="form-check-input indeterminate-checkbox" name="confirmed" checked={!approved} onChange={() => onFbChanged(false)} />
                    <label className="form-check-label" htmlFor="formInlineRadio2">
                      Hmm, dit lukt niet..
                    </label>
                  </div>
                </div>
                <div>
                  {!approved && (
                    <ValidatedFormField className="mb-4" errors={errors} name="declinedReason" description="">
                      <textarea className="form-control mt-5" placeholder="Toelichting" rows="4" {...register('declinedReason', { required: true })} />
                    </ValidatedFormField>
                  )}
                </div>
              </div>
            )}
            {submitSuccess && (
              <div className="card-body p-6">
                <img src={EmailSvg} alt="todo" style={{ width: '20rem' }} className="m-auto d-block" />
                <h1 className="display-5 mt-5 mb-3">Feedback verstuurd</h1>
                <p style={{ width: '30rem' }}>Gelukt! Bedankt voor uw feedback!</p>
              </div>
            )}
            {!submitSuccess && internship && (
              <div className="card-footer">
                <button type="submit" className="btn btn-primary ms-auto me-0 d-block">
                  <i className="bi-send-check me-2" />
                  {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Feedback versturen'}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default InternshipApproval;
