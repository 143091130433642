import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import PulseLoader from 'react-spinners/PulseLoader';
import userService from '../../services/user.service';
import userRoleService from '../../services/user-role.service';
import FormSelect from '../Common/FormSelect';
import ProfileCover from '../../assets/img/img2.jpg';
import nameInitials from '../../helpers/name-initials';

function ChangeProfile({ user, onError }) {
  const [profileImage, setProfileImage] = useState();
  const [isBusy, setIsBusy] = useState(false);
  const {
    register,
    handleSubmit,
    trigger,
    control,
    reset,
    formState: { isValid, errors }
  } = useForm();

  const fetchProfileImage = async () => {
    try {
      const response = await userService().getProfileImage(user.id);
      setProfileImage(response);
    } catch (error) {
      onError();
    }
  };

  const handleProfileImgUpload = async (e) => {
    await userService().updateProfileImage(e.target.files[0], user.id);
    await fetchProfileImage();
  };

  const onSubmit = async (data) => {
    await trigger();
    if (isValid) {
      try {
        setIsBusy(true);
        await userService().update(user.id, data);
      } catch {
        onError();
      }

      setIsBusy(false);
    }
  };

  useEffect(() => {
    reset(user);
    if (user) {
      fetchProfileImage();
    }
  }, [user]);
  return (
    <div className="row">
      <div className="col-lg">
        <div className="card mb-3 mb-lg-5">
          <div className="profile-cover">
            <div className="profile-cover-img-wrapper">
              <img id="profileCoverImg" className="profile-cover-img" src={ProfileCover} alt="profile img" />
            </div>
          </div>
          <label className="avatar avatar-xxl avatar-circle avatar-uploader profile-cover-avatar" htmlFor="editAvatarUploaderModal">
            {!profileImage && <span className="avatar-initials">{nameInitials(user?.fullName)}</span>}
            {profileImage && <img id="editAvatarImgModal" className="avatar-img" src={profileImage} alt="avatar" />}

            <input
              type="file"
              className="avatar-uploader-input"
              accept=".jpg, .jpeg, .png"
              id="editAvatarUploaderModal"
              onChange={async (e) => {
                await handleProfileImgUpload(e);
              }}
            />

            <span className="avatar-uploader-trigger">
              <i className="bi-pencil-fill avatar-uploader-icon shadow-sm" />
            </span>
          </label>
          <div className="card-body">
            {user != null ? (
              <form key={1} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="row pt-4">
                  <div className="col-4 col-lg-3 col-sm-5">
                    <label htmlFor="firstName" className="form-label">
                      Voornaam
                    </label>
                  </div>
                  <div className="col-8 col-sm-7 col-lg-6">
                    <input
                      {...register('firstName')}
                      name="firstName"
                      type="text"
                      defaultValue={user?.firstName}
                      className="form-control"
                      autoComplete="false"
                      placeholder="vul hier de voornaam in."
                    />
                  </div>
                </div>
                <div className="row">
                  <span className="invalid-feedback ms-1" style={{ display: errors['firstName'] ? 'block' : 'none' }}>
                    Gelieve de voornaam op te geven.
                  </span>
                </div>
                <div className="row pt-4">
                  <div className="col-4 col-lg-3 col-sm-5">
                    <label htmlFor="lastName" className="form-label">
                      Achternaam
                    </label>
                  </div>
                  <div className="col-8 col-sm-7 col-lg-6">
                    <input {...register('lastName')} name="lastName" type="text" defaultValue={user?.lastName} className="form-control" autoComplete="false" placeholder="vul hier de achternaam in." />
                  </div>
                </div>
                <div className="row">
                  <span className="invalid-feedback ms-1" style={{ display: errors['lastName'] ? 'block' : 'none' }}>
                    Gelieve de achternaam op te geven.
                  </span>
                </div>
                <div className="row pt-4">
                  <div className="col-4 col-lg-3 col-sm-5">
                    <label htmlFor="role" className="form-label">
                      Rol
                    </label>
                  </div>
                  <div className="col-8 col-sm-7 col-lg-6">
                    <FormSelect name="userRoleId" required defaultValue={user?.userRoleId} loadService={userRoleService} control={control} iconClassName="bi-person" />
                  </div>
                </div>
                <div className="row">
                  <span className="invalid-feedback ms-1" style={{ display: errors['userRoleId'] ? 'block' : 'none' }}>
                    Gelieve een rol te selecteren.
                  </span>
                </div>
                <div className="d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary">
                    {isBusy ? <PulseLoader className="loader" color="#fff" size="5" /> : 'Bewaren'}
                  </button>
                </div>
              </form>
            ) : (
              <Skeleton count={3} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeProfile;
