import React, { useState, useEffect } from 'react';
import { Card, Button, Nav } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useForm } from 'react-hook-form';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import PulseLoader from 'react-spinners/PulseLoader';
import RichEditor from '../Common/RichEditor';
import dateFormat from '../../helpers/date-format';
import eventService from '../../services/event.service';
import eventTypeEnum from '../../enum/event-type-enum';
import nameInitials from '../../helpers/name-initials';

function Timeline({ officeId, contactId, studentId, internshipId, internshipTypeId, className, canEdit = true, height }) {
  const [events, setEvents] = useState([]);
  const [editorState, setEditorState] = useState();
  const [initialEditorState, setInitialEditorState] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEvent, setSelectedEvent] = useState({
    officeId,
    contactId,
    studentId,
    internshipId
  });
  const [selectedEventType, setSelectedEventType] = useState(null); // Track selected event type

  const { handleSubmit } = useForm();

  const fetchEvents = async (reload) => {
    const response = await eventService().getAll(reload ? 1 : currentPage, 10, 'createdAt', 'DESC', '', { officeId, contactId, studentId, internshipId, internshipTypeId });
    setEvents(reload ? response?.data?.rows : [...events, ...response.data.rows]);
    if (response.data?.rows.length < 10) {
      setCurrentPage(-1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const style = height ? { height } : {};

  const resetEditor = () => {
    setSelectedEvent({ officeId, contactId, studentId, internshipId, internshipTypeId });
    setInitialEditorState(null);
    setEditorState(null);
  };

  const onEditorChange = (e) => {
    setEditorState(e);
  };

  const onSubmitEvent = async () => {
    selectedEvent.description = editorState;
    let result;
    setIsBusy(true);
    if (selectedEvent.id) {
      result = await eventService().update(selectedEvent.id, selectedEvent);
    } else {
      result = await eventService().create(selectedEvent);
    }

    if (result && result.id) {
      await fetchEvents(true);
    }

    resetEditor();
    setIsEditing(false);
    setIsBusy(false);
  };

  const onCancel = async () => {
    resetEditor();
    setIsEditing(false);
  };

  const onDeleteEvent = async (id) => {
    await eventService().delete([id]);
    await fetchEvents(true);
  };

  const onEditNewEvent = () => {
    resetEditor();
    setIsEditing(true);
    setInitialEditorState('');
  };

  const onEditEvent = async (id) => {
    const event = events.filter((f) => f.id === id)[0];
    setInitialEditorState(event.description);
    setSelectedEvent(event);
    setIsEditing(true);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [currentPage]);

  useEffect(() => {
    fetchEvents(true);
  }, [officeId, contactId, studentId, internshipId, internshipTypeId]);

  return (
    <Card className={`mb-3 mb-lg-5 ${className ?? ''}`}>
      <Card.Header className="d-flex justify-content-between align-items-center">
        {canEdit && <h4 className="card-header-title">Gebeurtenissen</h4>}
        {!canEdit && <h4 className="card-subtitle mt-2">Gebeurtenissen</h4>}
        {canEdit && (
          <Button variant="link" className={`btn btn-link ${isEditing ? 'd-none' : 'd-block'}`} onClick={() => onEditNewEvent()}>
            <i className="bi bi-plus" />
            Nieuwe gebeurtenis
          </Button>
        )}
      </Card.Header>
      <Card.Body className="overflow-auto" style={style}>
        <div className="d-flex">
          <Nav className="flex-column me-4">
            {Object.values(eventTypeEnum).map((eventType) => (
              <Nav.Link key={eventType.id} className={`btn ${selectedEventType === eventType.id ? 'btn-primary' : 'btn-light'}`} onClick={() => setSelectedEventType(eventType.id)}>
                {eventType.name}
              </Nav.Link>
            ))}
          </Nav>
          <div className="flex-grow-1">
            {events ? (
              <>
                <ul className="step step-icon-sm">
                  <li className="step-item">
                    <div className="step-content-wrapper">
                      <div className={`step-content ${isEditing ? 'd-block' : 'd-none'}`}>
                        <form onSubmit={handleSubmit(onSubmitEvent)}>
                          <RichEditor onChange={(e) => onEditorChange(e)} editorStateJson={initialEditorState} />
                          <div className="justify-content-end mt-3 gap-3 d-flex">
                            <Button variant="outline-white" onClick={() => onCancel()}>
                              Annuleer
                            </Button>
                            <Button type="submit" className="btn btn-primary">
                              {isBusy ? <PulseLoader className="loader" color="#fff" size="5px" /> : 'Bewaar gebeurtenis'}
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </li>
                  {events &&
                    events
                      .filter((event) => selectedEventType === null || event.eventTypeId === selectedEventType)
                      .map((event) => (
                        <li className="step-item" key={event.id}>
                          <div className="step-content-wrapper">
                            <span className="step-icon step-icon-soft-primary">
                              {event.user && event.eventTypeId !== eventTypeEnum.MESSAGE.id && nameInitials(event.user?.fullName)}
                              {!event.user && event.eventTypeId !== eventTypeEnum.MESSAGE.id && <i className="bi-info h2 mt-2 text-primary" />}
                              {event.eventTypeId === eventTypeEnum.MESSAGE.id && <i className="bi-envelope h2 mt-2 text-primary" />}
                            </span>
                            <div className="step-content">
                              <h5 className="mb-0">{Object.values(eventTypeEnum).filter((f) => f.id === event.eventTypeId)[0].name}</h5>
                              {event.description && <Editor editorState={EditorState.createWithContent(convertFromRaw(event.description))} onChange={() => {}} />}
                              <small className=" mb-0">{dateFormat(event.createdAt, true)}</small>
                              {(event.eventTypeId === eventTypeEnum.INFO.id || event.eventTypeId === eventTypeEnum.MESSAGE.id) && canEdit && (
                                <>
                                  <Button variant="outline-white" className="btn btn-white btn-icon btn-xs rounded-circle mt-2 me-1" onClick={() => onDeleteEvent(event.id)}>
                                    <i className="bi-trash" />
                                  </Button>
                                  <Button variant="outline-white" className="btn btn-white btn-icon btn-xs rounded-circle mt-2" onClick={() => onEditEvent(event.id)}>
                                    <i className="bi-pencil" />
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                </ul>
                {events.length === 0 && <p>Er zijn nog geen gebeurtenissen.</p>}
              </>
            ) : (
              <Skeleton count={5} />
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default Timeline;
